import { defineMessages } from 'react-intl'

export const tradeInRequestDetailMessages = defineMessages({
    // -- Title --
    tradeInRequestTableTitle: {
        id: 'tradeIn.requests.detail.backToTitle',
        defaultMessage: 'Back to overview',
    },
    tradeInRequestFrom: {
        id: 'tradeIn.requests.detail.requestedOn',
        defaultMessage: 'Requested on',
    },
    source: {
        id: 'tradeIn.requests.detail.source',
        defaultMessage: 'Source:',
    },
    vehicleInformationInfoBoxTitle: {
        id: 'tradeIn.requests.detail.infobox.vehicleInfo.title',
        defaultMessage: 'Vehicle Information',
    },
    priceEstimationInfoBoxTitle: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.title',
        defaultMessage: 'Price Estimation',
    },
    equipmentInfoBoxTitle: {
        id: 'tradeIn.requests.detail.infobox.equipment.title',
        defaultMessage: 'Equipment',
    },
    conditionAndHistoryInfoBoxTitle: {
        id: 'tradeIn.requests.detail.infobox.conditionAndHistory.title',
        defaultMessage: 'Condition & History',
    },
    customerInformationInfoBoxTitle: {
        id: 'tradeIn.requests.detail.infobox.customerInformation.title',
        defaultMessage: 'Customer Information',
    },
    marketplaceLinksInfoBoxTitle: {
        id: 'tradeIn.requests.detail.infobox.marketplaceLinks.title',
        defaultMessage: 'Links for market prices',
    },
})
