/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface TradeInVehicleMSRPPriceDataDTO
 */
export interface TradeInVehicleMSRPPriceDataDTO {
    /**
     * The original MSRP price of the trade-in vehicle
     * @type {string}
     * @memberof TradeInVehicleMSRPPriceDataDTO
     */
    formattedMsrpPrice?: string
    /**
     * The original option price sum of the trade-in vehicle
     * @type {string}
     * @memberof TradeInVehicleMSRPPriceDataDTO
     */
    formattedMsrpOptionSum?: string
}

/**
 * Check if a given object implements the TradeInVehicleMSRPPriceDataDTO interface.
 */
export function instanceOfTradeInVehicleMSRPPriceDataDTO(value: object): value is TradeInVehicleMSRPPriceDataDTO {
    return true
}

export function TradeInVehicleMSRPPriceDataDTOFromJSON(json: any): TradeInVehicleMSRPPriceDataDTO {
    return TradeInVehicleMSRPPriceDataDTOFromJSONTyped(json, false)
}

export function TradeInVehicleMSRPPriceDataDTOFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): TradeInVehicleMSRPPriceDataDTO {
    if (json == null) {
        return json
    }
    return {
        formattedMsrpPrice: json['formattedMsrpPrice'] == null ? undefined : json['formattedMsrpPrice'],
        formattedMsrpOptionSum: json['formattedMsrpOptionSum'] == null ? undefined : json['formattedMsrpOptionSum'],
    }
}

export function TradeInVehicleMSRPPriceDataDTOToJSON(json: any): TradeInVehicleMSRPPriceDataDTO {
    return TradeInVehicleMSRPPriceDataDTOToJSONTyped(json, false)
}

export function TradeInVehicleMSRPPriceDataDTOToJSONTyped(
    value?: TradeInVehicleMSRPPriceDataDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        formattedMsrpPrice: value['formattedMsrpPrice'],
        formattedMsrpOptionSum: value['formattedMsrpOptionSum'],
    }
}
