import { useDealerContext } from '@slfinrtl/context'
import React from 'react'

import { useTradeInManagerBackend } from '~/app/backend/useTradeInManagerBackendContext'
import { MSRPPriceData } from '~/app/pages/request-detail/components/PriceEstimation/MSRPPriceData/MSRPPriceData'

/**
 * This component offers the msrp and the included option sum of the current trade-in-vehicle for a dealer
 * @param tradeInId id of the current trade in vehicle
 */
export const MSRPPriceDataContainer = ({ tradeInId }: { tradeInId: string }) => {
    const { locale } = useDealerContext()

    const { useMSRPPriceDataQuery } = useTradeInManagerBackend()

    const { data } = useMSRPPriceDataQuery({ tradeInId, locale })

    if (!data?.formattedMsrpPrice || !data?.formattedMsrpOptionSum) {
        return
    }

    return (
        <MSRPPriceData
            formattedMsrpPrice={data.formattedMsrpPrice}
            formattedMsrpOptionSum={data.formattedMsrpOptionSum}
        />
    )
}
