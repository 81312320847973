/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { TradeInSourceDTO } from './TradeInSourceDTO'
import {
    TradeInSourceDTOFromJSON,
    TradeInSourceDTOFromJSONTyped,
    TradeInSourceDTOToJSON,
    TradeInSourceDTOToJSONTyped,
} from './TradeInSourceDTO'
import type { TradeInOverviewEntryCustomerDTO } from './TradeInOverviewEntryCustomerDTO'
import {
    TradeInOverviewEntryCustomerDTOFromJSON,
    TradeInOverviewEntryCustomerDTOFromJSONTyped,
    TradeInOverviewEntryCustomerDTOToJSON,
    TradeInOverviewEntryCustomerDTOToJSONTyped,
} from './TradeInOverviewEntryCustomerDTO'
import type { VehicleOfInterestDTO } from './VehicleOfInterestDTO'
import {
    VehicleOfInterestDTOFromJSON,
    VehicleOfInterestDTOFromJSONTyped,
    VehicleOfInterestDTOToJSON,
    VehicleOfInterestDTOToJSONTyped,
} from './VehicleOfInterestDTO'
import type { MileageDTO } from './MileageDTO'
import { MileageDTOFromJSON, MileageDTOFromJSONTyped, MileageDTOToJSON, MileageDTOToJSONTyped } from './MileageDTO'

/**
 *
 * @export
 * @interface TradeInOverviewEntry
 */
export interface TradeInOverviewEntry {
    /**
     * UUID of the Trade-in
     * @type {string}
     * @memberof TradeInOverviewEntry
     */
    id: string
    /**
     * Date-time which represents when was the Trade-in request made
     * @type {Date}
     * @memberof TradeInOverviewEntry
     */
    requestedOn: Date
    /**
     *
     * @type {TradeInSourceDTO}
     * @memberof TradeInOverviewEntry
     */
    source: TradeInSourceDTO
    /**
     * Manufacturer of a customer vehicle
     * @type {string}
     * @memberof TradeInOverviewEntry
     */
    manufacturer: string
    /**
     * Model of a customer vehicle
     * @type {string}
     * @memberof TradeInOverviewEntry
     */
    model: string
    /**
     *
     * @type {TradeInOverviewEntryCustomerDTO}
     * @memberof TradeInOverviewEntry
     */
    customer: TradeInOverviewEntryCustomerDTO
    /**
     * First registration date of a customer vehicle. Backend uses 'java.time.YearMonth'.
     * @type {Date}
     * @memberof TradeInOverviewEntry
     */
    firstRegistration: Date
    /**
     *
     * @type {MileageDTO}
     * @memberof TradeInOverviewEntry
     */
    mileage: MileageDTO
    /**
     *
     * @type {VehicleOfInterestDTO}
     * @memberof TradeInOverviewEntry
     */
    vehicleOfInterest: VehicleOfInterestDTO
}

/**
 * Check if a given object implements the TradeInOverviewEntry interface.
 */
export function instanceOfTradeInOverviewEntry(value: object): value is TradeInOverviewEntry {
    if (!('id' in value) || value['id'] === undefined) return false
    if (!('requestedOn' in value) || value['requestedOn'] === undefined) return false
    if (!('source' in value) || value['source'] === undefined) return false
    if (!('manufacturer' in value) || value['manufacturer'] === undefined) return false
    if (!('model' in value) || value['model'] === undefined) return false
    if (!('customer' in value) || value['customer'] === undefined) return false
    if (!('firstRegistration' in value) || value['firstRegistration'] === undefined) return false
    if (!('mileage' in value) || value['mileage'] === undefined) return false
    if (!('vehicleOfInterest' in value) || value['vehicleOfInterest'] === undefined) return false
    return true
}

export function TradeInOverviewEntryFromJSON(json: any): TradeInOverviewEntry {
    return TradeInOverviewEntryFromJSONTyped(json, false)
}

export function TradeInOverviewEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeInOverviewEntry {
    if (json == null) {
        return json
    }
    return {
        id: json['id'],
        requestedOn: new Date(json['requestedOn']),
        source: TradeInSourceDTOFromJSON(json['source']),
        manufacturer: json['manufacturer'],
        model: json['model'],
        customer: TradeInOverviewEntryCustomerDTOFromJSON(json['customer']),
        firstRegistration: new Date(json['firstRegistration']),
        mileage: MileageDTOFromJSON(json['mileage']),
        vehicleOfInterest: VehicleOfInterestDTOFromJSON(json['vehicleOfInterest']),
    }
}

export function TradeInOverviewEntryToJSON(json: any): TradeInOverviewEntry {
    return TradeInOverviewEntryToJSONTyped(json, false)
}

export function TradeInOverviewEntryToJSONTyped(
    value?: TradeInOverviewEntry | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        id: value['id'],
        requestedOn: value['requestedOn'].toISOString(),
        source: TradeInSourceDTOToJSON(value['source']),
        manufacturer: value['manufacturer'],
        model: value['model'],
        customer: TradeInOverviewEntryCustomerDTOToJSON(value['customer']),
        firstRegistration: value['firstRegistration'].toISOString().substring(0, 10),
        mileage: MileageDTOToJSON(value['mileage']),
        vehicleOfInterest: VehicleOfInterestDTOToJSON(value['vehicleOfInterest']),
    }
}
