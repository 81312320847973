import { PLinkPure } from '@porsche-design-system/components-react'
import { DealerContextProps } from '@slfinrtl/context-types'
import { useIntl } from 'react-intl'
import { VehicleOfInterestDTO, WantedVehicleDTOTypeEnum } from 'tim-api-client'

import { customerInformationMessages } from '~/app/pages/request-detail/components/CustomerInformation/messages'

type VehicleDetailsLinkProps = {
    vehicleData: VehicleOfInterestDTO
    buildInventoryVehicleUrl: (vehicleId: string) => string
    buildConfiguredVehicleUrl: (params: {
        porscheCode: string
        environment: DealerContextProps['environment']
    }) => string
    environment: DealerContextProps['environment']
}

export const VehicleDetailsLink = ({
    vehicleData,
    environment,
    buildInventoryVehicleUrl,
    buildConfiguredVehicleUrl,
}: VehicleDetailsLinkProps) => {
    const { formatMessage } = useIntl()

    if (vehicleData.type === WantedVehicleDTOTypeEnum.INVENTORY) {
        return (
            <PLinkPure
                inert={undefined}
                target="_blank"
                icon="none"
                underline
                href={buildInventoryVehicleUrl(vehicleData.id)}
            >
                {vehicleData.description} - {formatMessage(customerInformationMessages.stockVehicle)}
            </PLinkPure>
        )
    }

    return (
        <PLinkPure
            inert={undefined}
            target="_blank"
            icon="none"
            underline
            href={buildConfiguredVehicleUrl({ porscheCode: vehicleData.id, environment })}
        >
            {vehicleData.description} - {formatMessage(customerInformationMessages.configuratorVehicle)}
        </PLinkPure>
    )
}
