import { PButtonPure, PIcon } from '@porsche-design-system/components-react'
import {
    themeLightContrastMedium,
    themeLightNotificationError,
    themeLightNotificationSuccess,
} from '@porsche-design-system/components-react/styles'
import { useDealerContext } from '@slfinrtl/context'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { useTradeInManagerBackend } from '~/app/backend/useTradeInManagerBackendContext'
import {
    FeedbackBox,
    FeedbackBoxAnswer,
    FeedbackBoxText,
    FeedbackChoiceLink,
    FeedbackTextarea,
    HeadingSmall,
    NegativeFeedbackForm,
    NotificationBox,
    NotificationTextBox,
    SendFeedbackButton,
    Textarea,
    TextXSmall,
} from '~/app/pages/request-detail/components/PriceEstimation/MarketPrice/MarketPrice.styled'
import { marketPriceMessages } from '~/app/pages/request-detail/components/PriceEstimation/MarketPrice/messages'

type MarketPriceFeedbackProps = { tradeInId: string }
export const MarketPriceFeedback = ({ tradeInId }: MarketPriceFeedbackProps) => {
    const { formatMessage } = useIntl()
    const { locale } = useDealerContext()

    const [isHelpful, setIsHelpful] = useState<boolean | undefined>()
    const [feedbackText, setFeedbackText] = useState<string>()
    const [showFeedback, setShowFeedback] = useState<boolean>(true)
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
    const [showTransmissionErrorMessage, setShowTransmissionErrorMessage] = useState<boolean>(false)

    const { useMarketPriceFeedbackMutation } = useTradeInManagerBackend()

    const { mutateAsync, isPending } = useMarketPriceFeedbackMutation({
        tradeInId,
        onSuccess: () => {
            setShowFeedback(false)
            setIsSubmitted(true)
        },
        onError: () => {
            setShowTransmissionErrorMessage(true)
        },
    })

    const sendFeedback = async (priceEstimationIsHelpful: boolean) => {
        setShowTransmissionErrorMessage(false)
        await mutateAsync({
            id: tradeInId,
            tradeInFeedbackPriceEstimationDTO: {
                priceEstimationIsHelpful,
                locale,
                freeText: feedbackText,
            },
        })
    }

    return (
        <>
            {showFeedback && (
                <>
                    <FeedbackBox>
                        <FeedbackBoxText>
                            <TextXSmall>{formatMessage(marketPriceMessages.textFeedbackAppreciated)}</TextXSmall>
                        </FeedbackBoxText>
                        <FeedbackBoxAnswer>
                            <FeedbackChoiceLink
                                inert={undefined}
                                weight={'regular'}
                                size={'x-small'}
                                color={isHelpful ? 'notification-success' : 'primary'}
                                data-testid={'feedback-choice-is-helpful'}
                                onClick={async () => {
                                    setIsHelpful(true)
                                    await sendFeedback(true)
                                }}
                                textColor={isHelpful ? themeLightNotificationSuccess : themeLightContrastMedium}
                            >
                                {formatMessage(marketPriceMessages.feedbackYes)}
                            </FeedbackChoiceLink>
                            <FeedbackChoiceLink
                                inert={undefined}
                                weight={'regular'}
                                size={'x-small'}
                                data-testid={'feedback-choice-not-helpful'}
                                onClick={() => {
                                    setIsHelpful(false)
                                }}
                                color={isHelpful === false ? 'notification-error' : 'primary'}
                                textColor={isHelpful === false ? themeLightNotificationError : themeLightContrastMedium}
                            >
                                {formatMessage(marketPriceMessages.feedbackNo)}
                            </FeedbackChoiceLink>
                        </FeedbackBoxAnswer>
                    </FeedbackBox>
                    {isHelpful === false && (
                        <NegativeFeedbackForm>
                            <TextXSmall>{formatMessage(marketPriceMessages.feedbackRequestText)}</TextXSmall>
                            <FeedbackTextarea inert={undefined}>
                                <Textarea
                                    data-testid={'feedback-not-helpful-text'}
                                    onChange={(e) => {
                                        setFeedbackText(e.target.value)
                                    }}
                                />
                            </FeedbackTextarea>
                            <div>
                                <SendFeedbackButton
                                    inert={undefined}
                                    loading={isPending}
                                    size={'x-small'}
                                    data-testid={'feedback-submit-button'}
                                    onClick={async () => await sendFeedback(false)}
                                >
                                    {formatMessage(marketPriceMessages.sendFeedbackButton)}
                                </SendFeedbackButton>
                            </div>
                        </NegativeFeedbackForm>
                    )}
                </>
            )}

            {isSubmitted && (
                <InlineNotification
                    status={'success'}
                    heading={formatMessage(marketPriceMessages.successMessageHeadline)}
                    message={formatMessage(marketPriceMessages.successMessageText)}
                />
            )}
            {showTransmissionErrorMessage && (
                <InlineNotification
                    status={'error'}
                    heading={formatMessage(marketPriceMessages.errorMessageHeadline)}
                    message={formatMessage(marketPriceMessages.errorMessageText)}
                    onDismiss={() => setShowTransmissionErrorMessage(false)}
                />
            )}
        </>
    )
}

/**
 * This component is only used within the Price Estimation Feedback context, to seamlessly fill in the border-radius'd
 * parent container
 */
type InlineNotificationProps = { heading: string; message: string; status: 'error' | 'success'; onDismiss?: () => void }
const InlineNotification = ({ heading, message, status, onDismiss }: InlineNotificationProps) => {
    return (
        <NotificationBox status={status}>
            <PIcon
                inert={undefined}
                name={status === 'error' ? 'error-filled' : 'success-filled'}
                color={status === 'error' ? 'notification-error' : 'notification-success'}
            />
            <NotificationTextBox>
                <HeadingSmall>{heading}</HeadingSmall>
                <TextXSmall>{message}</TextXSmall>
            </NotificationTextBox>
            {onDismiss && (
                <div>
                    <PButtonPure inert={undefined} icon={'none'} onClick={onDismiss}>
                        <PIcon inert={undefined} name={'close'} />
                    </PButtonPure>
                </div>
            )}
        </NotificationBox>
    )
}
