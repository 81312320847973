/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const MileageUnitDTO = {
    KM: 'KM',
    SMI: 'SMI',
} as const
export type MileageUnitDTO = (typeof MileageUnitDTO)[keyof typeof MileageUnitDTO]

export function instanceOfMileageUnitDTO(value: any): boolean {
    for (const key in MileageUnitDTO) {
        if (Object.prototype.hasOwnProperty.call(MileageUnitDTO, key)) {
            if (MileageUnitDTO[key as keyof typeof MileageUnitDTO] === value) {
                return true
            }
        }
    }
    return false
}

export function MileageUnitDTOFromJSON(json: any): MileageUnitDTO {
    return MileageUnitDTOFromJSONTyped(json, false)
}

export function MileageUnitDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MileageUnitDTO {
    return json as MileageUnitDTO
}

export function MileageUnitDTOToJSON(value?: MileageUnitDTO | null): any {
    return value as any
}

export function MileageUnitDTOToJSONTyped(value: any, ignoreDiscriminator: boolean): MileageUnitDTO {
    return value as MileageUnitDTO
}
