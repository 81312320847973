import { useQuery } from '@tanstack/react-query'
import { tradeInControllerApi } from 'tim-api-client'

export interface TradeInConditionAndHistoryQueryProps {
    id: string
}

export const useTradeInConditionAndHistoryQuery = ({ id }: TradeInConditionAndHistoryQueryProps) =>
    useQuery({
        queryKey: ['trade-in-request', id, 'condition-and-history'],
        queryFn: () => tradeInControllerApi.fetchTradeInConditionAndHistory({ id: id }),
    })
