import {
    fontSizeHeadingLarge,
    fontSizeTextLarge,
    getSkeletonStyle,
    spacing,
} from '@porsche-design-system/components-react/styles'
import { ReactNode } from 'react'
import styled from 'styled-components'

interface SkeletonProps {
    value?: string | ReactNode
}

export const SkeletonFallbackText = ({ value }: SkeletonProps) => {
    return value === undefined ? <SkeletonText /> : value
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const BaseSkeleton = styled.div(getSkeletonStyle({ theme: 'light' }))

export const SkeletonText = styled(BaseSkeleton)`
    padding: ${spacing.fluid.small};
    height: ${fontSizeTextLarge};
    width: 33vw;
`

export const SkeletonHeadline = styled(BaseSkeleton)`
    margin-top: ${spacing.fluid.large};
    padding: ${spacing.fluid.small};
    height: ${fontSizeHeadingLarge};
    max-width: 100%;
    width: 50vw;
`

export const SkeletonContent = styled(BaseSkeleton)`
    margin-top: ${spacing.fluid.large};
    width: 100%;
    height: 50vw;
`
