/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 3 letter currency code as defined by ISO-4217
 * @export
 */
export const CurrencyDTO = {
    EUR: 'EUR',
} as const
export type CurrencyDTO = (typeof CurrencyDTO)[keyof typeof CurrencyDTO]

export function instanceOfCurrencyDTO(value: any): boolean {
    for (const key in CurrencyDTO) {
        if (Object.prototype.hasOwnProperty.call(CurrencyDTO, key)) {
            if (CurrencyDTO[key as keyof typeof CurrencyDTO] === value) {
                return true
            }
        }
    }
    return false
}

export function CurrencyDTOFromJSON(json: any): CurrencyDTO {
    return CurrencyDTOFromJSONTyped(json, false)
}

export function CurrencyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrencyDTO {
    return json as CurrencyDTO
}

export function CurrencyDTOToJSON(value?: CurrencyDTO | null): any {
    return value as any
}

export function CurrencyDTOToJSONTyped(value: any, ignoreDiscriminator: boolean): CurrencyDTO {
    return value as CurrencyDTO
}
