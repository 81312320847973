/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CreateCustomerDTO
 */
export interface CreateCustomerDTO {
    /**
     * Customer first name
     * @type {string}
     * @memberof CreateCustomerDTO
     */
    firstName: string
    /**
     * Customer last name
     * @type {string}
     * @memberof CreateCustomerDTO
     */
    lastName: string
    /**
     * Customer email
     * @type {string}
     * @memberof CreateCustomerDTO
     */
    email?: string
    /**
     * Customer phone number
     * @type {string}
     * @memberof CreateCustomerDTO
     */
    phoneNumber?: string
    /**
     * Customer salutation
     * @type {string}
     * @memberof CreateCustomerDTO
     */
    salutation?: string
    /**
     * Locale used by customer when he made an inquiry
     * @type {string}
     * @memberof CreateCustomerDTO
     */
    locale: string
}

/**
 * Check if a given object implements the CreateCustomerDTO interface.
 */
export function instanceOfCreateCustomerDTO(value: object): value is CreateCustomerDTO {
    if (!('firstName' in value) || value['firstName'] === undefined) return false
    if (!('lastName' in value) || value['lastName'] === undefined) return false
    if (!('locale' in value) || value['locale'] === undefined) return false
    return true
}

export function CreateCustomerDTOFromJSON(json: any): CreateCustomerDTO {
    return CreateCustomerDTOFromJSONTyped(json, false)
}

export function CreateCustomerDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCustomerDTO {
    if (json == null) {
        return json
    }
    return {
        firstName: json['firstName'],
        lastName: json['lastName'],
        email: json['email'] == null ? undefined : json['email'],
        phoneNumber: json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        salutation: json['salutation'] == null ? undefined : json['salutation'],
        locale: json['locale'],
    }
}

export function CreateCustomerDTOToJSON(json: any): CreateCustomerDTO {
    return CreateCustomerDTOToJSONTyped(json, false)
}

export function CreateCustomerDTOToJSONTyped(
    value?: CreateCustomerDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        firstName: value['firstName'],
        lastName: value['lastName'],
        email: value['email'],
        phoneNumber: value['phoneNumber'],
        salutation: value['salutation'],
        locale: value['locale'],
    }
}
