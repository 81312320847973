import { PTableCell } from '@porsche-design-system/components-react'
import { PropsWithChildren } from 'react'

import { StyledCellText } from '~/app/pages/request-table/components/TradeInRequestTable/TradeInRequestTable.styled'

export const Cell = ({
    children,
    maxWidth,
    onClick,
}: PropsWithChildren<{ maxWidth?: string; onClick?: () => void }>) => {
    return (
        <PTableCell inert={undefined} onClick={onClick}>
            <StyledCellText inert={undefined} ellipsis={true} maxWidth={maxWidth}>
                {children}
            </StyledCellText>
        </PTableCell>
    )
}
