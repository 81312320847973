import { PPopover } from '@porsche-design-system/components-react'
import {
    fontWeightRegular,
    fontWeightSemiBold,
    headingLargeStyle,
    headingSmallStyle,
    spacingFluidSmall,
    spacingFluidXSmall,
    textXSmallStyle,
} from '@porsche-design-system/components-react/styles'
import styled from 'styled-components'

export const PurchasePriceSubtitle = styled.div`
    ${headingSmallStyle};
    display: flex;
    flex-direction: row;
    gap: ${spacingFluidXSmall};
`

export const PurchasePriceSubtitleStatusDate = styled.div`
    ${headingSmallStyle};
    font-weight: ${fontWeightRegular};
`

export const PriceEntries = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacingFluidXSmall};
`

export const PriceEntry = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`

export const TextXSmall = styled.p`
    ${textXSmallStyle};
    margin: 0;
`

export const TextXSmallSemiBold = styled(TextXSmall)`
    font-weight: ${fontWeightSemiBold};
`

export const HeadingLarge = styled.h2`
    ${headingLargeStyle};
    margin: 0;
`

export const SpacingFluidSmall = styled.div`
    display: inline;
    margin-left: ${spacingFluidSmall};
`

export const PopoverWithWrapText = styled(PPopover)`
    white-space: normal;
`
