/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const TradeInPowerUnitDTO = {
    KILOWATT: 'KILOWATT',
    PS_OR_HORSEPOWER: 'PS_OR_HORSEPOWER',
} as const
export type TradeInPowerUnitDTO = (typeof TradeInPowerUnitDTO)[keyof typeof TradeInPowerUnitDTO]

export function instanceOfTradeInPowerUnitDTO(value: any): boolean {
    for (const key in TradeInPowerUnitDTO) {
        if (Object.prototype.hasOwnProperty.call(TradeInPowerUnitDTO, key)) {
            if (TradeInPowerUnitDTO[key as keyof typeof TradeInPowerUnitDTO] === value) {
                return true
            }
        }
    }
    return false
}

export function TradeInPowerUnitDTOFromJSON(json: any): TradeInPowerUnitDTO {
    return TradeInPowerUnitDTOFromJSONTyped(json, false)
}

export function TradeInPowerUnitDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeInPowerUnitDTO {
    return json as TradeInPowerUnitDTO
}

export function TradeInPowerUnitDTOToJSON(value?: TradeInPowerUnitDTO | null): any {
    return value as any
}

export function TradeInPowerUnitDTOToJSONTyped(value: any, ignoreDiscriminator: boolean): TradeInPowerUnitDTO {
    return value as TradeInPowerUnitDTO
}
