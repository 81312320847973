import { defineMessages } from 'react-intl'

export const msrpPriceDataMessages = defineMessages({
    tileHeading: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.tileHeadingMSRP',
        defaultMessage: 'MSRP',
    },
    labelVehicleMSRPPrice: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.labelVehicleMSRPPrice',
        defaultMessage: 'Original MSRP',
    },
    labelVehicleMSRPOptionSum: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.labelVehicleMSRPOptionSum',
        defaultMessage: 'included options',
    },
})
