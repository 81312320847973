import { PTextFieldWrapper } from '@porsche-design-system/components-react'
import {
    getMediaQueryMax,
    getMediaQueryMinMax,
    spacingFluidMedium,
    spacingFluidSmall,
} from '@porsche-design-system/components-react/styles'
import React, { ChangeEventHandler } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { messagesForUseFilter } from '~/app/pages/request-table/messages/messagesForUseFilter'
import { messagesForUseTextFilter } from '~/app/pages/request-table/messages/messagesForUseTextFilter'

export interface TradeInRequestTableSearchAndFiltersProps {
    onTextChange: ChangeEventHandler<HTMLInputElement>
    fromDateChange: ChangeEventHandler<HTMLInputElement>
    toDateChange: ChangeEventHandler<HTMLInputElement>
}

export const TradeInRequestTableSearchAndFilters = ({
    onTextChange,
    fromDateChange,
    toDateChange,
}: TradeInRequestTableSearchAndFiltersProps) => {
    const { formatMessage } = useIntl()

    return (
        <Grid>
            <div>
                <PTextFieldWrapper label={formatMessage(messagesForUseTextFilter.filterSearchLabel)} inert={undefined}>
                    <input type={'search'} onChange={onTextChange} />
                </PTextFieldWrapper>
            </div>
            <div>
                <PTextFieldWrapper label={formatMessage(messagesForUseFilter.filterDateFromLabel)} inert={undefined}>
                    <input type="date" onChange={fromDateChange}></input>
                </PTextFieldWrapper>
            </div>
            <div>
                <PTextFieldWrapper label={formatMessage(messagesForUseFilter.filterDateToLabel)} inert={undefined}>
                    <input type="date" onChange={toDateChange}></input>
                </PTextFieldWrapper>
            </div>
        </Grid>
    )
}

const Grid = styled.div`
    display: grid;
    grid-gap: ${spacingFluidMedium};
    grid-template-columns: 1fr 1fr 1fr 1fr;

    ${getMediaQueryMax('s')} {
        grid-gap: ${spacingFluidSmall};
        grid-template-columns: 1fr;
    }

    ${getMediaQueryMinMax('s', 'm')} {
        grid-gap: ${spacingFluidSmall};
        grid-template-columns: 1fr 1fr 1fr;
    }
`
