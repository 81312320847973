import { createContext, PropsWithChildren } from 'react'

import {
    ConditionAndHistoryContainer,
    CustomerInformationContainer,
    EquipmentContainer,
    VehicleInformationContainer,
} from '~/app/pages/request-detail/components'
import { MarketplaceLinksContainer } from '~/app/pages/request-detail/components/PriceEstimation/MarketplaceLinks/MarketplaceLinksContainer'
import { MarketPriceContainer } from '~/app/pages/request-detail/components/PriceEstimation/MarketPrice/MarketPriceContainer'
import { MSRPPriceDataContainer } from '~/app/pages/request-detail/components/PriceEstimation/MSRPPriceData/MSRPPriceDataContainer'
import { PurchasePriceContainer } from '~/app/pages/request-detail/components/PriceEstimation/PurchasePrice/PurchasePriceContainer'

/*
 *  This context exists so nested container components can be easily replaces in Storybook without mocking rest endpoints.
 *  The idea comes from https://storybook.js.org/docs/react/writing-stories/build-pages-with-storybook#avoiding-mocking-dependencies
 */

// By default, the real container components are used, which actually do calls to the backends
const containerComponentContext = {
    VehicleInformationContainer,
    PurchasePriceContainer,
    MarketPriceContainer,
    MSRPPriceDataContainer,
    ConditionAndHistoryContainer,
    CustomerInformationContainer,
    EquipmentContainer,
    MarketplaceLinksContainer,
}

export type TradeInDetailPageComponentContextProps = typeof containerComponentContext

export const TradeInDetailPageComponentContext = createContext(containerComponentContext)

// Provides real container components in context
export const TradeInDetailPageContainerProvider = ({ children }: PropsWithChildren) => {
    return (
        <TradeInDetailPageComponentContext.Provider value={containerComponentContext}>
            {children}
        </TradeInDetailPageComponentContext.Provider>
    )
}
