import { PLink } from '@porsche-design-system/components-react'
import { DealerContextProps } from '@slfinrtl/context-types'
import React from 'react'
import { useIntl } from 'react-intl'

import { conditionAndHistoryMessages } from '~/app/pages/request-detail/components/ConditionAndHistory/messages'

type Env = DealerContextProps['environment']

interface PCSSLinkProps {
    vin: string
    env: Env
}

const buildLinkFromVinAndEnv = (vin: string, env: Env): string => {
    if (env === 'dev') {
        // Uses PPN-lite
        return `https://staging.pcss.porsche.com/static/vehicle/search/vin/history?vin=${vin}`
    } else {
        // 'local'|'preview'|'prod'|null, uses PPN
        return `https://pcss.porsche.com/static/vehicle/search/vin/history?vin=${vin}`
    }
}

/**
 * A link to the "Porsche Central Service System", where the dealer can see the
 * equipment etc. of the given vehicle.
 * Note: all dealers have permission to access to the PCSS.
 */
const PCSSLink = ({ vin, env }: PCSSLinkProps) => {
    const { formatMessage } = useIntl()
    const link = buildLinkFromVinAndEnv(vin, env)
    return (
        <PLink inert={undefined} href={link} icon="external" variant={'tertiary'} target={'_blank'}>
            {formatMessage(conditionAndHistoryMessages.pcssLinkButtonText)}
        </PLink>
    )
}

export default PCSSLink
