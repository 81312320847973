import React, { useContext } from 'react'
import { TradeInSourceDTO } from 'tim-api-client'

import { CardWithMarginTop, FullWidth, VehicleDetailsHeadline } from '~/app/components'
import { useTradeInManagerContext } from '~/app/context'
import {
    MarketplaceLinksColumn,
    MarketPriceLayoutGrid,
    PriceTileColumn,
} from '~/app/pages/request-detail/components/PriceEstimation/PriceEstimation.styled'
import { TradeInDetailPageComponentContext } from '~/app/pages/request-detail/context/TradeInDetailPageContext'

type PriceEstimationProps = {
    title: string
    tradeInId: string
    tradeInSource: TradeInSourceDTO
    tradeInRequestDate: Date
}

/**
 * Component used to support the Dealer during a market-price finding analysis by displaying an
 * estimated market price as well as providing search links to common used-car-portals
 */
export const PriceEstimation = ({ title, tradeInId, tradeInSource, tradeInRequestDate }: PriceEstimationProps) => {
    const tradeInManagerContext = useTradeInManagerContext()

    const { PurchasePriceContainer, MarketPriceContainer, MSRPPriceDataContainer, MarketplaceLinksContainer } =
        useContext(TradeInDetailPageComponentContext)

    const isDealbuilder = tradeInSource === 'FINDER_DEALBUILDER' || tradeInSource === 'ICC_DEALBUILDER'

    return (
        <CardWithMarginTop>
            <FullWidth>
                <VehicleDetailsHeadline>{title}</VehicleDetailsHeadline>
                <MarketPriceLayoutGrid>
                    {(tradeInManagerContext.showPriceEstimation || tradeInManagerContext.showMsrpPrice) && (
                        <PriceTileColumn>
                            {isDealbuilder && (
                                <PurchasePriceContainer
                                    tradeInId={tradeInId}
                                    priceEstimationDate={tradeInRequestDate}
                                />
                            )}
                            {tradeInManagerContext.showPriceEstimation && (
                                <MarketPriceContainer tradeInId={tradeInId} />
                            )}
                            {tradeInManagerContext.showMsrpPrice && <MSRPPriceDataContainer tradeInId={tradeInId} />}
                        </PriceTileColumn>
                    )}
                    <MarketplaceLinksColumn>
                        <MarketplaceLinksContainer tradeInId={tradeInId} />
                    </MarketplaceLinksColumn>
                </MarketPriceLayoutGrid>
            </FullWidth>
        </CardWithMarginTop>
    )
}
