import { useQuery } from '@tanstack/react-query'
import { tradeInControllerApi } from 'tim-api-client'

export interface TradeInEquipmentQueryProps {
    id: string
    locale: string
}

export const useTradeInEquipmentQuery = ({ id, locale }: TradeInEquipmentQueryProps) =>
    useQuery({
        queryKey: ['trade-in-request', id, 'equipment', locale],
        queryFn: () => tradeInControllerApi.fetchTradeInVehicleEquipment({ id: id, locale: locale }),
    })
