import {
    fontWeightSemiBold,
    getMediaQueryMin,
    getMediaQueryMinMax,
    headingLargeStyle,
    spacingFluidMedium,
    textSmallStyle,
} from '@porsche-design-system/components-react/styles'
import { ReactNode } from 'react'
import styled from 'styled-components'

import { CopyToClipboardButton } from '~/app/components/button'
import { HideableValue, HideableValueProps } from '~/app/components/HideableValue'

export const VehicleDetailGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    ${getMediaQueryMinMax('xs', 'm')} {
        grid-template-columns: 1fr 1fr;
    }
    ${getMediaQueryMin('m')} {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
`

export type VehicleDetailsItemProps = {
    label: string
    copyToClipboardButton?: boolean
    value: string | number | ReactNode
    hiddenStatus?: HideableValueProps['hidden']
}

export const VehicleDetailsItem = ({ label, value, hiddenStatus, copyToClipboardButton }: VehicleDetailsItemProps) => {
    return (
        <MarginBottomMedium>
            <LabelText>{label}</LabelText>
            <ValueText>
                <HideableValue hidden={hiddenStatus}>
                    {value}
                    {copyToClipboardButton && <CopyToClipboardButton text={`${value}`} />}
                </HideableValue>
            </ValueText>
        </MarginBottomMedium>
    )
}

const LabelText = styled.p`
    ${textSmallStyle};
    margin: 0;
`

const ValueText = styled(LabelText)`
    font-weight: ${fontWeightSemiBold};
    margin: 0;
    text-wrap: none;
`

const MarginBottomMedium = styled.div`
    margin: 0 0 ${spacingFluidMedium};
`

export const VehicleDetailsHeadline = styled.p`
    ${headingLargeStyle};
    margin: 0 0 ${spacingFluidMedium} 0;
`
