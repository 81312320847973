/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { OwnershipDTO } from './OwnershipDTO'
import {
    OwnershipDTOFromJSON,
    OwnershipDTOFromJSONTyped,
    OwnershipDTOToJSON,
    OwnershipDTOToJSONTyped,
} from './OwnershipDTO'
import type { ConditionDTO } from './ConditionDTO'
import {
    ConditionDTOFromJSON,
    ConditionDTOFromJSONTyped,
    ConditionDTOToJSON,
    ConditionDTOToJSONTyped,
} from './ConditionDTO'
import type { MileageDTO } from './MileageDTO'
import { MileageDTOFromJSON, MileageDTOFromJSONTyped, MileageDTOToJSON, MileageDTOToJSONTyped } from './MileageDTO'

/**
 *
 * @export
 * @interface ConditionAndHistoryResponseDTO
 */
export interface ConditionAndHistoryResponseDTO {
    /**
     * First registration date (YYYY-MM or YYYY-MM-DD)
     * @type {string}
     * @memberof ConditionAndHistoryResponseDTO
     */
    firstRegistrationDate: string
    /**
     *
     * @type {MileageDTO}
     * @memberof ConditionAndHistoryResponseDTO
     */
    mileage: MileageDTO
    /**
     *
     * @type {number}
     * @memberof ConditionAndHistoryResponseDTO
     */
    previousOwners: number
    /**
     *
     * @type {boolean}
     * @memberof ConditionAndHistoryResponseDTO
     */
    vatDeductible?: boolean
    /**
     *
     * @type {ConditionDTO}
     * @memberof ConditionAndHistoryResponseDTO
     */
    condition: ConditionDTO
    /**
     *
     * @type {OwnershipDTO}
     * @memberof ConditionAndHistoryResponseDTO
     */
    ownership?: OwnershipDTO
}

/**
 * Check if a given object implements the ConditionAndHistoryResponseDTO interface.
 */
export function instanceOfConditionAndHistoryResponseDTO(value: object): value is ConditionAndHistoryResponseDTO {
    if (!('firstRegistrationDate' in value) || value['firstRegistrationDate'] === undefined) return false
    if (!('mileage' in value) || value['mileage'] === undefined) return false
    if (!('previousOwners' in value) || value['previousOwners'] === undefined) return false
    if (!('condition' in value) || value['condition'] === undefined) return false
    return true
}

export function ConditionAndHistoryResponseDTOFromJSON(json: any): ConditionAndHistoryResponseDTO {
    return ConditionAndHistoryResponseDTOFromJSONTyped(json, false)
}

export function ConditionAndHistoryResponseDTOFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ConditionAndHistoryResponseDTO {
    if (json == null) {
        return json
    }
    return {
        firstRegistrationDate: json['firstRegistrationDate'],
        mileage: MileageDTOFromJSON(json['mileage']),
        previousOwners: json['previousOwners'],
        vatDeductible: json['vatDeductible'] == null ? undefined : json['vatDeductible'],
        condition: ConditionDTOFromJSON(json['condition']),
        ownership: json['ownership'] == null ? undefined : OwnershipDTOFromJSON(json['ownership']),
    }
}

export function ConditionAndHistoryResponseDTOToJSON(json: any): ConditionAndHistoryResponseDTO {
    return ConditionAndHistoryResponseDTOToJSONTyped(json, false)
}

export function ConditionAndHistoryResponseDTOToJSONTyped(
    value?: ConditionAndHistoryResponseDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        firstRegistrationDate: value['firstRegistrationDate'],
        mileage: MileageDTOToJSON(value['mileage']),
        previousOwners: value['previousOwners'],
        vatDeductible: value['vatDeductible'],
        condition: ConditionDTOToJSON(value['condition']),
        ownership: OwnershipDTOToJSON(value['ownership']),
    }
}
