import { PButtonPure, useToastManager } from '@porsche-design-system/components-react'
import { spacing } from '@porsche-design-system/components-react/styles'
import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'

type CopyToClipboardButtonProps = {
    text: string
}
export const CopyToClipboardButton = ({ text }: CopyToClipboardButtonProps) => {
    // Using <PToast /> in TradeInRequestDetailPage
    const { addMessage } = useToastManager()
    const { formatMessage } = useIntl()

    const onClick = async () => {
        try {
            await navigator.clipboard.writeText(text)
            addMessage({ text: formatMessage(messages.copyToClipboardSuccess, { value: text }), state: 'success' })
        } catch {
            addMessage({ text: formatMessage(messages.copyToClipboardError), state: 'info' })
        }
    }

    return <ButtonWithMarginLeft inert={undefined} icon={'copy'} onClick={onClick} />
}

const ButtonWithMarginLeft = styled(PButtonPure)`
    margin-left: ${spacing.fluid.xSmall};
`

const messages = defineMessages({
    copyToClipboardSuccess: {
        id: 'tradeIn.components.copyToClipboard.success',
        defaultMessage: 'Successfully copied "{value}" to Clipboard',
    },
    copyToClipboardError: {
        id: 'tradeIn.components.copyToClipboard.error',
        defaultMessage: 'Value could not be copied to Clipboard',
    },
})
