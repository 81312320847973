import { FormatDateOptions, useIntl } from 'react-intl'
import { TradeInSourceDTO } from 'tim-api-client'

import { VehicleDetailsItem } from '~/app/components'
import { conditionAndHistoryMessages } from '~/app/pages/request-detail/components/ConditionAndHistory/messages'

interface FirstRegistrationDateDetailItemProps {
    tradeInSource: TradeInSourceDTO
    firstRegistrationDate?: string
}

export const FirstRegistrationDateDetailItem = ({
    tradeInSource,
    firstRegistrationDate,
}: FirstRegistrationDateDetailItemProps) => {
    const { formatMessage, formatDate } = useIntl()

    // For trade-ins from contact form, the user cannot enter the day of month, so we don't show it in this case
    const firstRegistrationDateFormat: FormatDateOptions =
        tradeInSource === 'FINDER_DEALBUILDER' || tradeInSource === 'ICC_DEALBUILDER'
            ? { year: 'numeric', month: '2-digit', day: '2-digit' }
            : { year: 'numeric', month: '2-digit' }

    return (
        <VehicleDetailsItem
            label={formatMessage(conditionAndHistoryMessages.firstRegistration)}
            value={formatDate(firstRegistrationDate, firstRegistrationDateFormat)}
        />
    )
}
