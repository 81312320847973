/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { SensitiveString } from './SensitiveString'
import {
    SensitiveStringFromJSON,
    SensitiveStringFromJSONTyped,
    SensitiveStringToJSON,
    SensitiveStringToJSONTyped,
} from './SensitiveString'

/**
 *
 * @export
 * @interface TradeInVehicleDetail
 */
export interface TradeInVehicleDetail {
    /**
     *
     * @type {SensitiveString}
     * @memberof TradeInVehicleDetail
     */
    vin?: SensitiveString
    /**
     * Manufacturer of a customer vehicle
     * @type {string}
     * @memberof TradeInVehicleDetail
     */
    manufacturer?: string
    /**
     * Model of a customer vehicle
     * @type {string}
     * @memberof TradeInVehicleDetail
     */
    model?: string
    /**
     * Modelvariant of a customer vehicle
     * @type {string}
     * @memberof TradeInVehicleDetail
     */
    modelVariant?: string
    /**
     * Modelgeneration of a customer vehicle
     * @type {string}
     * @memberof TradeInVehicleDetail
     */
    modelGeneration?: string
    /**
     * Transmission of a customer vehicle
     * @type {string}
     * @memberof TradeInVehicleDetail
     */
    transmission?: string
    /**
     * Power of a customer vehicle
     * @type {string}
     * @memberof TradeInVehicleDetail
     */
    power?: string
    /**
     * Exterior color of the trade-in vehicle
     * @type {string}
     * @memberof TradeInVehicleDetail
     */
    exterior?: string
    /**
     * Interior color of the trade-in vehicle
     * @type {string}
     * @memberof TradeInVehicleDetail
     */
    interior?: string
    /**
     * Body type of the trade-in vehicle
     * @type {string}
     * @memberof TradeInVehicleDetail
     */
    bodyType?: string
    /**
     * Engine type of the trade-in vehicle
     * @type {string}
     * @memberof TradeInVehicleDetail
     */
    engineType?: string
    /**
     * Model Year / First registration date of the trade-in vehicle
     * @type {string}
     * @memberof TradeInVehicleDetail
     */
    modelYear?: string
}

/**
 * Check if a given object implements the TradeInVehicleDetail interface.
 */
export function instanceOfTradeInVehicleDetail(value: object): value is TradeInVehicleDetail {
    return true
}

export function TradeInVehicleDetailFromJSON(json: any): TradeInVehicleDetail {
    return TradeInVehicleDetailFromJSONTyped(json, false)
}

export function TradeInVehicleDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeInVehicleDetail {
    if (json == null) {
        return json
    }
    return {
        vin: json['vin'] == null ? undefined : SensitiveStringFromJSON(json['vin']),
        manufacturer: json['manufacturer'] == null ? undefined : json['manufacturer'],
        model: json['model'] == null ? undefined : json['model'],
        modelVariant: json['modelVariant'] == null ? undefined : json['modelVariant'],
        modelGeneration: json['modelGeneration'] == null ? undefined : json['modelGeneration'],
        transmission: json['transmission'] == null ? undefined : json['transmission'],
        power: json['power'] == null ? undefined : json['power'],
        exterior: json['exterior'] == null ? undefined : json['exterior'],
        interior: json['interior'] == null ? undefined : json['interior'],
        bodyType: json['bodyType'] == null ? undefined : json['bodyType'],
        engineType: json['engineType'] == null ? undefined : json['engineType'],
        modelYear: json['modelYear'] == null ? undefined : json['modelYear'],
    }
}

export function TradeInVehicleDetailToJSON(json: any): TradeInVehicleDetail {
    return TradeInVehicleDetailToJSONTyped(json, false)
}

export function TradeInVehicleDetailToJSONTyped(
    value?: TradeInVehicleDetail | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        vin: SensitiveStringToJSON(value['vin']),
        manufacturer: value['manufacturer'],
        model: value['model'],
        modelVariant: value['modelVariant'],
        modelGeneration: value['modelGeneration'],
        transmission: value['transmission'],
        power: value['power'],
        exterior: value['exterior'],
        interior: value['interior'],
        bodyType: value['bodyType'],
        engineType: value['engineType'],
        modelYear: value['modelYear'],
    }
}
