import { PToast } from '@porsche-design-system/components-react'
import { useDealerContext } from '@slfinrtl/context'
import { useContext } from 'react'
import { useIntl } from 'react-intl'
import { useHref } from 'react-router'

import { NarrowTopLevelContent, SkeletonFallbackText, WideTopLevelContent } from '~/app/components'
import { formatTradeInSource } from '~/app/components/sharedMessages'
import { FeedbackForm } from '~/app/pages/request-detail/components/FeedbackForm/FeedbackForm'
import { PriceEstimation } from '~/app/pages/request-detail/components/PriceEstimation/PriceEstimation'
import { TradeInDetailPageComponentContext } from '~/app/pages/request-detail/context/TradeInDetailPageContext'
import { tradeInRequestDetailMessages } from '~/app/pages/request-detail/messages/tradeInRequestDetailPageMessages'
import {
    BackToOverviewLink,
    DetailPageBackground,
    HeadingTags,
    HeadingXXLarge,
    SpacingFluidLargeTopAndBottom,
    TradeInRequestTag,
} from '~/app/pages/request-detail/TradeInRequestDetailPage.styled'

import { TradeInRequestDetailPageProps } from './TradeInRequestDetailPageProps'

export const TradeInRequestDetailPage = ({ getVehicleDetailsUrl, data, id }: TradeInRequestDetailPageProps) => {
    const { locale } = useDealerContext()
    const tableLink = useHref('/')

    const {
        VehicleInformationContainer,
        ConditionAndHistoryContainer,
        EquipmentContainer,
        CustomerInformationContainer,
    } = useContext(TradeInDetailPageComponentContext)

    const { formatMessage } = useIntl()
    const isVehicleDataServiceVehicle = data?.isVDSVehicle
    return (
        <>
            <WideTopLevelContent>
                <BackToOverviewLink inert={undefined} href={tableLink} icon={'arrow-left'}>
                    {formatMessage(tradeInRequestDetailMessages.tradeInRequestTableTitle)}
                </BackToOverviewLink>
                <HeadingXXLarge>
                    <SkeletonFallbackText value={data && `${data.manufacturer} ${data.model}`} />
                </HeadingXXLarge>
                {data && (
                    <HeadingTags>
                        <TradeInRequestTag inert={undefined}>
                            {formatMessage(tradeInRequestDetailMessages.tradeInRequestFrom)}&nbsp;
                            {data.requestedOn.toLocaleDateString(locale, { hour: '2-digit', minute: '2-digit' })}
                        </TradeInRequestTag>
                        <TradeInRequestTag inert={undefined}>
                            {formatMessage(tradeInRequestDetailMessages.source)}&nbsp;
                            {formatMessage(formatTradeInSource(data.source))}
                        </TradeInRequestTag>
                    </HeadingTags>
                )}
            </WideTopLevelContent>
            <DetailPageBackground>
                <WideTopLevelContent>
                    <VehicleInformationContainer id={id ?? ''} />

                    {isVehicleDataServiceVehicle && (
                        <PriceEstimation
                            title={formatMessage(tradeInRequestDetailMessages.priceEstimationInfoBoxTitle)}
                            tradeInId={id ?? ''}
                            tradeInSource={data.source}
                            tradeInRequestDate={data?.requestedOn}
                        />
                    )}

                    {isVehicleDataServiceVehicle && <EquipmentContainer id={id ?? ''} />}

                    {data && (
                        <ConditionAndHistoryContainer
                            id={id ?? ''}
                            vin={data?.vin.value}
                            isVDSVehicle={!!isVehicleDataServiceVehicle}
                            tradeInSource={data.source}
                        />
                    )}

                    <CustomerInformationContainer id={id ?? ''} getVehicleDetailsUrl={getVehicleDetailsUrl} />
                </WideTopLevelContent>

                <NarrowTopLevelContent>
                    <SpacingFluidLargeTopAndBottom>
                        <FeedbackForm id={id ?? ''} />
                    </SpacingFluidLargeTopAndBottom>
                </NarrowTopLevelContent>
            </DetailPageBackground>
            <PToast inert={undefined} />
        </>
    )
}
