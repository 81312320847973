import { useTradeInManagerBackend } from '~/app/backend/useTradeInManagerBackendContext'
import { CustomerInformation } from '~/app/pages/request-detail/components/CustomerInformation/CustomerInformation'

export interface CustomerInformationContainerProps {
    id: string
    getVehicleDetailsUrl: (vehicleId: string) => string
}

export const CustomerInformationContainer = ({ id, getVehicleDetailsUrl }: CustomerInformationContainerProps) => {
    const { useTradeInVehicleOfInterestQuery, useTradeInCustomerInfoQuery } = useTradeInManagerBackend()

    const { data: customerData } = useTradeInCustomerInfoQuery({ id })
    const { data: vehicleData } = useTradeInVehicleOfInterestQuery({ id })

    if (!customerData || !vehicleData) return null

    return (
        <CustomerInformation
            customerData={customerData}
            vehicleData={vehicleData}
            getVehicleDetailsUrl={getVehicleDetailsUrl}
        />
    )
}
