import { useDealerContext } from '@slfinrtl/context'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { tradeInControllerApi } from 'tim-api-client'

import { SkeletonFallbackText } from '~/app/components'
import { MarketplaceLinks } from '~/app/pages/request-detail/components/PriceEstimation/MarketplaceLinks/MarketplaceLinks'

/**
 * This component offers a couple of useful links to different markets where used cars are being sold
 * @param tradeInId id of the current trade in vehicle
 */
export const MarketplaceLinksContainer = ({ tradeInId }: { tradeInId: string }) => {
    const { locale } = useDealerContext()

    const { isLoading: searchLinkDataIsLoading, data: searchLinkData } = useQuery({
        queryKey: ['trade-in-request', tradeInId, 'vehicle-search-link-parameters', locale],
        queryFn: () => tradeInControllerApi.fetchTradeInVehicleSearchParameters({ id: tradeInId, locale }),
    })

    if (searchLinkDataIsLoading) {
        return <SkeletonFallbackText />
    }

    if (!searchLinkData) {
        return null
    }

    return <MarketplaceLinks searchFilterData={searchLinkData} />
}
