import { useInfiniteQuery } from '@tanstack/react-query'

import { UseFilterReturnValue } from '~/app/pages/request-table/hooks/useFilter'

import { useTablePageQuery } from './tablePage'

export interface TablePageQueryProps {
    filter: UseFilterReturnValue['filter']
    text: string
    dealerEntryDn: string
    country: string
}

export const useInfiniteTablePageQuery = ({ filter, text, dealerEntryDn, country }: TablePageQueryProps) => {
    const fetchPage = useTablePageQuery({ filter, text, dealerEntryDn, country })

    return useInfiniteQuery({
        queryKey: [
            'trade-in-request-table',
            {
                filter,
                text,
            },
        ],
        queryFn: fetchPage, // invoked with pageParam returned by getNextPageParam
        initialPageParam: 0,
        /**
         * If getNextPageParam returns a value other than undefined,
         * the hasNextPage boolean is true
         * @param lastPage
         */
        getNextPageParam: (lastPage) => {
            if (lastPage.last) {
                return undefined
            }
            return (lastPage.number ?? 0) + 1
        },
        refetchOnWindowFocus: false,
        retry: false,
    })
}
