/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * DTO to represent the Reason of the Dealer to not like the Trade-In-Detail Page
 * @export
 */
export const FeedbackReasonDTO = {
    TooLittleInformation: 'TooLittleInformation',
    UselessCustomerVehicle: 'UselessCustomerVehicle',
    MissingEditFeature: 'MissingEditFeature',
    MissingStatusFeature: 'MissingStatusFeature',
    MissingPicturesFeature: 'MissingPicturesFeature',
    OtherReason: 'OtherReason',
} as const
export type FeedbackReasonDTO = (typeof FeedbackReasonDTO)[keyof typeof FeedbackReasonDTO]

export function instanceOfFeedbackReasonDTO(value: any): boolean {
    for (const key in FeedbackReasonDTO) {
        if (Object.prototype.hasOwnProperty.call(FeedbackReasonDTO, key)) {
            if (FeedbackReasonDTO[key as keyof typeof FeedbackReasonDTO] === value) {
                return true
            }
        }
    }
    return false
}

export function FeedbackReasonDTOFromJSON(json: any): FeedbackReasonDTO {
    return FeedbackReasonDTOFromJSONTyped(json, false)
}

export function FeedbackReasonDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbackReasonDTO {
    return json as FeedbackReasonDTO
}

export function FeedbackReasonDTOToJSON(value?: FeedbackReasonDTO | null): any {
    return value as any
}

export function FeedbackReasonDTOToJSONTyped(value: any, ignoreDiscriminator: boolean): FeedbackReasonDTO {
    return value as FeedbackReasonDTO
}
