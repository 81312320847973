/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { EquipmentOptionDTO } from './EquipmentOptionDTO'
import {
    EquipmentOptionDTOFromJSON,
    EquipmentOptionDTOFromJSONTyped,
    EquipmentOptionDTOToJSON,
    EquipmentOptionDTOToJSONTyped,
} from './EquipmentOptionDTO'

/**
 *
 * @export
 * @interface EquipmentCategoryDTO
 */
export interface EquipmentCategoryDTO {
    /**
     * Translated name of the category
     * @type {string}
     * @memberof EquipmentCategoryDTO
     */
    name: string
    /**
     * List of all options in that category
     * @type {Array<EquipmentOptionDTO>}
     * @memberof EquipmentCategoryDTO
     */
    options: Array<EquipmentOptionDTO>
}

/**
 * Check if a given object implements the EquipmentCategoryDTO interface.
 */
export function instanceOfEquipmentCategoryDTO(value: object): value is EquipmentCategoryDTO {
    if (!('name' in value) || value['name'] === undefined) return false
    if (!('options' in value) || value['options'] === undefined) return false
    return true
}

export function EquipmentCategoryDTOFromJSON(json: any): EquipmentCategoryDTO {
    return EquipmentCategoryDTOFromJSONTyped(json, false)
}

export function EquipmentCategoryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentCategoryDTO {
    if (json == null) {
        return json
    }
    return {
        name: json['name'],
        options: (json['options'] as Array<any>).map(EquipmentOptionDTOFromJSON),
    }
}

export function EquipmentCategoryDTOToJSON(json: any): EquipmentCategoryDTO {
    return EquipmentCategoryDTOToJSONTyped(json, false)
}

export function EquipmentCategoryDTOToJSONTyped(
    value?: EquipmentCategoryDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        name: value['name'],
        options: (value['options'] as Array<any>).map(EquipmentOptionDTOToJSON),
    }
}
