import { DealerContextProps } from '@slfinrtl/context-types'
import { useMemo } from 'react'
import { VehicleOfInterestDTO, WantedVehicleDTOTypeEnum } from 'tim-api-client'

type VehicleOfInterestLinkProps = {
    vehicleOfInterest: VehicleOfInterestDTO
    environment: DealerContextProps['environment']
    linkText: string
    buildInventoryVehicleUrl: (vehicleId: string) => string
    buildConfiguredVehicleUrl: ({
        porscheCode,
        environment,
    }: {
        porscheCode: string
        environment: DealerContextProps['environment']
    }) => string
}

export const VehicleOfInterestLink = ({
    vehicleOfInterest,
    environment,
    linkText,
    buildInventoryVehicleUrl,
    buildConfiguredVehicleUrl,
}: VehicleOfInterestLinkProps) => {
    const vehicleOfInterestUrl = useMemo(() => {
        switch (vehicleOfInterest.type) {
            case WantedVehicleDTOTypeEnum.INVENTORY:
                return buildInventoryVehicleUrl(vehicleOfInterest.id)
            case WantedVehicleDTOTypeEnum.CSV:
                return buildConfiguredVehicleUrl({
                    porscheCode: vehicleOfInterest.id,
                    environment,
                })
            default:
                throw Error(`Unknown VehicleOfInterest type: ${vehicleOfInterest.type}`)
        }
    }, [vehicleOfInterest.type, vehicleOfInterest.id, buildInventoryVehicleUrl, buildConfiguredVehicleUrl, environment])

    return (
        <a
            href={vehicleOfInterestUrl}
            rel="noreferrer"
            target="_blank"
            onClick={(e) => {
                e.stopPropagation()
            }}
        >
            {linkText}
        </a>
    )
}
