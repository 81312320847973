import { fontWeightRegular, fontWeightSemiBold } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { OwnershipDTO } from 'tim-api-client'

import { VehicleDetailsItem } from '~/app/components'
import { formatTradeInOwnershipType } from '~/app/components/sharedMessages/messagesForTradeInOwnershipType'
import { conditionAndHistoryMessages } from '~/app/pages/request-detail/components/ConditionAndHistory/messages'

interface ConditionAndHistoryOwnershipDetailItemProps {
    ownership: OwnershipDTO
}

export const ConditionAndHistoryOwnershipDetailItem = ({ ownership }: ConditionAndHistoryOwnershipDetailItemProps) => {
    const { formatMessage, formatNumber } = useIntl()

    let outstandingFinanceOrLeaseAmountFormatted: string | undefined
    if (ownership.outstandingFinanceOrLeaseAmount) {
        outstandingFinanceOrLeaseAmountFormatted = formatNumber(ownership.outstandingFinanceOrLeaseAmount.amount, {
            style: 'currency',
            currency: ownership.outstandingFinanceOrLeaseAmount.currency,
        })
    }

    return (
        <VehicleDetailsItem
            label={formatMessage(conditionAndHistoryMessages.ownership)}
            value={
                <StyledOwnershipValue>
                    <StyledOwnershipType>
                        {formatMessage(formatTradeInOwnershipType(ownership.ownershipType))}
                    </StyledOwnershipType>
                    &nbsp;
                    {outstandingFinanceOrLeaseAmountFormatted &&
                        formatMessage(conditionAndHistoryMessages.ownershipOutstandingFinanceOrLeaseAmount, {
                            outstandingFinanceOrLeaseAmount: outstandingFinanceOrLeaseAmountFormatted,
                        })}
                </StyledOwnershipValue>
            }
        />
    )
}

const StyledOwnershipValue = styled.div`
    font-weight: ${fontWeightRegular};
    display: flex;
`

const StyledOwnershipType = styled.div`
    font-weight: ${fontWeightSemiBold};
`
