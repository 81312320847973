import { ChangeEventHandler, useCallback, useState } from 'react'

interface Filter {
    fromDate: string // as ISO date string
    toDate: string // as ISO date string
}

export interface UseFilterReturnValue {
    filter: Filter
    fromDateChange: ChangeEventHandler<HTMLInputElement>
    toDateChange: ChangeEventHandler<HTMLInputElement>
}

export const useFilter = (): UseFilterReturnValue => {
    const [filter, setFilter] = useState<Filter>({
        fromDate: '',
        toDate: '',
    })

    const fromDateChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        if (e.target.value) {
            setFilter((prev) => ({ ...prev, fromDate: e.target.value }))
        } else {
            setFilter((prev) => ({ ...prev, fromDate: '' }))
        }
    }, [])

    const toDateChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        if (e.target.value) {
            setFilter((prev) => ({ ...prev, toDate: e.target.value }))
        } else {
            setFilter((prev) => ({ ...prev, toDate: '' }))
        }
    }, [])

    return {
        filter: filter,
        fromDateChange,
        toDateChange,
    }
}
