import { defineMessages } from 'react-intl'

export const customerInformationMessages = defineMessages({
    name: {
        id: 'tradeIn.requests.detail.infobox.customerInformation.name',
        defaultMessage: 'Name',
    },
    mail: {
        id: 'tradeIn.requests.detail.infobox.customerInformation.mail',
        defaultMessage: 'Email',
    },
    phone: {
        id: 'tradeIn.requests.detail.infobox.customerInformation.phone',
        defaultMessage: 'Phone Number',
    },
    interestedIn: {
        id: 'tradeIn.requests.detail.infobox.customerInformation.interestedIn',
        defaultMessage: 'Interested in',
    },
    configuratorVehicle: {
        id: 'tradeIn.requests.detail.infobox.customerInformation.configuratorVehicle',
        defaultMessage: 'Configurator Vehicle',
    },
    stockVehicle: {
        id: 'tradeIn.requests.detail.infobox.customerInformation.stockVehicle',
        defaultMessage: 'Stock Vehicle',
    },
})
