import { defineMessages } from 'react-intl'

export const messagesForTradeInManagerContextProvider = defineMessages({
    errorHeader: {
        id: 'tradeIn.requests.context.error.header',
        defaultMessage: 'Error',
    },
    errorDescription: {
        id: 'tradeIn.requests.context.error.description',
        defaultMessage: 'The trade-in service is currently unavailable. Please try again later.',
    },
})
