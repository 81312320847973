import React from 'react'
import { useIntl } from 'react-intl'
import { EquipmentCategoryDTO, EquipmentDTO } from 'tim-api-client'

import { CardWithMarginTop, FullWidth, VehicleDetailsHeadline } from '~/app/components'
import {
    CategoryHeadline,
    EquipmentItemStyle,
    GridColumn,
    OptionCode,
    OptionName,
    SpacingFluidMediumBottom,
    ThreeColumnsOneRow,
} from '~/app/pages/request-detail/components/Equipment/Equipment.styled'
import { tradeInRequestDetailMessages } from '~/app/pages/request-detail/messages/tradeInRequestDetailPageMessages'

export interface EquipmentProps {
    data: EquipmentDTO
}

export const Equipment = ({ data }: EquipmentProps) => {
    const { formatMessage } = useIntl()

    const categoriesWithEquipmentItems = data.categories
        ?.map((category) => {
            // don't render anything if no options for this category are available
            if (category.options.length === 0) return null
            return <Category key={category.name} name={category.name} options={category.options} />
        })
        .filter((elem) => elem !== null)

    const total = categoriesWithEquipmentItems.length
    const thirdRoundedUp = Math.round(total / 3)

    const firstBlock = 0
    const secondBlock = thirdRoundedUp
    const thirdBlock = thirdRoundedUp * 2

    // Note this "grid" has only one row containing sub-elements of varying heights
    const firstColumn =
        total === 1 ? categoriesWithEquipmentItems[0] : categoriesWithEquipmentItems.slice(firstBlock, secondBlock)
    const secondColumn = total === 1 ? null : categoriesWithEquipmentItems.slice(secondBlock, thirdBlock)
    const thirdColumn = total === 1 ? null : categoriesWithEquipmentItems.slice(thirdBlock)

    if (!data || data?.categories?.length === 0) {
        return null
    }

    return (
        <CardWithMarginTop bottomSpacing={false}>
            <FullWidth>
                <VehicleDetailsHeadline>
                    {formatMessage(tradeInRequestDetailMessages.equipmentInfoBoxTitle)}
                </VehicleDetailsHeadline>
                <ThreeColumnsOneRow>
                    <GridColumn>{firstColumn}</GridColumn>
                    <GridColumn>{secondColumn}</GridColumn>
                    <GridColumn>{thirdColumn}</GridColumn>
                </ThreeColumnsOneRow>
            </FullWidth>
        </CardWithMarginTop>
    )
}

const Category = ({ name, options }: EquipmentCategoryDTO) => (
    <SpacingFluidMediumBottom>
        <CategoryHeadline>{name}</CategoryHeadline>
        {options.map((option, idx) => (
            <EquipmentItem
                key={`${option.name}-${idx}`}
                name={option.name ?? '-'}
                optionCode={option.optionCode ?? '-'}
            />
        ))}
    </SpacingFluidMediumBottom>
)

type EquipmentItemProps = { name: string; optionCode: string }
const EquipmentItem = ({ name, optionCode }: EquipmentItemProps) => {
    return (
        <EquipmentItemStyle>
            <OptionCode>{optionCode}</OptionCode>
            <OptionName>{name}</OptionName>
        </EquipmentItemStyle>
    )
}
