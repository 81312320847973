import { useQuery } from '@tanstack/react-query'
import { tradeInControllerApi } from 'tim-api-client'

export interface TradeInCustomerInfoQueryProps {
    id: string
}

export const useTradeInCustomerInfoQuery = ({ id }: TradeInCustomerInfoQueryProps) =>
    useQuery({
        queryKey: ['trade-in-request', id, 'customer'],
        queryFn: () => tradeInControllerApi.fetchTradeInCustomer({ id: id }),
    })
