import {
    PInlineNotification,
    PSpinner,
    PTable,
    PTableBody,
    PTableHead,
    PTableHeadCell,
    PTableHeadRow,
    PTag,
} from '@porsche-design-system/components-react'
import { useDealerContext } from '@slfinrtl/context'
import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { IntlShape } from 'react-intl/src/types'
import { useNavigate } from 'react-router'
import { TradeInOverviewEntry, TradeInOverviewPage } from 'tim-api-client'

import { HideableValue, HideableValueProps } from '~/app/components'
import { formatTradeInSource } from '~/app/components/sharedMessages'
import { Cell } from '~/app/pages/request-table/components/TradeInRequestTable/Cell'
import { LoadingBar } from '~/app/pages/request-table/components/TradeInRequestTable/LoadingBar'
import {
    PTextSmall,
    PTextSmallWithMargin,
    StyledRows,
} from '~/app/pages/request-table/components/TradeInRequestTable/TradeInRequestTable.styled'
import { VehicleOfInterestLink } from '~/app/pages/request-table/components/TradeInRequestTable/VehicleOfInterestLink'
import {
    messagesForTradeInRequestTable as messages,
    messagesForTradeInRequestTable,
} from '~/app/pages/request-table/messages/messagesForTradeInRequestTable'
import { formatRegistrationDate, getPorscheCodeUrl } from '~/app/ui-utils'

export interface TradeInRequestTableProps
    extends Pick<
        UseInfiniteQueryResult<InfiniteData<TradeInOverviewPage, unknown>, Error>,
        'isError' | 'isFetchingNextPage'
    > {
    data: Pick<InfiniteData<TradeInOverviewPage>, 'pages'> | undefined
    getVehicleDetailsUrl: (vehicleId: string) => string
}

const mapToStrings = (
    entry: TradeInOverviewEntry,
    locale: string,
    formatMessage: IntlShape['formatMessage'],
): {
    requestedOn: string
    source: string
    manufacturer: string
    model: string
    customer: { value: string; hidden?: HideableValueProps['hidden'] }
    firstRegistration: string
    mileage: string
    vehicleInterestDescription: string
} => {
    const n = 'numeric'
    const DATETIME_SHORT: Intl.DateTimeFormatOptions = {
        year: n,
        month: n,
        day: n,
        hour: n,
        minute: n,
    }

    const formatMileage = () => {
        if (entry.mileage) {
            const mileageUnitLocalized =
                entry.mileage.unit === 'SMI'
                    ? formatMessage(messagesForTradeInRequestTable.mileageUnitSMI)
                    : formatMessage(messagesForTradeInRequestTable.mileageUnitKM)
            return `${Intl.NumberFormat(locale).format(entry.mileage.value)} ${mileageUnitLocalized}`
        }
        return '-'
    }

    return {
        // "dd.mm.yyyy - HH-MM"
        requestedOn: entry?.requestedOn?.toLocaleDateString(locale, DATETIME_SHORT) ?? '-',
        source: formatMessage(formatTradeInSource(entry.source)),
        manufacturer: entry.manufacturer ?? '-',
        model: entry.model ?? '-',
        customer: { value: entry.customer?.value?.name ?? '-', hidden: entry.customer?.hidden },
        // "mm/yyyy"
        firstRegistration: formatRegistrationDate(entry?.firstRegistration),
        mileage: formatMileage(),
        vehicleInterestDescription: entry.vehicleOfInterest?.description ?? '-',
    }
}

export const TradeInRequestTable = ({
    data: iqData,
    isFetchingNextPage,
    isError,
    getVehicleDetailsUrl,
}: TradeInRequestTableProps) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const { environment } = useDealerContext()

    const headBasic: string[] = useMemo(
        () => [
            intl.formatMessage(messagesForTradeInRequestTable.requestDate),
            intl.formatMessage(messagesForTradeInRequestTable.source),
            intl.formatMessage(messagesForTradeInRequestTable.manufacturer),
            intl.formatMessage(messagesForTradeInRequestTable.model),
            intl.formatMessage(messagesForTradeInRequestTable.customer),
            intl.formatMessage(messagesForTradeInRequestTable.firstRegistration),
            intl.formatMessage(messagesForTradeInRequestTable.mileage),
            intl.formatMessage(messagesForTradeInRequestTable.vehicleInterestedIn),
        ],
        [intl],
    )

    if (isError) {
        return (
            <PInlineNotification
                inert={undefined}
                heading={intl.formatMessage(messagesForTradeInRequestTable.tableFetchErrorGenericHeading)}
                description={intl.formatMessage(messagesForTradeInRequestTable.tableFetchErrorGenericDescription)}
                state={'error'}
                dismissButton={false}
            />
        )
    }

    if (!iqData) {
        return <PSpinner inert={undefined} />
    }

    return (
        <>
            <PTable inert={undefined} caption={intl.formatMessage(messagesForTradeInRequestTable.tableCaption)}>
                <PTableHead inert={undefined}>
                    <PTableHeadRow inert={undefined}>
                        {headBasic.map((item: string, i: number) => {
                            if (i === 0) {
                                return (
                                    <PTableHeadCell
                                        key={i}
                                        inert={undefined}
                                        sort={{
                                            id: `${i}`, // identifier for the column to be sorted by
                                            active: true,
                                            direction: 'desc',
                                        }}
                                    >
                                        {item}
                                    </PTableHeadCell>
                                )
                            }
                            return (
                                <PTableHeadCell inert={undefined} key={i}>
                                    {item}
                                </PTableHeadCell>
                            )
                        })}
                    </PTableHeadRow>
                </PTableHead>
                {iqData.pages.map((page: TradeInOverviewPage) => {
                    return (
                        <PTableBody inert={undefined} key={`page-${page.number}`}>
                            {page?.content?.map((item: TradeInOverviewEntry, i: number) => {
                                const itemStrings = mapToStrings(item, intl.locale, intl.formatMessage)

                                // max container width = 1920px = 240*8
                                const base = 8
                                // longest only-name car manufacturer
                                const mercedesBenzLen = 22 // 176px
                                const longCell = 38 // 304px
                                const shortCell = (240 - mercedesBenzLen - longCell * 3) / 3 // 277.333px

                                const wRequestedOn = `${shortCell * base}px`
                                const wSource = `${shortCell * base}px`
                                const wManufacturer = `${mercedesBenzLen * base}px`
                                const wModel = `${longCell * base}px`
                                const wCustomer = `${longCell * base}px`
                                const wFirstRegistration = `${shortCell * base}px`
                                const wMileage = `${shortCell * base}px`
                                const wVehicleInterest = `${longCell * base}px`

                                return (
                                    <StyledRows inert={undefined} key={i} onClick={() => navigate(`/${item.id}`)}>
                                        <Cell maxWidth={wRequestedOn}>{itemStrings.requestedOn}</Cell>

                                        <Cell maxWidth={wSource}>
                                            <PTag color="background-surface" inert={undefined}>
                                                {itemStrings.source}
                                            </PTag>
                                        </Cell>

                                        <Cell maxWidth={wManufacturer}>{itemStrings.manufacturer}</Cell>

                                        <Cell maxWidth={wModel}>{itemStrings.model}</Cell>

                                        <Cell maxWidth={wCustomer}>
                                            <HideableValue hidden={itemStrings.customer.hidden} noIcon>
                                                {itemStrings.customer.value}
                                            </HideableValue>
                                        </Cell>
                                        <Cell maxWidth={wFirstRegistration}>{itemStrings.firstRegistration}</Cell>
                                        <Cell maxWidth={wMileage}>{itemStrings.mileage}</Cell>

                                        <Cell maxWidth={wVehicleInterest}>
                                            {item?.vehicleOfInterest?.id && environment !== null ? (
                                                <VehicleOfInterestLink
                                                    vehicleOfInterest={item.vehicleOfInterest}
                                                    linkText={itemStrings.vehicleInterestDescription}
                                                    environment={environment}
                                                    buildConfiguredVehicleUrl={getPorscheCodeUrl}
                                                    buildInventoryVehicleUrl={getVehicleDetailsUrl}
                                                />
                                            ) : (
                                                <PTextSmall>{itemStrings.vehicleInterestDescription}</PTextSmall>
                                            )}
                                        </Cell>
                                    </StyledRows>
                                )
                            })}
                        </PTableBody>
                    )
                })}
            </PTable>
            <LoadingBar isLoading={isFetchingNextPage} />
            {iqData?.pages[0]?.totalElements === 0 && (
                <PTextSmallWithMargin>{intl.formatMessage(messages.noTradeInRequestsFoundText)}</PTextSmallWithMargin>
            )}
        </>
    )
}

export default TradeInRequestTable
