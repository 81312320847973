import { createContext, useContext } from 'react'
import { FrontendConfiguration } from 'tim-api-client'

export type TradeInManagerContextProps = FrontendConfiguration

export const TradeInManagerContext = createContext<TradeInManagerContextProps | undefined>(undefined)

export const useTradeInManagerContext: () => TradeInManagerContextProps = () => {
    const context = useContext(TradeInManagerContext)
    if (context == null) {
        throw new Error('TradeInManagerContext is required for this component')
    }
    return context
}
