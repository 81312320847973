import {
    borderRadius,
    getMediaQueryMin,
    headingMediumStyle,
    spacingFluidMedium,
    spacingFluidSmall,
    themeLightContrastLow,
} from '@porsche-design-system/components-react/styles'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

type PriceTileProps = {
    heading: string
    content: ReactNode
    footer?: ReactNode
}
export const PriceTile = ({ heading, content, footer }: PriceTileProps) => (
    <PriceTileWithHeading>
        <HeadingMedium>{heading}</HeadingMedium>
        <PriceTileGrid>
            <ContentBox>{content}</ContentBox>
            {footer && <FooterBox>{footer}</FooterBox>}
        </PriceTileGrid>
    </PriceTileWithHeading>
)

const Grid = styled.div`
    display: grid;
`

export const PriceTileWithHeading = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacingFluidSmall};
`

export const PriceTileGrid = styled(Grid)`
    border: 1px solid ${themeLightContrastLow};
    border-radius: ${borderRadius.medium};
`

export const PriceTileContent = styled(Grid)`
    grid-template-columns: 1fr;
    grid-gap: ${spacingFluidMedium};

    ${getMediaQueryMin('xs')} {
        grid-template-columns: 1fr 1fr;
    }
`

export const ContentBox = styled.div`
    padding: ${spacingFluidMedium};
`

export const FooterBox = styled.div`
    border-top: 1px solid ${themeLightContrastLow};
`

export const HeadingMedium = styled.h2`
    ${headingMediumStyle};
    margin: 0;
`
