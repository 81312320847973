import { useQuery } from '@tanstack/react-query'
import { tradeInControllerApi } from 'tim-api-client'

export interface TradeInVehicleOfInterestQueryProps {
    id: string
}

export const useTradeInVehicleOfInterestQuery = ({ id }: TradeInVehicleOfInterestQueryProps) =>
    useQuery({
        queryKey: ['trade-in-request', id, 'vehicle'],
        queryFn: () => tradeInControllerApi.fetchTradeInVehicleOfInterestInformation({ id: id }),
    })
