/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { BodyTypeDTO } from './BodyTypeDTO'
import { BodyTypeDTOFromJSON, BodyTypeDTOFromJSONTyped, BodyTypeDTOToJSON, BodyTypeDTOToJSONTyped } from './BodyTypeDTO'
import type { EngineTypeDTO } from './EngineTypeDTO'
import {
    EngineTypeDTOFromJSON,
    EngineTypeDTOFromJSONTyped,
    EngineTypeDTOToJSON,
    EngineTypeDTOToJSONTyped,
} from './EngineTypeDTO'
import type { TransmissionTypeDTO } from './TransmissionTypeDTO'
import {
    TransmissionTypeDTOFromJSON,
    TransmissionTypeDTOFromJSONTyped,
    TransmissionTypeDTOToJSON,
    TransmissionTypeDTOToJSONTyped,
} from './TransmissionTypeDTO'

/**
 *
 * @export
 * @interface ModelInformationDTO
 */
export interface ModelInformationDTO {
    /**
     * One string for the whole model name of the vehicle
     * @type {string}
     * @memberof ModelInformationDTO
     */
    modelCombined: string
    /**
     *
     * @type {BodyTypeDTO}
     * @memberof ModelInformationDTO
     */
    bodyType: BodyTypeDTO
    /**
     *
     * @type {TransmissionTypeDTO}
     * @memberof ModelInformationDTO
     */
    transmission: TransmissionTypeDTO
    /**
     *
     * @type {EngineTypeDTO}
     * @memberof ModelInformationDTO
     */
    engineType: EngineTypeDTO
}

/**
 * Check if a given object implements the ModelInformationDTO interface.
 */
export function instanceOfModelInformationDTO(value: object): value is ModelInformationDTO {
    if (!('modelCombined' in value) || value['modelCombined'] === undefined) return false
    if (!('bodyType' in value) || value['bodyType'] === undefined) return false
    if (!('transmission' in value) || value['transmission'] === undefined) return false
    if (!('engineType' in value) || value['engineType'] === undefined) return false
    return true
}

export function ModelInformationDTOFromJSON(json: any): ModelInformationDTO {
    return ModelInformationDTOFromJSONTyped(json, false)
}

export function ModelInformationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelInformationDTO {
    if (json == null) {
        return json
    }
    return {
        modelCombined: json['modelCombined'],
        bodyType: BodyTypeDTOFromJSON(json['bodyType']),
        transmission: TransmissionTypeDTOFromJSON(json['transmission']),
        engineType: EngineTypeDTOFromJSON(json['engineType']),
    }
}

export function ModelInformationDTOToJSON(json: any): ModelInformationDTO {
    return ModelInformationDTOToJSONTyped(json, false)
}

export function ModelInformationDTOToJSONTyped(
    value?: ModelInformationDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        modelCombined: value['modelCombined'],
        bodyType: BodyTypeDTOToJSON(value['bodyType']),
        transmission: TransmissionTypeDTOToJSON(value['transmission']),
        engineType: EngineTypeDTOToJSON(value['engineType']),
    }
}
