/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface FrontendConfiguration
 */
export interface FrontendConfiguration {
    /**
     * When set to true, the VAT-deductible checkbox is displayed in the frontend.
     * @type {boolean}
     * @memberof FrontendConfiguration
     */
    showVATDeductible: boolean
    /**
     * When set to true, the price estimation box is displayed in the frontend.
     * @type {boolean}
     * @memberof FrontendConfiguration
     */
    showPriceEstimation: boolean
    /**
     * When set to true, the msrp price box is displayed in the frontend.
     * @type {boolean}
     * @memberof FrontendConfiguration
     */
    showMsrpPrice: boolean
    /**
     * When set to true, the link to a third party car-sales platform (e.g. mobile.de) is displayed in the frontend.
     * @type {boolean}
     * @memberof FrontendConfiguration
     */
    showThirdPartyProviderLink: boolean
}

/**
 * Check if a given object implements the FrontendConfiguration interface.
 */
export function instanceOfFrontendConfiguration(value: object): value is FrontendConfiguration {
    if (!('showVATDeductible' in value) || value['showVATDeductible'] === undefined) return false
    if (!('showPriceEstimation' in value) || value['showPriceEstimation'] === undefined) return false
    if (!('showMsrpPrice' in value) || value['showMsrpPrice'] === undefined) return false
    if (!('showThirdPartyProviderLink' in value) || value['showThirdPartyProviderLink'] === undefined) return false
    return true
}

export function FrontendConfigurationFromJSON(json: any): FrontendConfiguration {
    return FrontendConfigurationFromJSONTyped(json, false)
}

export function FrontendConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontendConfiguration {
    if (json == null) {
        return json
    }
    return {
        showVATDeductible: json['showVATDeductible'],
        showPriceEstimation: json['showPriceEstimation'],
        showMsrpPrice: json['showMsrpPrice'],
        showThirdPartyProviderLink: json['showThirdPartyProviderLink'],
    }
}

export function FrontendConfigurationToJSON(json: any): FrontendConfiguration {
    return FrontendConfigurationToJSONTyped(json, false)
}

export function FrontendConfigurationToJSONTyped(
    value?: FrontendConfiguration | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        showVATDeductible: value['showVATDeductible'],
        showPriceEstimation: value['showPriceEstimation'],
        showMsrpPrice: value['showMsrpPrice'],
        showThirdPartyProviderLink: value['showThirdPartyProviderLink'],
    }
}
