import { createContext, PropsWithChildren } from 'react'

import { useMarketPriceFeedbackMutation } from '~/app/backend/mutations/marketPriceFeedback'
import { useDealPriceDataQuery } from '~/app/backend/queries/dealPriceData'
import { useInfiniteTablePageQuery } from '~/app/backend/queries/infiniteTablePage'
import { useMarketPriceQuery } from '~/app/backend/queries/marketPrice'
import { useMSRPPriceDataQuery } from '~/app/backend/queries/msrpPriceData'
import { useTablePageQuery } from '~/app/backend/queries/tablePage'
import { useTradeInConditionAndHistoryQuery } from '~/app/backend/queries/tradeInConditionAndHistory'
import { useTradeInCustomerInfoQuery } from '~/app/backend/queries/tradeInCustomerInfo'
import { useTradeInEquipmentQuery } from '~/app/backend/queries/tradeInEquipment'
import { useTradeInVehicleOfInterestQuery } from '~/app/backend/queries/tradeInVehicleOfInterest'

import { useFrontendConfigurationQuery } from './queries/frontendConfiguration'

const defaultContextValue = {
    useFrontendConfigurationQuery,
    useTablePageQuery,
    useInfiniteTablePageQuery,
    useTradeInEquipmentQuery,
    useMarketPriceQuery,
    useMarketPriceFeedbackMutation,
    useMSRPPriceDataQuery,
    useDealPriceDataQuery,
    useTradeInCustomerInfoQuery,
    useTradeInVehicleOfInterestQuery,
    useTradeInConditionAndHistoryQuery,
}

/**
 * Helper context for backend queries, used for mocking the backend calls without mocking framework.
 * (e.g. in storybook)
 */
export const TradeInManagerBackendContext = createContext(defaultContextValue)

export const TradeInManagerBackendProvider = ({ children }: PropsWithChildren) => {
    return (
        <TradeInManagerBackendContext.Provider value={defaultContextValue}>
            {children}
        </TradeInManagerBackendContext.Provider>
    )
}
