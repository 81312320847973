import { PTableRow, PText } from '@porsche-design-system/components-react'
import { spacingFluidSmall, textSmallStyle } from '@porsche-design-system/components-react/styles'
import styled from 'styled-components'

export const StyledRows = styled(PTableRow)`
    cursor: pointer;
`

export const StyledCellText = styled(PText)<{ maxWidth?: string }>`
    ${({ maxWidth }) => `
    max-width: ${maxWidth};
  `}
`

export const PTextSmall = styled.p`
    margin: 0;
    ${textSmallStyle};
`

export const PTextSmallWithMargin = styled(PTextSmall)`
    margin: ${spacingFluidSmall};
    text-align: center;
`
