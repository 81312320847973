/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { MileageUnitDTO } from './MileageUnitDTO'
import {
    MileageUnitDTOFromJSON,
    MileageUnitDTOFromJSONTyped,
    MileageUnitDTOToJSON,
    MileageUnitDTOToJSONTyped,
} from './MileageUnitDTO'

/**
 *
 * @export
 * @interface MileageDTO
 */
export interface MileageDTO {
    /**
     *
     * @type {number}
     * @memberof MileageDTO
     */
    value: number
    /**
     *
     * @type {MileageUnitDTO}
     * @memberof MileageDTO
     */
    unit: MileageUnitDTO
}

/**
 * Check if a given object implements the MileageDTO interface.
 */
export function instanceOfMileageDTO(value: object): value is MileageDTO {
    if (!('value' in value) || value['value'] === undefined) return false
    if (!('unit' in value) || value['unit'] === undefined) return false
    return true
}

export function MileageDTOFromJSON(json: any): MileageDTO {
    return MileageDTOFromJSONTyped(json, false)
}

export function MileageDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MileageDTO {
    if (json == null) {
        return json
    }
    return {
        value: json['value'],
        unit: MileageUnitDTOFromJSON(json['unit']),
    }
}

export function MileageDTOToJSON(json: any): MileageDTO {
    return MileageDTOToJSONTyped(json, false)
}

export function MileageDTOToJSONTyped(value?: MileageDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value
    }

    return {
        value: value['value'],
        unit: MileageUnitDTOToJSON(value['unit']),
    }
}
