/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { TradeInSourceDTO } from './TradeInSourceDTO'
import {
    TradeInSourceDTOFromJSON,
    TradeInSourceDTOFromJSONTyped,
    TradeInSourceDTOToJSON,
    TradeInSourceDTOToJSONTyped,
} from './TradeInSourceDTO'
import type { SensitiveString } from './SensitiveString'
import {
    SensitiveStringFromJSON,
    SensitiveStringFromJSONTyped,
    SensitiveStringToJSON,
    SensitiveStringToJSONTyped,
} from './SensitiveString'

/**
 *
 * @export
 * @interface TradeInMetaData
 */
export interface TradeInMetaData {
    /**
     *
     * @type {SensitiveString}
     * @memberof TradeInMetaData
     */
    vin: SensitiveString
    /**
     * Manufacturer of a customer vehicle
     * @type {string}
     * @memberof TradeInMetaData
     */
    manufacturer: string
    /**
     * Model of a customer vehicle
     * @type {string}
     * @memberof TradeInMetaData
     */
    model?: string
    /**
     * Date-time which represents when was the Trade-in request made
     * @type {Date}
     * @memberof TradeInMetaData
     */
    requestedOn: Date
    /**
     * When true this flag tells the frontend that the data was fetched from the VehicleDataService (customerVehicleData == null). E.g. to hide VDS-only elements for trade-ins with manually entered vehicle details (e.g. non-vds Porsche).
     * @type {boolean}
     * @memberof TradeInMetaData
     */
    isVDSVehicle?: boolean
    /**
     *
     * @type {TradeInSourceDTO}
     * @memberof TradeInMetaData
     */
    source: TradeInSourceDTO
}

/**
 * Check if a given object implements the TradeInMetaData interface.
 */
export function instanceOfTradeInMetaData(value: object): value is TradeInMetaData {
    if (!('vin' in value) || value['vin'] === undefined) return false
    if (!('manufacturer' in value) || value['manufacturer'] === undefined) return false
    if (!('requestedOn' in value) || value['requestedOn'] === undefined) return false
    if (!('source' in value) || value['source'] === undefined) return false
    return true
}

export function TradeInMetaDataFromJSON(json: any): TradeInMetaData {
    return TradeInMetaDataFromJSONTyped(json, false)
}

export function TradeInMetaDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeInMetaData {
    if (json == null) {
        return json
    }
    return {
        vin: SensitiveStringFromJSON(json['vin']),
        manufacturer: json['manufacturer'],
        model: json['model'] == null ? undefined : json['model'],
        requestedOn: new Date(json['requestedOn']),
        isVDSVehicle: json['isVDSVehicle'] == null ? undefined : json['isVDSVehicle'],
        source: TradeInSourceDTOFromJSON(json['source']),
    }
}

export function TradeInMetaDataToJSON(json: any): TradeInMetaData {
    return TradeInMetaDataToJSONTyped(json, false)
}

export function TradeInMetaDataToJSONTyped(value?: TradeInMetaData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value
    }

    return {
        vin: SensitiveStringToJSON(value['vin']),
        manufacturer: value['manufacturer'],
        model: value['model'],
        requestedOn: value['requestedOn'].toISOString(),
        isVDSVehicle: value['isVDSVehicle'],
        source: TradeInSourceDTOToJSON(value['source']),
    }
}
