import { QueryClientConfig } from '@tanstack/react-query'

// determines whether query client should retry a failed request or not (failed request = not status code 2xx)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const defaultUiQueryClientRetry = (failureCount: number, error: any) =>
    (error.response as Response)?.status !== 404 && failureCount < 2

export const defaultUiQueryClientConfig: QueryClientConfig = {
    defaultOptions: {
        queries: {
            // usually we don't need a call to be done again when the user e.g. goes to one tab and then comes back
            refetchOnWindowFocus: false,
            // if response has status 404 (not found), there's no need to retry
            retry: defaultUiQueryClientRetry,
        },
    },
}
