import { defineMessages } from 'react-intl'

export const messagesForTradeInRequestTable = defineMessages({
    // -- Title --
    tradeInRequestTableTitle: {
        id: 'tradeIn.requests.table.title',
        defaultMessage: 'Trade-In Request',
    },
    // -- Total --
    tableTotalCount: {
        id: 'tradeIn.requests.table.total',
        defaultMessage: 'Trade-In Requests',
    },
    // -- Search & filters --
    // see messagesForUseFilter.ts
    // see messagesForUseTextFilter.ts
    // -- Table headers --
    requestDate: {
        id: 'tradeIn.requests.table.header.requestDate',
        defaultMessage: 'Request Date',
    },
    source: {
        id: 'tradeIn.requests.table.header.source',
        defaultMessage: 'Source',
    },
    manufacturer: {
        id: 'tradeIn.requests.table.header.manufacturer',
        defaultMessage: 'Manufacturer',
    },
    model: { id: 'tradeIn.requests.table.header.model', defaultMessage: 'Model' },
    customer: {
        id: 'tradeIn.requests.table.header.customer',
        defaultMessage: 'Customer',
    },
    firstRegistration: {
        id: 'tradeIn.requests.table.header.firstRegistration',
        defaultMessage: 'First registration',
    },
    mileage: {
        id: 'tradeIn.requests.table.header.mileage',
        defaultMessage: 'Mileage',
    },
    vehicleInterestedIn: {
        id: 'tradeIn.requests.table.header.vehicleInterestedIn',
        defaultMessage: 'Vehicle Interested In',
    },
    // -- Table accessibility --
    tableCaption: {
        id: 'tradeIn.requests.table.caption',
        defaultMessage: 'A table containing trade-in requests.',
    },
    // -- Table errors --
    tableFetchErrorGenericDescription: {
        id: 'tradeIn.requests.error.fetchTables.generic.description',
        defaultMessage: 'Failed to retrieve trade-in requests. Please try again later.',
    },
    tableFetchErrorGenericHeading: {
        id: 'tradeIn.requests.error.fetchTables.generic.heading',
        defaultMessage: 'Error',
    },
    // -- Table: no data --
    noTradeInRequestsFoundText: {
        id: 'tradeIn.requests.table.noRequestsFound.text',
        defaultMessage: 'No trade-in requests found.',
    },
    // -- Mileage unit enum --
    mileageUnitKM: {
        id: 'tradeIn.requests.table.mileage.unit.KM',
        defaultMessage: 'km',
    },
    mileageUnitSMI: {
        id: 'tradeIn.requests.table.mileage.unit.SMI',
        defaultMessage: 'mi',
    },
})
