import { useQuery } from '@tanstack/react-query'
import { frontendApi } from 'tim-api-client'

export interface FrontendConfigurationQueryProps {
    marketplaceKey: string
    overrides: unknown
}

// Using one hour as a guesstimate between fetching too often and having an up-to-date cfg.
const ONE_HOUR = 1000 * 60 * 60

export const useFrontendConfigurationQuery = ({ marketplaceKey, overrides }: FrontendConfigurationQueryProps) =>
    useQuery({
        queryKey: ['frontend-configuration', marketplaceKey],
        queryFn: () => frontendApi.fetchFrontendConfiguration({ marketplace: marketplaceKey }),
        // The other options (e.g. retry) are defined by the defaultUiQueryClientConfig
        // which is used by the defaultQueryClient initializer in the App.tsx
        // This way we can override them in tests
        staleTime: ONE_HOUR,
        enabled: !overrides,
    })
