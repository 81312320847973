import { PSpinner } from '@porsche-design-system/components-react'
import styled from 'styled-components'

export const LoadingBar = ({ isLoading }: { isLoading: boolean }) => {
    return isLoading ? (
        <CenterAlignedFlex>
            <PSpinner inert={undefined} size={'medium'} />
        </CenterAlignedFlex>
    ) : null
}

const CenterAlignedFlex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
