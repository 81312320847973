import { TradeInSourceDTO } from 'tim-api-client'

import { useTradeInManagerBackend } from '~/app/backend/useTradeInManagerBackendContext'
import { ConditionAndHistory } from '~/app/pages/request-detail/components/ConditionAndHistory/ConditionAndHistory'

export interface ConditionAndHistoryProps {
    id: string // tradeIn-id
    vin?: string
    isVDSVehicle: boolean
    tradeInSource: TradeInSourceDTO
}

export const ConditionAndHistoryContainer = ({ id, vin, isVDSVehicle, tradeInSource }: ConditionAndHistoryProps) => {
    const { useTradeInConditionAndHistoryQuery } = useTradeInManagerBackend()

    const { data } = useTradeInConditionAndHistoryQuery({ id })

    if (!data) return null

    return <ConditionAndHistory data={data} vin={vin} isVDSVehicle={isVDSVehicle} tradeInSource={tradeInSource} />
}
