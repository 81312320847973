import ReactDOM from 'react-dom'

import { App, AppProps } from './app/App'

declare const VERSION: string
declare const COMMITHASH: string
declare const BRANCH: string
declare const LAST_COMMIT_DATETIME: string

async function systemJsMount(container: HTMLElement) {
    console.log(`Trade-In Manager '${VERSION}' '${COMMITHASH}' '${BRANCH}' '${LAST_COMMIT_DATETIME}'`)
    return {
        mount(props: AppProps) {
            // Note: We are using the react-dom provided by the dealer-app (host application). And it does not provider createRoot. So, this is a workaround suggested by Martin from the Transfprmers team.
            ReactDOM.render(
                <App
                    basePath={props.basePath}
                    context={props.context}
                    getVehicleDetailsUrl={props.getVehicleDetailsUrl}
                />,
                container,
            )
        },
        unmount() {
            // Note: We are using the react-dom provided by the dealer-app (host application). And it does not provider createRoot. So, this is a workaround suggested by Martin from the Transfprmers team.

            ReactDOM.unmountComponentAtNode(container)
        },
    }
}

export default systemJsMount
