/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { ModelInformationDTO } from './ModelInformationDTO'
import {
    ModelInformationDTOFromJSON,
    ModelInformationDTOFromJSONTyped,
    ModelInformationDTOToJSON,
    ModelInformationDTOToJSONTyped,
} from './ModelInformationDTO'
import type { TechnicalDataDTO } from './TechnicalDataDTO'
import {
    TechnicalDataDTOFromJSON,
    TechnicalDataDTOFromJSONTyped,
    TechnicalDataDTOToJSON,
    TechnicalDataDTOToJSONTyped,
} from './TechnicalDataDTO'
import type { ColorsDTO } from './ColorsDTO'
import { ColorsDTOFromJSON, ColorsDTOFromJSONTyped, ColorsDTOToJSON, ColorsDTOToJSONTyped } from './ColorsDTO'

/**
 * Manually entered data of the vehicle that the customer wants to sell, or null. Null means the data is retrievable via the Porsche Vehicle Data Service (VDS).
 * @export
 * @interface CustomerVehicleDataDTO
 */
export interface CustomerVehicleDataDTO {
    /**
     * The manufacturer of this vehicle (usually not Porsche).
     * @type {string}
     * @memberof CustomerVehicleDataDTO
     */
    manufacturer: string
    /**
     *
     * @type {ModelInformationDTO}
     * @memberof CustomerVehicleDataDTO
     */
    modelInformation: ModelInformationDTO
    /**
     *
     * @type {TechnicalDataDTO}
     * @memberof CustomerVehicleDataDTO
     */
    technicalData: TechnicalDataDTO
    /**
     *
     * @type {ColorsDTO}
     * @memberof CustomerVehicleDataDTO
     */
    colors: ColorsDTO
}

/**
 * Check if a given object implements the CustomerVehicleDataDTO interface.
 */
export function instanceOfCustomerVehicleDataDTO(value: object): value is CustomerVehicleDataDTO {
    if (!('manufacturer' in value) || value['manufacturer'] === undefined) return false
    if (!('modelInformation' in value) || value['modelInformation'] === undefined) return false
    if (!('technicalData' in value) || value['technicalData'] === undefined) return false
    if (!('colors' in value) || value['colors'] === undefined) return false
    return true
}

export function CustomerVehicleDataDTOFromJSON(json: any): CustomerVehicleDataDTO {
    return CustomerVehicleDataDTOFromJSONTyped(json, false)
}

export function CustomerVehicleDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerVehicleDataDTO {
    if (json == null) {
        return json
    }
    return {
        manufacturer: json['manufacturer'],
        modelInformation: ModelInformationDTOFromJSON(json['modelInformation']),
        technicalData: TechnicalDataDTOFromJSON(json['technicalData']),
        colors: ColorsDTOFromJSON(json['colors']),
    }
}

export function CustomerVehicleDataDTOToJSON(json: any): CustomerVehicleDataDTO {
    return CustomerVehicleDataDTOToJSONTyped(json, false)
}

export function CustomerVehicleDataDTOToJSONTyped(
    value?: CustomerVehicleDataDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        manufacturer: value['manufacturer'],
        modelInformation: ModelInformationDTOToJSON(value['modelInformation']),
        technicalData: TechnicalDataDTOToJSON(value['technicalData']),
        colors: ColorsDTOToJSON(value['colors']),
    }
}
