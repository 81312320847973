/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ConditionDTO
 */
export interface ConditionDTO {
    /**
     *
     * @type {boolean}
     * @memberof ConditionDTO
     */
    accidentFree: boolean
    /**
     *
     * @type {boolean}
     * @memberof ConditionDTO
     */
    fullServiceHistory: boolean
}

/**
 * Check if a given object implements the ConditionDTO interface.
 */
export function instanceOfConditionDTO(value: object): value is ConditionDTO {
    if (!('accidentFree' in value) || value['accidentFree'] === undefined) return false
    if (!('fullServiceHistory' in value) || value['fullServiceHistory'] === undefined) return false
    return true
}

export function ConditionDTOFromJSON(json: any): ConditionDTO {
    return ConditionDTOFromJSONTyped(json, false)
}

export function ConditionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionDTO {
    if (json == null) {
        return json
    }
    return {
        accidentFree: json['accidentFree'],
        fullServiceHistory: json['fullServiceHistory'],
    }
}

export function ConditionDTOToJSON(json: any): ConditionDTO {
    return ConditionDTOToJSONTyped(json, false)
}

export function ConditionDTOToJSONTyped(value?: ConditionDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value
    }

    return {
        accidentFree: value['accidentFree'],
        fullServiceHistory: value['fullServiceHistory'],
    }
}
