import { useQuery } from '@tanstack/react-query'
import { tradeInControllerApi } from 'tim-api-client'

export interface VehiclePriceEstimationQueryProps {
    tradeInId: string
    locale: string
}

export const useMarketPriceQuery = ({ tradeInId, locale }: VehiclePriceEstimationQueryProps) =>
    useQuery({
        queryKey: ['trade-in-request', tradeInId, 'price-estimation', locale],
        queryFn: () => tradeInControllerApi.fetchTradeInVehiclePriceEstimation({ id: tradeInId, locale }),
    })
