/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface TradeInFeedbackPriceEstimationDTO
 */
export interface TradeInFeedbackPriceEstimationDTO {
    /**
     * Decide if the price estimation is helpful
     * @type {boolean}
     * @memberof TradeInFeedbackPriceEstimationDTO
     */
    priceEstimationIsHelpful: boolean
    /**
     * Locale used by the dealer
     * @type {string}
     * @memberof TradeInFeedbackPriceEstimationDTO
     */
    locale: string
    /**
     * Free Text for the customer feedback
     * @type {string}
     * @memberof TradeInFeedbackPriceEstimationDTO
     */
    freeText?: string
}

/**
 * Check if a given object implements the TradeInFeedbackPriceEstimationDTO interface.
 */
export function instanceOfTradeInFeedbackPriceEstimationDTO(value: object): value is TradeInFeedbackPriceEstimationDTO {
    if (!('priceEstimationIsHelpful' in value) || value['priceEstimationIsHelpful'] === undefined) return false
    if (!('locale' in value) || value['locale'] === undefined) return false
    return true
}

export function TradeInFeedbackPriceEstimationDTOFromJSON(json: any): TradeInFeedbackPriceEstimationDTO {
    return TradeInFeedbackPriceEstimationDTOFromJSONTyped(json, false)
}

export function TradeInFeedbackPriceEstimationDTOFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): TradeInFeedbackPriceEstimationDTO {
    if (json == null) {
        return json
    }
    return {
        priceEstimationIsHelpful: json['priceEstimationIsHelpful'],
        locale: json['locale'],
        freeText: json['freeText'] == null ? undefined : json['freeText'],
    }
}

export function TradeInFeedbackPriceEstimationDTOToJSON(json: any): TradeInFeedbackPriceEstimationDTO {
    return TradeInFeedbackPriceEstimationDTOToJSONTyped(json, false)
}

export function TradeInFeedbackPriceEstimationDTOToJSONTyped(
    value?: TradeInFeedbackPriceEstimationDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        priceEstimationIsHelpful: value['priceEstimationIsHelpful'],
        locale: value['locale'],
        freeText: value['freeText'],
    }
}
