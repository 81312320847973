/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { HiddenReason } from './HiddenReason'
import {
    HiddenReasonFromJSON,
    HiddenReasonFromJSONTyped,
    HiddenReasonToJSON,
    HiddenReasonToJSONTyped,
} from './HiddenReason'

/**
 *
 * @export
 * @interface SensitiveString
 */
export interface SensitiveString {
    /**
     *
     * @type {HiddenReason}
     * @memberof SensitiveString
     */
    hidden: HiddenReason
    /**
     * Sensitive string value
     * @type {string}
     * @memberof SensitiveString
     */
    value?: string
}

/**
 * Check if a given object implements the SensitiveString interface.
 */
export function instanceOfSensitiveString(value: object): value is SensitiveString {
    if (!('hidden' in value) || value['hidden'] === undefined) return false
    return true
}

export function SensitiveStringFromJSON(json: any): SensitiveString {
    return SensitiveStringFromJSONTyped(json, false)
}

export function SensitiveStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): SensitiveString {
    if (json == null) {
        return json
    }
    return {
        hidden: HiddenReasonFromJSON(json['hidden']),
        value: json['value'] == null ? undefined : json['value'],
    }
}

export function SensitiveStringToJSON(json: any): SensitiveString {
    return SensitiveStringToJSONTyped(json, false)
}

export function SensitiveStringToJSONTyped(value?: SensitiveString | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value
    }

    return {
        hidden: HiddenReasonToJSON(value['hidden']),
        value: value['value'],
    }
}
