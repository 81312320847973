/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const BodyTypeDTO = {
    LIMOUSINE: 'LIMOUSINE',
    COMBI: 'COMBI',
    COUPE: 'COUPE',
    CABRIOLET: 'CABRIOLET',
    SUV: 'SUV',
    OTHER: 'OTHER',
} as const
export type BodyTypeDTO = (typeof BodyTypeDTO)[keyof typeof BodyTypeDTO]

export function instanceOfBodyTypeDTO(value: any): boolean {
    for (const key in BodyTypeDTO) {
        if (Object.prototype.hasOwnProperty.call(BodyTypeDTO, key)) {
            if (BodyTypeDTO[key as keyof typeof BodyTypeDTO] === value) {
                return true
            }
        }
    }
    return false
}

export function BodyTypeDTOFromJSON(json: any): BodyTypeDTO {
    return BodyTypeDTOFromJSONTyped(json, false)
}

export function BodyTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyTypeDTO {
    return json as BodyTypeDTO
}

export function BodyTypeDTOToJSON(value?: BodyTypeDTO | null): any {
    return value as any
}

export function BodyTypeDTOToJSONTyped(value: any, ignoreDiscriminator: boolean): BodyTypeDTO {
    return value as BodyTypeDTO
}
