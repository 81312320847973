/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { MileageDTO } from './MileageDTO'
import { MileageDTOFromJSON, MileageDTOFromJSONTyped, MileageDTOToJSON, MileageDTOToJSONTyped } from './MileageDTO'

/**
 *
 * @export
 * @interface TradeInVehicleSearchParameters
 */
export interface TradeInVehicleSearchParameters {
    /**
     * Fetched from external VDS, have to handle unexpected undefined.
     * @type {string}
     * @memberof TradeInVehicleSearchParameters
     */
    derivativeKey?: string
    /**
     * Fetched from external VDS, have to handle unexpected undefined.
     * @type {string}
     * @memberof TradeInVehicleSearchParameters
     */
    modelGenerationKey?: string
    /**
     * Fetched from external VDS, have to handle unexpected undefined.
     * @type {string}
     * @memberof TradeInVehicleSearchParameters
     */
    modelSeriesKey?: string
    /**
     *
     * @type {MileageDTO}
     * @memberof TradeInVehicleSearchParameters
     */
    mileage: MileageDTO
    /**
     * First registration date (YYYY-MM)
     * @type {string}
     * @memberof TradeInVehicleSearchParameters
     */
    firstRegistrationDate: string
}

/**
 * Check if a given object implements the TradeInVehicleSearchParameters interface.
 */
export function instanceOfTradeInVehicleSearchParameters(value: object): value is TradeInVehicleSearchParameters {
    if (!('mileage' in value) || value['mileage'] === undefined) return false
    if (!('firstRegistrationDate' in value) || value['firstRegistrationDate'] === undefined) return false
    return true
}

export function TradeInVehicleSearchParametersFromJSON(json: any): TradeInVehicleSearchParameters {
    return TradeInVehicleSearchParametersFromJSONTyped(json, false)
}

export function TradeInVehicleSearchParametersFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): TradeInVehicleSearchParameters {
    if (json == null) {
        return json
    }
    return {
        derivativeKey: json['derivativeKey'] == null ? undefined : json['derivativeKey'],
        modelGenerationKey: json['modelGenerationKey'] == null ? undefined : json['modelGenerationKey'],
        modelSeriesKey: json['modelSeriesKey'] == null ? undefined : json['modelSeriesKey'],
        mileage: MileageDTOFromJSON(json['mileage']),
        firstRegistrationDate: json['firstRegistrationDate'],
    }
}

export function TradeInVehicleSearchParametersToJSON(json: any): TradeInVehicleSearchParameters {
    return TradeInVehicleSearchParametersToJSONTyped(json, false)
}

export function TradeInVehicleSearchParametersToJSONTyped(
    value?: TradeInVehicleSearchParameters | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        derivativeKey: value['derivativeKey'],
        modelGenerationKey: value['modelGenerationKey'],
        modelSeriesKey: value['modelSeriesKey'],
        mileage: MileageDTOToJSON(value['mileage']),
        firstRegistrationDate: value['firstRegistrationDate'],
    }
}
