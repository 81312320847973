/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
    ConditionAndHistoryResponseDTO,
    CustomerDTO,
    EquipmentDTO,
    IdDTO,
    TradeInFeedbackDTO,
    TradeInFeedbackPriceEstimationDTO,
    TradeInMetaData,
    TradeInOverviewPage,
    TradeInRequestDTO,
    TradeInVehicleDealPriceDataDTO,
    TradeInVehicleDetail,
    TradeInVehicleEstimationDTO,
    TradeInVehicleMSRPPriceDataDTO,
    TradeInVehicleSearchParameters,
    VehicleOfInterestDTO,
} from '../models/index'
import {
    ConditionAndHistoryResponseDTOFromJSON,
    ConditionAndHistoryResponseDTOToJSON,
    CustomerDTOFromJSON,
    CustomerDTOToJSON,
    EquipmentDTOFromJSON,
    EquipmentDTOToJSON,
    IdDTOFromJSON,
    IdDTOToJSON,
    TradeInFeedbackDTOFromJSON,
    TradeInFeedbackDTOToJSON,
    TradeInFeedbackPriceEstimationDTOFromJSON,
    TradeInFeedbackPriceEstimationDTOToJSON,
    TradeInMetaDataFromJSON,
    TradeInMetaDataToJSON,
    TradeInOverviewPageFromJSON,
    TradeInOverviewPageToJSON,
    TradeInRequestDTOFromJSON,
    TradeInRequestDTOToJSON,
    TradeInVehicleDealPriceDataDTOFromJSON,
    TradeInVehicleDealPriceDataDTOToJSON,
    TradeInVehicleDetailFromJSON,
    TradeInVehicleDetailToJSON,
    TradeInVehicleEstimationDTOFromJSON,
    TradeInVehicleEstimationDTOToJSON,
    TradeInVehicleMSRPPriceDataDTOFromJSON,
    TradeInVehicleMSRPPriceDataDTOToJSON,
    TradeInVehicleSearchParametersFromJSON,
    TradeInVehicleSearchParametersToJSON,
    VehicleOfInterestDTOFromJSON,
    VehicleOfInterestDTOToJSON,
} from '../models/index'

export interface CreateTradeInRequest {
    tradeInRequestDTO: TradeInRequestDTO
}

export interface CreateTradeInFeedbackRequest {
    id: string
    tradeInFeedbackDTO: TradeInFeedbackDTO
}

export interface CreateTradeInFeedbackPriceEstimationRequest {
    id: string
    tradeInFeedbackPriceEstimationDTO: TradeInFeedbackPriceEstimationDTO
}

export interface FetchTradeInConditionAndHistoryRequest {
    id: string
}

export interface FetchTradeInCustomerRequest {
    id: string
}

export interface FetchTradeInMetaInfoRequest {
    id: string
}

export interface FetchTradeInVehicleDealPriceDataRequest {
    id: string
}

export interface FetchTradeInVehicleEquipmentRequest {
    id: string
    locale: string
}

export interface FetchTradeInVehicleInformationRequest {
    id: string
    locale: string
}

export interface FetchTradeInVehicleMsrpDataRequest {
    id: string
    locale: string
}

export interface FetchTradeInVehicleOfInterestInformationRequest {
    id: string
}

export interface FetchTradeInVehiclePriceEstimationRequest {
    id: string
    locale: string
}

export interface FetchTradeInVehicleSearchParametersRequest {
    id: string
    locale: string
}

export interface FetchTradeInsRequest {
    dealerEntryDn: string
    country: string
    searchQuery?: string
    page?: number
    size?: number
    from?: number
    to?: number
}

/**
 *
 */
export class TradeInControllerApi extends runtime.BaseAPI {
    /**
     * Creates a trade-in request.
     */
    async createTradeInRaw(
        requestParameters: CreateTradeInRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<IdDTO>> {
        if (requestParameters['tradeInRequestDTO'] == null) {
            throw new runtime.RequiredError(
                'tradeInRequestDTO',
                'Required parameter "tradeInRequestDTO" was null or undefined when calling createTradeIn().',
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = await token('m2mAuth', [])

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/trade-in-request`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: TradeInRequestDTOToJSON(requestParameters['tradeInRequestDTO']),
            },
            initOverrides,
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => IdDTOFromJSON(jsonValue))
    }

    /**
     * Creates a trade-in request.
     */
    async createTradeIn(
        requestParameters: CreateTradeInRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<IdDTO> {
        const response = await this.createTradeInRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     * Creates a slack post with the user feedback transmitted in this request.
     */
    async createTradeInFeedbackRaw(
        requestParameters: CreateTradeInFeedbackRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<IdDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling createTradeInFeedback().',
            )
        }

        if (requestParameters['tradeInFeedbackDTO'] == null) {
            throw new runtime.RequiredError(
                'tradeInFeedbackDTO',
                'Required parameter "tradeInFeedbackDTO" was null or undefined when calling createTradeInFeedback().',
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = await token('m2mAuth', [])

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/trade-in-requests/{id}/feedback`.replace(
                    `{${'id'}}`,
                    encodeURIComponent(String(requestParameters['id'])),
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: TradeInFeedbackDTOToJSON(requestParameters['tradeInFeedbackDTO']),
            },
            initOverrides,
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => IdDTOFromJSON(jsonValue))
    }

    /**
     * Creates a slack post with the user feedback transmitted in this request.
     */
    async createTradeInFeedback(
        requestParameters: CreateTradeInFeedbackRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<IdDTO> {
        const response = await this.createTradeInFeedbackRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     * Creates a slack post with the user feedback for price estimation transmitted in this request.
     */
    async createTradeInFeedbackPriceEstimationRaw(
        requestParameters: CreateTradeInFeedbackPriceEstimationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<IdDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling createTradeInFeedbackPriceEstimation().',
            )
        }

        if (requestParameters['tradeInFeedbackPriceEstimationDTO'] == null) {
            throw new runtime.RequiredError(
                'tradeInFeedbackPriceEstimationDTO',
                'Required parameter "tradeInFeedbackPriceEstimationDTO" was null or undefined when calling createTradeInFeedbackPriceEstimation().',
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = await token('m2mAuth', [])

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/trade-in-requests/{id}/feedback-price-estimation`.replace(
                    `{${'id'}}`,
                    encodeURIComponent(String(requestParameters['id'])),
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: TradeInFeedbackPriceEstimationDTOToJSON(requestParameters['tradeInFeedbackPriceEstimationDTO']),
            },
            initOverrides,
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => IdDTOFromJSON(jsonValue))
    }

    /**
     * Creates a slack post with the user feedback for price estimation transmitted in this request.
     */
    async createTradeInFeedbackPriceEstimation(
        requestParameters: CreateTradeInFeedbackPriceEstimationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<IdDTO> {
        const response = await this.createTradeInFeedbackPriceEstimationRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     * Fetch the condition and history information associated to a single trade-in request
     */
    async fetchTradeInConditionAndHistoryRaw(
        requestParameters: FetchTradeInConditionAndHistoryRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ConditionAndHistoryResponseDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchTradeInConditionAndHistory().',
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = await token('ppnAuth', [])

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/trade-in-requests/{id}/condition-and-history`.replace(
                    `{${'id'}}`,
                    encodeURIComponent(String(requestParameters['id'])),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => ConditionAndHistoryResponseDTOFromJSON(jsonValue))
    }

    /**
     * Fetch the condition and history information associated to a single trade-in request
     */
    async fetchTradeInConditionAndHistory(
        requestParameters: FetchTradeInConditionAndHistoryRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ConditionAndHistoryResponseDTO> {
        const response = await this.fetchTradeInConditionAndHistoryRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     * Fetch the customer information associated to a single trade-in request
     */
    async fetchTradeInCustomerRaw(
        requestParameters: FetchTradeInCustomerRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CustomerDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchTradeInCustomer().',
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = await token('ppnAuth', [])

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/trade-in-requests/{id}/customer`.replace(
                    `{${'id'}}`,
                    encodeURIComponent(String(requestParameters['id'])),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerDTOFromJSON(jsonValue))
    }

    /**
     * Fetch the customer information associated to a single trade-in request
     */
    async fetchTradeInCustomer(
        requestParameters: FetchTradeInCustomerRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CustomerDTO> {
        const response = await this.fetchTradeInCustomerRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     * Fetch the meta information of a single trade-in request
     */
    async fetchTradeInMetaInfoRaw(
        requestParameters: FetchTradeInMetaInfoRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<TradeInMetaData>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchTradeInMetaInfo().',
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = await token('ppnAuth', [])

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/trade-in-requests/{id}/meta`.replace(
                    `{${'id'}}`,
                    encodeURIComponent(String(requestParameters['id'])),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => TradeInMetaDataFromJSON(jsonValue))
    }

    /**
     * Fetch the meta information of a single trade-in request
     */
    async fetchTradeInMetaInfo(
        requestParameters: FetchTradeInMetaInfoRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<TradeInMetaData> {
        const response = await this.fetchTradeInMetaInfoRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     * Fetch the customer-shown price information as part of deal-builder / payment estimator
     */
    async fetchTradeInVehicleDealPriceDataRaw(
        requestParameters: FetchTradeInVehicleDealPriceDataRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<TradeInVehicleDealPriceDataDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchTradeInVehicleDealPriceData().',
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = await token('ppnAuth', [])

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/trade-in-requests/{id}/deal-price-data`.replace(
                    `{${'id'}}`,
                    encodeURIComponent(String(requestParameters['id'])),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => TradeInVehicleDealPriceDataDTOFromJSON(jsonValue))
    }

    /**
     * Fetch the customer-shown price information as part of deal-builder / payment estimator
     */
    async fetchTradeInVehicleDealPriceData(
        requestParameters: FetchTradeInVehicleDealPriceDataRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<TradeInVehicleDealPriceDataDTO> {
        const response = await this.fetchTradeInVehicleDealPriceDataRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     * Fetch the equipment of the vehicle that the customer wants to trade in
     */
    async fetchTradeInVehicleEquipmentRaw(
        requestParameters: FetchTradeInVehicleEquipmentRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<EquipmentDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchTradeInVehicleEquipment().',
            )
        }

        if (requestParameters['locale'] == null) {
            throw new runtime.RequiredError(
                'locale',
                'Required parameter "locale" was null or undefined when calling fetchTradeInVehicleEquipment().',
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = await token('ppnAuth', [])

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/trade-in-requests/{id}/equipment/{locale}`
                    .replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id'])))
                    .replace(`{${'locale'}}`, encodeURIComponent(String(requestParameters['locale']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentDTOFromJSON(jsonValue))
    }

    /**
     * Fetch the equipment of the vehicle that the customer wants to trade in
     */
    async fetchTradeInVehicleEquipment(
        requestParameters: FetchTradeInVehicleEquipmentRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<EquipmentDTO> {
        const response = await this.fetchTradeInVehicleEquipmentRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     * Fetch the vehicle information of a single trade-in request
     */
    async fetchTradeInVehicleInformationRaw(
        requestParameters: FetchTradeInVehicleInformationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<TradeInVehicleDetail>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchTradeInVehicleInformation().',
            )
        }

        if (requestParameters['locale'] == null) {
            throw new runtime.RequiredError(
                'locale',
                'Required parameter "locale" was null or undefined when calling fetchTradeInVehicleInformation().',
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = await token('ppnAuth', [])

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/trade-in-requests/{id}/vehicle-information/{locale}`
                    .replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id'])))
                    .replace(`{${'locale'}}`, encodeURIComponent(String(requestParameters['locale']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => TradeInVehicleDetailFromJSON(jsonValue))
    }

    /**
     * Fetch the vehicle information of a single trade-in request
     */
    async fetchTradeInVehicleInformation(
        requestParameters: FetchTradeInVehicleInformationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<TradeInVehicleDetail> {
        const response = await this.fetchTradeInVehicleInformationRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     * Fetch the MSRP data for the trade-in requests Vehicle
     */
    async fetchTradeInVehicleMsrpDataRaw(
        requestParameters: FetchTradeInVehicleMsrpDataRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<TradeInVehicleMSRPPriceDataDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchTradeInVehicleMsrpData().',
            )
        }

        if (requestParameters['locale'] == null) {
            throw new runtime.RequiredError(
                'locale',
                'Required parameter "locale" was null or undefined when calling fetchTradeInVehicleMsrpData().',
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = await token('ppnAuth', [])

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/trade-in-requests/{id}/msrp-price-data/{locale}`
                    .replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id'])))
                    .replace(`{${'locale'}}`, encodeURIComponent(String(requestParameters['locale']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => TradeInVehicleMSRPPriceDataDTOFromJSON(jsonValue))
    }

    /**
     * Fetch the MSRP data for the trade-in requests Vehicle
     */
    async fetchTradeInVehicleMsrpData(
        requestParameters: FetchTradeInVehicleMsrpDataRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<TradeInVehicleMSRPPriceDataDTO> {
        const response = await this.fetchTradeInVehicleMsrpDataRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     * Fetch the vehicle of interest information of a single trade-in request
     */
    async fetchTradeInVehicleOfInterestInformationRaw(
        requestParameters: FetchTradeInVehicleOfInterestInformationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<VehicleOfInterestDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchTradeInVehicleOfInterestInformation().',
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = await token('ppnAuth', [])

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/trade-in-requests/{id}/vehicle-of-interest-information`.replace(
                    `{${'id'}}`,
                    encodeURIComponent(String(requestParameters['id'])),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleOfInterestDTOFromJSON(jsonValue))
    }

    /**
     * Fetch the vehicle of interest information of a single trade-in request
     */
    async fetchTradeInVehicleOfInterestInformation(
        requestParameters: FetchTradeInVehicleOfInterestInformationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<VehicleOfInterestDTO> {
        const response = await this.fetchTradeInVehicleOfInterestInformationRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     * Fetch the Price Estimation for the trade-in requests Vehicle
     */
    async fetchTradeInVehiclePriceEstimationRaw(
        requestParameters: FetchTradeInVehiclePriceEstimationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<TradeInVehicleEstimationDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchTradeInVehiclePriceEstimation().',
            )
        }

        if (requestParameters['locale'] == null) {
            throw new runtime.RequiredError(
                'locale',
                'Required parameter "locale" was null or undefined when calling fetchTradeInVehiclePriceEstimation().',
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = await token('ppnAuth', [])

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/trade-in-requests/{id}/price-estimation/{locale}`
                    .replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id'])))
                    .replace(`{${'locale'}}`, encodeURIComponent(String(requestParameters['locale']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => TradeInVehicleEstimationDTOFromJSON(jsonValue))
    }

    /**
     * Fetch the Price Estimation for the trade-in requests Vehicle
     */
    async fetchTradeInVehiclePriceEstimation(
        requestParameters: FetchTradeInVehiclePriceEstimationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<TradeInVehicleEstimationDTO> {
        const response = await this.fetchTradeInVehiclePriceEstimationRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     * Fetch the parameters used to create search-links based on a single trade-in request vehicle
     */
    async fetchTradeInVehicleSearchParametersRaw(
        requestParameters: FetchTradeInVehicleSearchParametersRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<TradeInVehicleSearchParameters>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchTradeInVehicleSearchParameters().',
            )
        }

        if (requestParameters['locale'] == null) {
            throw new runtime.RequiredError(
                'locale',
                'Required parameter "locale" was null or undefined when calling fetchTradeInVehicleSearchParameters().',
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = await token('ppnAuth', [])

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/trade-in-requests/{id}/vehicle-search-parameters/{locale}`
                    .replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id'])))
                    .replace(`{${'locale'}}`, encodeURIComponent(String(requestParameters['locale']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => TradeInVehicleSearchParametersFromJSON(jsonValue))
    }

    /**
     * Fetch the parameters used to create search-links based on a single trade-in request vehicle
     */
    async fetchTradeInVehicleSearchParameters(
        requestParameters: FetchTradeInVehicleSearchParametersRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<TradeInVehicleSearchParameters> {
        const response = await this.fetchTradeInVehicleSearchParametersRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     * Fetch a page of Trade-ins. Usually called by trade-in-manager project.
     */
    async fetchTradeInsRaw(
        requestParameters: FetchTradeInsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<TradeInOverviewPage>> {
        if (requestParameters['dealerEntryDn'] == null) {
            throw new runtime.RequiredError(
                'dealerEntryDn',
                'Required parameter "dealerEntryDn" was null or undefined when calling fetchTradeIns().',
            )
        }

        if (requestParameters['country'] == null) {
            throw new runtime.RequiredError(
                'country',
                'Required parameter "country" was null or undefined when calling fetchTradeIns().',
            )
        }

        const queryParameters: any = {}

        if (requestParameters['searchQuery'] != null) {
            queryParameters['searchQuery'] = requestParameters['searchQuery']
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page']
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size']
        }

        if (requestParameters['from'] != null) {
            queryParameters['from'] = requestParameters['from']
        }

        if (requestParameters['to'] != null) {
            queryParameters['to'] = requestParameters['to']
        }

        if (requestParameters['dealerEntryDn'] != null) {
            queryParameters['dealerEntryDn'] = requestParameters['dealerEntryDn']
        }

        if (requestParameters['country'] != null) {
            queryParameters['country'] = requestParameters['country']
        }

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = await token('ppnAuth', [])

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/trade-in-request`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => TradeInOverviewPageFromJSON(jsonValue))
    }

    /**
     * Fetch a page of Trade-ins. Usually called by trade-in-manager project.
     */
    async fetchTradeIns(
        requestParameters: FetchTradeInsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<TradeInOverviewPage> {
        const response = await this.fetchTradeInsRaw(requestParameters, initOverrides)
        return await response.value()
    }
}
