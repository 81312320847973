/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { ConditionDTO } from './ConditionDTO'
import {
    ConditionDTOFromJSON,
    ConditionDTOFromJSONTyped,
    ConditionDTOToJSON,
    ConditionDTOToJSONTyped,
} from './ConditionDTO'
import type { MileageDTO } from './MileageDTO'
import { MileageDTOFromJSON, MileageDTOFromJSONTyped, MileageDTOToJSON, MileageDTOToJSONTyped } from './MileageDTO'

/**
 *
 * @export
 * @interface ConditionAndHistoryDTO
 */
export interface ConditionAndHistoryDTO {
    /**
     * First registration date (YYYY-MM or YYYY-MM-DD)
     * @type {string}
     * @memberof ConditionAndHistoryDTO
     */
    firstRegistrationDate: string
    /**
     *
     * @type {MileageDTO}
     * @memberof ConditionAndHistoryDTO
     */
    mileage: MileageDTO
    /**
     *
     * @type {number}
     * @memberof ConditionAndHistoryDTO
     */
    previousOwners: number
    /**
     *
     * @type {boolean}
     * @memberof ConditionAndHistoryDTO
     */
    vatDeductible?: boolean
    /**
     *
     * @type {ConditionDTO}
     * @memberof ConditionAndHistoryDTO
     */
    condition: ConditionDTO
}

/**
 * Check if a given object implements the ConditionAndHistoryDTO interface.
 */
export function instanceOfConditionAndHistoryDTO(value: object): value is ConditionAndHistoryDTO {
    if (!('firstRegistrationDate' in value) || value['firstRegistrationDate'] === undefined) return false
    if (!('mileage' in value) || value['mileage'] === undefined) return false
    if (!('previousOwners' in value) || value['previousOwners'] === undefined) return false
    if (!('condition' in value) || value['condition'] === undefined) return false
    return true
}

export function ConditionAndHistoryDTOFromJSON(json: any): ConditionAndHistoryDTO {
    return ConditionAndHistoryDTOFromJSONTyped(json, false)
}

export function ConditionAndHistoryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionAndHistoryDTO {
    if (json == null) {
        return json
    }
    return {
        firstRegistrationDate: json['firstRegistrationDate'],
        mileage: MileageDTOFromJSON(json['mileage']),
        previousOwners: json['previousOwners'],
        vatDeductible: json['vatDeductible'] == null ? undefined : json['vatDeductible'],
        condition: ConditionDTOFromJSON(json['condition']),
    }
}

export function ConditionAndHistoryDTOToJSON(json: any): ConditionAndHistoryDTO {
    return ConditionAndHistoryDTOToJSONTyped(json, false)
}

export function ConditionAndHistoryDTOToJSONTyped(
    value?: ConditionAndHistoryDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        firstRegistrationDate: value['firstRegistrationDate'],
        mileage: MileageDTOToJSON(value['mileage']),
        previousOwners: value['previousOwners'],
        vatDeductible: value['vatDeductible'],
        condition: ConditionDTOToJSON(value['condition']),
    }
}
