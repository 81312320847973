import { PPopover } from '@porsche-design-system/components-react'
import { textSmallStyle, themeLightStateDisabled } from '@porsche-design-system/components-react/styles'
import { PropsWithChildren } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'
import { HiddenReason } from 'tim-api-client'

export interface HideableValueProps extends PropsWithChildren {
    hidden?: HiddenReason
    noIcon?: boolean
}

const HiddenPersonalDataInfo = ({ noIcon }: { noIcon?: boolean }) => {
    const { formatMessage } = useIntl()
    return (
        <ValueTextSpan>
            {formatMessage(messages.label)}&nbsp;
            {!noIcon ? (
                <PPopover inert={undefined} direction={'top'}>
                    {formatMessage(messages.roleNotAllowed)}
                </PPopover>
            ) : null}
        </ValueTextSpan>
    )
}

export const HideableValue = ({ children, hidden, noIcon }: HideableValueProps) => {
    if (hidden && hidden !== 'DATA_NOT_HIDDEN') {
        return <HiddenPersonalDataInfo noIcon={noIcon} />
    }

    return children ?? '-'
}

const TextSmallSpan = styled.span({
    ...textSmallStyle,
})

const ValueTextSpan = styled(TextSmallSpan)`
    color: ${themeLightStateDisabled};
    margin: 0;
`

export const messages = defineMessages({
    label: {
        id: 'tradeIn.details.personalDataHidden.label',
        defaultMessage: 'Personal Data is hidden',
    },
    roleNotAllowed: {
        id: 'tradeIn.details.personalDataHidden.reason.roleNotAllowed',
        defaultMessage: 'Your role is not allowed to view personal data',
    },
})
