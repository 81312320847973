import { useDealerContext } from '@slfinrtl/context'

import { useTradeInManagerBackend } from '~/app/backend/useTradeInManagerBackendContext'
import { Equipment } from '~/app/pages/request-detail/components/Equipment/Equipment'

export interface EquipmentInfoBoxProps {
    id: string
}

export const EquipmentContainer = ({ id }: EquipmentInfoBoxProps) => {
    const { locale } = useDealerContext()

    const { useTradeInEquipmentQuery } = useTradeInManagerBackend()

    const { data } = useTradeInEquipmentQuery({ id, locale })

    return (
        <Equipment
            data={
                data ?? {
                    categories: [],
                }
            }
        />
    )
}
