import { PButton, useToastManager } from '@porsche-design-system/components-react'
import { useDealerContext } from '@slfinrtl/context'
import { useMutation } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { FeedbackReasonDTO, LikertFeedbackDTO, tradeInControllerApi, TradeInFeedbackDTO } from 'tim-api-client'

import {
    BadReasonFeedbackSelectWrapper,
    ErrorPInlineNotification,
    FeedbackReasonBox,
    FreeTextContainerDiv,
    FreeTextPTextareaWrapper,
    HeadingMedium,
    HeadingSmall,
    Score,
    ScoreBox,
    Scores,
    SelectWrapper,
    SubmitButtonWrapper,
    TextSmall,
} from '~/app/pages/request-detail/components/FeedbackForm/FeedbackForm.styled'
import {
    feedbackFormMessages,
    feedbackFormNotifications,
} from '~/app/pages/request-detail/components/FeedbackForm/messages'
import {
    feedbackReasonOptionsMapping,
    TradeInFeedbackFormSchema,
} from '~/app/pages/request-detail/components/FeedbackForm/TradeInFeedbackFormSchema'
import { useFormValidation } from '~/app/ui-utils'

export type FeedbackFormProps = {
    id: string
}

/**
 * Note: Feedback-Form does not look that great on very slim viewports (i.e. mobile xs < 760px) but this
 * is out of scope for now, since dealers will almost always use desktop viewports and the Form is still functional
 * it's only the texts left and right that will line-break multiple times
 */
export const FeedbackForm = ({ id }: FeedbackFormProps) => {
    const { formatMessage } = useIntl()
    // Using <PToast /> in TradeInRequestDetailPage
    const { addMessage } = useToastManager()
    const { locale } = useDealerContext()

    const [selectedLikert, setSelectedLikert] = useState<string | undefined>(undefined)
    const [selectedReason, setSelectedReason] = useState<string | undefined>(undefined)
    const [freeText, setFreeText] = useState<string | undefined>(undefined)
    const [transmissionError, setTransmissionError] = useState<boolean>(false)

    const { mutateAsync, isPending } = useMutation({
        mutationFn: (tradeInFeedbackDTO: TradeInFeedbackDTO) =>
            tradeInControllerApi.createTradeInFeedback({
                id,
                tradeInFeedbackDTO,
            }),
        onSuccess: () => {
            addMessage({ text: formatMessage(feedbackFormNotifications.transmissionSuccess), state: 'success' })
            setFreeText(undefined)
            setSelectedLikert(undefined)
            setSelectedReason(undefined)
            setTransmissionError(false)
        },
        onError: () => setTransmissionError(true),
    })

    const isFormValid = useFormValidation(TradeInFeedbackFormSchema, {
        likertValue: selectedLikert as LikertFeedbackDTO,
        feedbackReason: selectedReason as FeedbackReasonDTO,
        freeText,
        locale,
    })

    const sendAnswers = async () => {
        if (!selectedLikert) {
            return
        }

        await mutateAsync({
            likertValue: selectedLikert as LikertFeedbackDTO,
            feedbackReason: selectedReason as FeedbackReasonDTO,
            freeText,
            locale,
        })
    }

    /**
     * used to transition smoothly to the bottom of the page to make sure rendered elements are visible (e.g. textarea,
     * send feedback button etc) after the form state has changed
     */
    const scrollToPageBottom = () => {
        // using setTimeout here to make sure we get the correct new height of the document during next render
        setTimeout(
            () =>
                window.scrollTo({
                    left: 0,
                    top: document.body.scrollHeight,
                    behavior: 'smooth',
                }),
            50,
        )
    }

    return (
        <>
            <HeadingMedium>{formatMessage(feedbackFormMessages.titleLikertAnswers)}</HeadingMedium>
            <ScoreBox>
                <TextSmall>{formatMessage(feedbackFormMessages.labelVeryDissatisfied)}</TextSmall>
                <Scores>
                    {Object.keys(LikertFeedbackDTO).map((likertKey, index) => (
                        <Score
                            $isActive={selectedLikert === likertKey}
                            onClick={() => {
                                setSelectedLikert(likertKey)
                                setSelectedReason(undefined)
                                scrollToPageBottom()
                            }}
                            key={likertKey}
                        >
                            {index + 1}
                        </Score>
                    ))}
                </Scores>
                <TextSmall>{formatMessage(feedbackFormMessages.labelVerySatisfied)}</TextSmall>
            </ScoreBox>

            {/* Feedback Reason Select */}
            {(selectedLikert === LikertFeedbackDTO.VeryDissatisfied ||
                selectedLikert === LikertFeedbackDTO.SomewhatDissatisfied ||
                selectedLikert === LikertFeedbackDTO.NeitherNor) && (
                <FeedbackReasonBox>
                    <HeadingSmall>{formatMessage(feedbackFormMessages.badFeedbackReason)}</HeadingSmall>
                    <BadReasonFeedbackSelectWrapper>
                        <SelectWrapper inert={undefined} hideLabel>
                            <select
                                name="bad-feedback-reason-select"
                                onChange={(event) => {
                                    const reason = event.currentTarget.value
                                    setSelectedReason(reason)
                                    setFreeText(undefined)
                                    if (
                                        reason === FeedbackReasonDTO.OtherReason ||
                                        reason === FeedbackReasonDTO.TooLittleInformation
                                    ) {
                                        scrollToPageBottom()
                                    }
                                }}
                            >
                                <option />
                                {Object.keys(feedbackReasonOptionsMapping).map((feedbackReasonKey) => (
                                    <option value={feedbackReasonKey} key={feedbackReasonKey}>
                                        {formatMessage(
                                            feedbackReasonOptionsMapping[feedbackReasonKey as FeedbackReasonDTO],
                                        )}
                                    </option>
                                ))}
                            </select>
                        </SelectWrapper>
                    </BadReasonFeedbackSelectWrapper>
                </FeedbackReasonBox>
            )}

            {/* Feedback Freetext */}
            {(selectedReason === FeedbackReasonDTO.OtherReason ||
                selectedReason === FeedbackReasonDTO.TooLittleInformation) && (
                <FreeTextContainerDiv>
                    <HeadingSmall>{formatMessage(feedbackFormMessages.titleFreeText)}</HeadingSmall>
                    <FreeTextPTextareaWrapper inert={undefined}>
                        <textarea value={freeText ?? ''} onChange={(e) => setFreeText(e.currentTarget.value)} />
                    </FreeTextPTextareaWrapper>
                </FreeTextContainerDiv>
            )}

            {transmissionError && (
                <ErrorPInlineNotification
                    inert={undefined}
                    heading={formatMessage(feedbackFormNotifications.transmissionErrorTitle)}
                    description={formatMessage(feedbackFormNotifications.transmissionErrorMessage)}
                    state={'error'}
                    onDismiss={() => setTransmissionError(false)}
                />
            )}

            <SubmitButtonWrapper>
                <PButton inert={undefined} onClick={sendAnswers} disabled={!isFormValid} loading={isPending}>
                    {formatMessage(feedbackFormMessages.submitButton)}
                </PButton>
            </SubmitButtonWrapper>
        </>
    )
}
