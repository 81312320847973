import React from 'react'
import { useIntl } from 'react-intl'

import { PriceTile, PriceTileContent } from '~/app/components'
import {
    HeadingLarge,
    TextWithoutWrap,
    TextXSmall,
} from '~/app/pages/request-detail/components/PriceEstimation/MarketPrice/MarketPrice.styled'
import { msrpPriceDataMessages } from '~/app/pages/request-detail/components/PriceEstimation/MSRPPriceData/messages'

type MSRPPriceDataProps = {
    formattedMsrpPrice: string
    formattedMsrpOptionSum: string
}
export const MSRPPriceData = ({ formattedMsrpPrice, formattedMsrpOptionSum }: MSRPPriceDataProps) => {
    const { formatMessage } = useIntl()

    return (
        <PriceTile
            heading={formatMessage(msrpPriceDataMessages.tileHeading)}
            content={
                <PriceTileContent>
                    <div>
                        <HeadingLarge>{formattedMsrpPrice}</HeadingLarge>
                        <TextXSmall>{formatMessage(msrpPriceDataMessages.labelVehicleMSRPPrice)}</TextXSmall>
                    </div>
                    <div>
                        <TextWithoutWrap>{formattedMsrpOptionSum}</TextWithoutWrap>
                        <TextXSmall>{formatMessage(msrpPriceDataMessages.labelVehicleMSRPOptionSum)}</TextXSmall>
                    </div>
                </PriceTileContent>
            }
        />
    )
}
