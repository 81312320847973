import {
    getMediaQueryMax,
    headingMediumStyle,
    spacing,
    spacingFluidMedium,
    spacingFluidSmall,
    spacingStaticSmall,
    textSmallStyle,
    themeLightContrastMedium,
} from '@porsche-design-system/components-react/styles'
import styled from 'styled-components'

export const CategoryHeadline = styled.p`
    ${headingMediumStyle};
    margin: 0 0 ${spacingFluidSmall} 0;
`

export const EquipmentItemStyle = styled.div`
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    margin-bottom: ${spacingFluidSmall};
`

const TextSmall = styled.p`
    ${textSmallStyle}
`

export const OptionCode = styled(TextSmall)`
    color: ${themeLightContrastMedium};
    margin: 0;
    margin-inline-end: ${spacingStaticSmall};
`

export const OptionName = styled(TextSmall)`
    margin: 0;
    // = Don't break words
    hyphens: none;
`

export const GridColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

export const ThreeColumnsOneRow = styled.div`
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: ${spacing.fluid.large};
    width: 100%;

    ${getMediaQueryMax('s')} {
        grid-template-columns: 1fr;
        gap: 0;
    }
`

export const SpacingFluidMediumBottom = styled.div`
    margin-bottom: ${spacingFluidMedium};
`
