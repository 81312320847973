import { defineMessages } from 'react-intl'

export const marketPriceMessages = defineMessages({
    tileHeading: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.tileHeadingMarketPrice',
        defaultMessage: 'Market price',
    },
    labelMarketPriceEstimation: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.labelMarketPriceEstimation',
        defaultMessage: 'Market Price Estimation',
    },
    labelSource: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.labelSource',
        defaultMessage: 'Source',
    },
    labelPriceEstimationNotPossible: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.labelNoPriceEstimation',
        defaultMessage: 'Price estimation not possible due to a lack of market data.',
    },
    labelPriceEstimationIsLoading: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.labelPriceEstimationIsLoading',
        defaultMessage: 'Please be patient while a price is being estimated.',
    },
    textFeedbackAppreciated: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.textFeedBackAppreciated',
        defaultMessage: 'Does this automatically determined market price replace your market price research?',
    },
    feedbackYes: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.feedbackYes',
        defaultMessage: 'Yes',
    },
    feedbackNo: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.feedbackNo',
        defaultMessage: 'No',
    },
    feedbackRequestText: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.feedbackRequestText',
        defaultMessage: "Why doesn't it replace your market price research?",
    },
    sendFeedbackButton: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.sendFeedbackButton',
        defaultMessage: 'Send Feedback',
    },
    successMessageHeadline: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.successMessageHeadline',
        defaultMessage: 'Feedback submitted successfully',
    },
    successMessageText: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.successMessageText',
        defaultMessage: 'Thank you for your feedback!',
    },
    errorMessageHeadline: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.errorMessageHeadline',
        defaultMessage: 'Transmission Error',
    },
    errorMessageText: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.errorMessageText',
        defaultMessage: 'Something went wrong sending feedback. Please try again in a minute.',
    },
    infoPopupAboutAnalyticCompany: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.infoPopupAboutAnalyticCompany',
        defaultMessage:
            'Analytic Company is the most technology-driven and innovative company on the topic of proving car market prices. The underlying data used by Analytic Company is driven by the extensive automotive market.',
    },
    labelPriceEstimationWarningsPopup: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.warningPopupAboutPriceEstimation',
        defaultMessage:
            'The estimated price is based on uncertainties. The following warnings are issued by Analytic Company:',
    },
    labelMarketPrice: {
        id: 'tradeIn.requests.detail.infobox.priceEstimation.marketPrice.label',
        defaultMessage: 'Market price',
    },
})
