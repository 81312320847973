import { defineMessages } from 'react-intl'

export const marketplaceLinksMessages = defineMessages({
    finderButton: {
        id: 'tradeIn.requests.detail.infobox.marketplaceLinks.finder.button',
        defaultMessage: 'Show similar vehicles in Porsche Finder',
    },
    mobileDeButton: {
        id: 'tradeIn.requests.detail.infobox.marketplaceLinks.mobileDe.button',
        defaultMessage: 'Show similar vehicles at mobile.de',
    },
})
