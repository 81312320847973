import { useQuery } from '@tanstack/react-query'
import { tradeInControllerApi } from 'tim-api-client'

export interface DealPriceDataQueryQueryProps {
    tradeInId: string
}

export const useDealPriceDataQuery = ({ tradeInId }: DealPriceDataQueryQueryProps) =>
    useQuery({
        queryKey: ['trade-in-request', tradeInId, 'purchase-price'],
        queryFn: () => tradeInControllerApi.fetchTradeInVehicleDealPriceData({ id: tradeInId }),
    })
