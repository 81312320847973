/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ColorsDTO
 */
export interface ColorsDTO {
    /**
     * Exterior color
     * @type {string}
     * @memberof ColorsDTO
     */
    exterior: string
    /**
     * Interior color
     * @type {string}
     * @memberof ColorsDTO
     */
    interior: string
}

/**
 * Check if a given object implements the ColorsDTO interface.
 */
export function instanceOfColorsDTO(value: object): value is ColorsDTO {
    if (!('exterior' in value) || value['exterior'] === undefined) return false
    if (!('interior' in value) || value['interior'] === undefined) return false
    return true
}

export function ColorsDTOFromJSON(json: any): ColorsDTO {
    return ColorsDTOFromJSONTyped(json, false)
}

export function ColorsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColorsDTO {
    if (json == null) {
        return json
    }
    return {
        exterior: json['exterior'],
        interior: json['interior'],
    }
}

export function ColorsDTOToJSON(json: any): ColorsDTO {
    return ColorsDTOToJSONTyped(json, false)
}

export function ColorsDTOToJSONTyped(value?: ColorsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value
    }

    return {
        exterior: value['exterior'],
        interior: value['interior'],
    }
}
