import { defineMessages } from 'react-intl'
import { TradeInSourceDTO } from 'tim-api-client'

export const messagesForTradeInSource = defineMessages({
    sourceIcc: {
        id: 'tradeIn.requests.source.icc',
        defaultMessage: 'ICC',
    },
    sourceFinder: {
        id: 'tradeIn.requests.source.finder',
        defaultMessage: 'Finder',
    },
})

export const formatTradeInSource = (tradeInSource: TradeInSourceDTO) => {
    switch (tradeInSource) {
        case 'ICC_CONTACT':
        case 'ICC_DEALBUILDER':
            return messagesForTradeInSource.sourceIcc
        case 'FINDER_CONTACT':
        case 'FINDER_DEALBUILDER':
            return messagesForTradeInSource.sourceFinder
    }
}
