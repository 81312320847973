/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { EquipmentCategoryDTO } from './EquipmentCategoryDTO'
import {
    EquipmentCategoryDTOFromJSON,
    EquipmentCategoryDTOFromJSONTyped,
    EquipmentCategoryDTOToJSON,
    EquipmentCategoryDTOToJSONTyped,
} from './EquipmentCategoryDTO'

/**
 *
 * @export
 * @interface EquipmentDTO
 */
export interface EquipmentDTO {
    /**
     * List of all categories
     * @type {Array<EquipmentCategoryDTO>}
     * @memberof EquipmentDTO
     */
    categories: Array<EquipmentCategoryDTO>
}

/**
 * Check if a given object implements the EquipmentDTO interface.
 */
export function instanceOfEquipmentDTO(value: object): value is EquipmentDTO {
    if (!('categories' in value) || value['categories'] === undefined) return false
    return true
}

export function EquipmentDTOFromJSON(json: any): EquipmentDTO {
    return EquipmentDTOFromJSONTyped(json, false)
}

export function EquipmentDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentDTO {
    if (json == null) {
        return json
    }
    return {
        categories: (json['categories'] as Array<any>).map(EquipmentCategoryDTOFromJSON),
    }
}

export function EquipmentDTOToJSON(json: any): EquipmentDTO {
    return EquipmentDTOToJSONTyped(json, false)
}

export function EquipmentDTOToJSONTyped(value?: EquipmentDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value
    }

    return {
        categories: (value['categories'] as Array<any>).map(EquipmentCategoryDTOToJSON),
    }
}
