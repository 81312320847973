/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface IdDTO
 */
export interface IdDTO {
    /**
     * ID of a new entity
     * @type {string}
     * @memberof IdDTO
     */
    id: string
}

/**
 * Check if a given object implements the IdDTO interface.
 */
export function instanceOfIdDTO(value: object): value is IdDTO {
    if (!('id' in value) || value['id'] === undefined) return false
    return true
}

export function IdDTOFromJSON(json: any): IdDTO {
    return IdDTOFromJSONTyped(json, false)
}

export function IdDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdDTO {
    if (json == null) {
        return json
    }
    return {
        id: json['id'],
    }
}

export function IdDTOToJSON(json: any): IdDTO {
    return IdDTOToJSONTyped(json, false)
}

export function IdDTOToJSONTyped(value?: IdDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value
    }

    return {
        id: value['id'],
    }
}
