import { useDealerContext } from '@slfinrtl/context'
import { DealerContextProps } from '@slfinrtl/context-types'
import { useIntl } from 'react-intl'
import { ConditionAndHistoryResponseDTO, TradeInSourceDTO } from 'tim-api-client'

import {
    CardWithMarginTop,
    FullWidth,
    VehicleDetailGrid,
    VehicleDetailsHeadline,
    VehicleDetailsItem,
} from '~/app/components'
import { useTradeInManagerContext } from '~/app/context'
import { FirstRegistrationDateDetailItem } from '~/app/pages/request-detail/components/ConditionAndHistory/FirstRegistrationDate/FirstRegistrationDateDetailItem'
import { conditionAndHistoryMessages } from '~/app/pages/request-detail/components/ConditionAndHistory/messages'
import { ConditionAndHistoryOwnershipDetailItem } from '~/app/pages/request-detail/components/ConditionAndHistory/Ownership/ConditionAndHistoryOwnershipDetailItem'
import PCSSLink from '~/app/pages/request-detail/components/ConditionAndHistory/PCSSLink/PCSSLink'
import { tradeInRequestDetailMessages } from '~/app/pages/request-detail/messages/tradeInRequestDetailPageMessages'

type PCSSProps = {
    vin?: string
    isVDSVehicle: boolean
    environment: DealerContextProps['environment']
}
const PCSSLinkIfVDSVehicle = ({ vin, isVDSVehicle, environment }: PCSSProps) => {
    if (!vin || !isVDSVehicle) return null

    return <PCSSLink vin={vin} env={environment} />
}

type ConditionAndHistoryProps = {
    data: ConditionAndHistoryResponseDTO
    tradeInSource: TradeInSourceDTO
} & Pick<PCSSProps, 'vin' | 'isVDSVehicle'>

export const ConditionAndHistory = ({ data, vin, tradeInSource, isVDSVehicle }: ConditionAndHistoryProps) => {
    const { formatMessage } = useIntl()
    const dealerContext = useDealerContext()
    const tradeInManagerContext = useTradeInManagerContext()

    const yesAsMessage = formatMessage(conditionAndHistoryMessages.yes)
    const noAsMessage = formatMessage(conditionAndHistoryMessages.no)

    return (
        <CardWithMarginTop>
            <FullWidth>
                <VehicleDetailsHeadline>
                    {formatMessage(tradeInRequestDetailMessages.conditionAndHistoryInfoBoxTitle)}
                </VehicleDetailsHeadline>
                <VehicleDetailGrid>
                    <FirstRegistrationDateDetailItem
                        tradeInSource={tradeInSource}
                        firstRegistrationDate={data?.firstRegistrationDate}
                    />
                    <VehicleDetailsItem
                        label={formatMessage(conditionAndHistoryMessages.mileage)}
                        value={
                            data &&
                            `${Intl.NumberFormat(dealerContext.locale).format(data.mileage.value)} ${formatMessage(
                                conditionAndHistoryMessages[data.mileage.unit],
                            )}`
                        }
                    />
                    <VehicleDetailsItem
                        label={formatMessage(conditionAndHistoryMessages.numberOfPreviousOwners)}
                        value={data?.previousOwners.toString()}
                    />
                    <VehicleDetailsItem
                        label={formatMessage(conditionAndHistoryMessages.accidentFree)}
                        value={data && data.condition.accidentFree ? yesAsMessage : noAsMessage}
                    />
                    <VehicleDetailsItem
                        label={formatMessage(conditionAndHistoryMessages.fullServiceHistory)}
                        value={data && data.condition.fullServiceHistory ? yesAsMessage : noAsMessage}
                    />
                    {tradeInManagerContext.showVATDeductible && (
                        <VehicleDetailsItem
                            label={formatMessage(conditionAndHistoryMessages.vatDeductible)}
                            value={data && data.vatDeductible ? yesAsMessage : noAsMessage}
                        />
                    )}
                    {data.ownership && <ConditionAndHistoryOwnershipDetailItem ownership={data.ownership} />}
                </VehicleDetailGrid>
                <PCSSLinkIfVDSVehicle isVDSVehicle={isVDSVehicle} vin={vin} environment={dealerContext.environment} />
            </FullWidth>
        </CardWithMarginTop>
    )
}
