/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * DTO to describe how much the Dealer likes the Trade-In Detail Page
 * @export
 */
export const LikertFeedbackDTO = {
    VeryDissatisfied: 'VeryDissatisfied',
    SomewhatDissatisfied: 'SomewhatDissatisfied',
    NeitherNor: 'NeitherNor',
    SomewhatSatisfied: 'SomewhatSatisfied',
    VerySatisfied: 'VerySatisfied',
} as const
export type LikertFeedbackDTO = (typeof LikertFeedbackDTO)[keyof typeof LikertFeedbackDTO]

export function instanceOfLikertFeedbackDTO(value: any): boolean {
    for (const key in LikertFeedbackDTO) {
        if (Object.prototype.hasOwnProperty.call(LikertFeedbackDTO, key)) {
            if (LikertFeedbackDTO[key as keyof typeof LikertFeedbackDTO] === value) {
                return true
            }
        }
    }
    return false
}

export function LikertFeedbackDTOFromJSON(json: any): LikertFeedbackDTO {
    return LikertFeedbackDTOFromJSONTyped(json, false)
}

export function LikertFeedbackDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LikertFeedbackDTO {
    return json as LikertFeedbackDTO
}

export function LikertFeedbackDTOToJSON(value?: LikertFeedbackDTO | null): any {
    return value as any
}

export function LikertFeedbackDTOToJSONTyped(value: any, ignoreDiscriminator: boolean): LikertFeedbackDTO {
    return value as LikertFeedbackDTO
}
