/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const HiddenReason = {
    NOT_DEALER_USER: 'NOT_DEALER_USER',
    DATA_NOT_HIDDEN: 'DATA_NOT_HIDDEN',
} as const
export type HiddenReason = (typeof HiddenReason)[keyof typeof HiddenReason]

export function instanceOfHiddenReason(value: any): boolean {
    for (const key in HiddenReason) {
        if (Object.prototype.hasOwnProperty.call(HiddenReason, key)) {
            if (HiddenReason[key as keyof typeof HiddenReason] === value) {
                return true
            }
        }
    }
    return false
}

export function HiddenReasonFromJSON(json: any): HiddenReason {
    return HiddenReasonFromJSONTyped(json, false)
}

export function HiddenReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): HiddenReason {
    return json as HiddenReason
}

export function HiddenReasonToJSON(value?: HiddenReason | null): any {
    return value as any
}

export function HiddenReasonToJSONTyped(value: any, ignoreDiscriminator: boolean): HiddenReason {
    return value as HiddenReason
}
