/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { LikertFeedbackDTO } from './LikertFeedbackDTO'
import {
    LikertFeedbackDTOFromJSON,
    LikertFeedbackDTOFromJSONTyped,
    LikertFeedbackDTOToJSON,
    LikertFeedbackDTOToJSONTyped,
} from './LikertFeedbackDTO'
import type { FeedbackReasonDTO } from './FeedbackReasonDTO'
import {
    FeedbackReasonDTOFromJSON,
    FeedbackReasonDTOFromJSONTyped,
    FeedbackReasonDTOToJSON,
    FeedbackReasonDTOToJSONTyped,
} from './FeedbackReasonDTO'

/**
 *
 * @export
 * @interface TradeInFeedbackDTO
 */
export interface TradeInFeedbackDTO {
    /**
     *
     * @type {LikertFeedbackDTO}
     * @memberof TradeInFeedbackDTO
     */
    likertValue: LikertFeedbackDTO
    /**
     *
     * @type {FeedbackReasonDTO}
     * @memberof TradeInFeedbackDTO
     */
    feedbackReason?: FeedbackReasonDTO
    /**
     * Locale used by the dealer
     * @type {string}
     * @memberof TradeInFeedbackDTO
     */
    locale: string
    /**
     * Free Text for the customer feedback
     * @type {string}
     * @memberof TradeInFeedbackDTO
     */
    freeText?: string
}

/**
 * Check if a given object implements the TradeInFeedbackDTO interface.
 */
export function instanceOfTradeInFeedbackDTO(value: object): value is TradeInFeedbackDTO {
    if (!('likertValue' in value) || value['likertValue'] === undefined) return false
    if (!('locale' in value) || value['locale'] === undefined) return false
    return true
}

export function TradeInFeedbackDTOFromJSON(json: any): TradeInFeedbackDTO {
    return TradeInFeedbackDTOFromJSONTyped(json, false)
}

export function TradeInFeedbackDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeInFeedbackDTO {
    if (json == null) {
        return json
    }
    return {
        likertValue: LikertFeedbackDTOFromJSON(json['likertValue']),
        feedbackReason: json['feedbackReason'] == null ? undefined : FeedbackReasonDTOFromJSON(json['feedbackReason']),
        locale: json['locale'],
        freeText: json['freeText'] == null ? undefined : json['freeText'],
    }
}

export function TradeInFeedbackDTOToJSON(json: any): TradeInFeedbackDTO {
    return TradeInFeedbackDTOToJSONTyped(json, false)
}

export function TradeInFeedbackDTOToJSONTyped(
    value?: TradeInFeedbackDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        likertValue: LikertFeedbackDTOToJSON(value['likertValue']),
        feedbackReason: FeedbackReasonDTOToJSON(value['feedbackReason']),
        locale: value['locale'],
        freeText: value['freeText'],
    }
}
