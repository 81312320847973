import {
    gridStyle,
    gridWideColumnEnd,
    gridWideColumnStart,
    headingXXLargeStyle,
    spacingFluidMedium,
} from '@porsche-design-system/components-react/styles'
import styled from 'styled-components'

export const TopMarginMedium = styled.div`
    margin-top: ${spacingFluidMedium};
`

export const HeadingXXLarge = styled.h2({
    margin: 0,
    ...headingXXLargeStyle,
})

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const HeroGrid = styled.div({
    // note: using css syntax doesn't work here, because styled-components and pdsv3 types are incompatible for pds-grid
    ...gridStyle,
})

export const HeroGridItem = styled.div`
    grid-column: ${gridWideColumnStart} / ${gridWideColumnEnd};
    grid-row: 1;
`
