import { useCallback, useEffect, useRef } from 'react'

// If you want to fine-tune the offsets of the infiniteScroll you can enable
// this visualization helper by invoking it in the useCallback below.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const visualizationForDebugging = (triggerPoint: number, viewportBottom: number) => {
    const divLine = (_div: HTMLDivElement, id: string, top: number, bg: string) => {
        _div1.id = id
        _div.style.width = '100vw'
        _div.style.height = `8px`
        _div.style.zIndex = '9999'
        _div.style.position = 'absolute'
        _div.style.top = `${top}px`
        _div.style.left = `0`
        _div.style.background = bg
    }

    const w = window as unknown as {
        debug1: undefined | HTMLDivElement
        debug2: undefined | HTMLDivElement
    }
    const _div1 = w.debug1 ?? document.createElement('div')
    w.debug1 = _div1
    divLine(_div1, 'debugTriggerPoint', triggerPoint, 'red')

    const _div2 = w.debug2 ?? document.createElement('div')
    w.debug2 = _div2
    divLine(_div2, 'debugViewportBottom', viewportBottom - 8, 'blue')

    document.body.append(_div1)
    document.body.append(_div2)
}

/**
 * <pre>
 * ViewPort  Document
 *   ╭─╮     ┌──┐
 *   │ │     │  │
 *   ╰─╯     │  │
 *           ├──┤<- TriggerPoint
 *           └──┘<- ClientHeight
 *
 *          ViewPort╮ ╭Document
 *                 ╭─╮┐
 *                 │ ││
 * ViewPortBottom->╰─╯│
 * TriggerPoint -->├──┤ ╗
 *                 │  │ ╠═ offset
 * ClientHeight -->└──┘ ╝
 *
 *          ViewPort╮ ╭Document
 *                 ┌──┐
 *                 ╭─╮│
 *                 ├─┼┤
 *       VPB>TP -->╰─╯│ -> action is triggered
 *                 └──┘
 * </pre>
 * When the user scrolls to "endOfThePage - offset" the $action is triggered. <br/>
 * Note: adapted from slsoscf-opportunity-frontend
 * @param action
 */
export function useInfiniteScroll(action: () => void) {
    const offset = 300

    const actionRef = useRef(action)
    actionRef.current = action

    const onScroll = useCallback(() => {
        const triggerPoint = window.document.body.clientHeight - offset
        const viewportBottom = window.innerHeight + window.scrollY
        if (triggerPoint < viewportBottom) {
            actionRef.current()
        }
    }, [offset, actionRef])

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [onScroll])
}
