/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { CurrencyDTO } from './CurrencyDTO'
import { CurrencyDTOFromJSON, CurrencyDTOFromJSONTyped, CurrencyDTOToJSON, CurrencyDTOToJSONTyped } from './CurrencyDTO'

/**
 * Estimated value of trade-in vehicle, as shown to the customer.
 * @export
 * @interface RangedPriceEstimationDTO
 */
export interface RangedPriceEstimationDTO {
    /**
     *
     * @type {CurrencyDTO}
     * @memberof RangedPriceEstimationDTO
     */
    currency: CurrencyDTO
    /**
     * Price estimate in given currency (without cents)
     * @type {number}
     * @memberof RangedPriceEstimationDTO
     */
    estimate: number
    /**
     * Lower range for price estimate in given currency (without cents)
     * @type {number}
     * @memberof RangedPriceEstimationDTO
     */
    low: number
    /**
     * Higher range for price estimate in given currency (without cents)
     * @type {number}
     * @memberof RangedPriceEstimationDTO
     */
    high: number
}

/**
 * Check if a given object implements the RangedPriceEstimationDTO interface.
 */
export function instanceOfRangedPriceEstimationDTO(value: object): value is RangedPriceEstimationDTO {
    if (!('currency' in value) || value['currency'] === undefined) return false
    if (!('estimate' in value) || value['estimate'] === undefined) return false
    if (!('low' in value) || value['low'] === undefined) return false
    if (!('high' in value) || value['high'] === undefined) return false
    return true
}

export function RangedPriceEstimationDTOFromJSON(json: any): RangedPriceEstimationDTO {
    return RangedPriceEstimationDTOFromJSONTyped(json, false)
}

export function RangedPriceEstimationDTOFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): RangedPriceEstimationDTO {
    if (json == null) {
        return json
    }
    return {
        currency: CurrencyDTOFromJSON(json['currency']),
        estimate: json['estimate'],
        low: json['low'],
        high: json['high'],
    }
}

export function RangedPriceEstimationDTOToJSON(json: any): RangedPriceEstimationDTO {
    return RangedPriceEstimationDTOToJSONTyped(json, false)
}

export function RangedPriceEstimationDTOToJSONTyped(
    value?: RangedPriceEstimationDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        currency: CurrencyDTOToJSON(value['currency']),
        estimate: value['estimate'],
        low: value['low'],
        high: value['high'],
    }
}
