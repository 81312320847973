import { useMutation } from '@tanstack/react-query'
import { CreateTradeInFeedbackPriceEstimationRequest, tradeInControllerApi } from 'tim-api-client'

export interface MarketPriceFeedbackMutationProps {
    tradeInId: string
    onSuccess?: () => void
    onError?: () => void
}

export const useMarketPriceFeedbackMutation = ({ tradeInId, onError, onSuccess }: MarketPriceFeedbackMutationProps) =>
    useMutation({
        mutationFn: (data: CreateTradeInFeedbackPriceEstimationRequest) =>
            tradeInControllerApi.createTradeInFeedbackPriceEstimation({
                id: tradeInId,
                tradeInFeedbackPriceEstimationDTO: data.tradeInFeedbackPriceEstimationDTO,
            }),
        onSuccess,
        onError,
    })
