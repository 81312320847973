/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { HiddenReason } from './HiddenReason'
import {
    HiddenReasonFromJSON,
    HiddenReasonFromJSONTyped,
    HiddenReasonToJSON,
    HiddenReasonToJSONTyped,
} from './HiddenReason'
import type { TradeInOverviewEntryCustomerDTOValue } from './TradeInOverviewEntryCustomerDTOValue'
import {
    TradeInOverviewEntryCustomerDTOValueFromJSON,
    TradeInOverviewEntryCustomerDTOValueFromJSONTyped,
    TradeInOverviewEntryCustomerDTOValueToJSON,
    TradeInOverviewEntryCustomerDTOValueToJSONTyped,
} from './TradeInOverviewEntryCustomerDTOValue'

/**
 *
 * @export
 * @interface TradeInOverviewEntryCustomerDTO
 */
export interface TradeInOverviewEntryCustomerDTO {
    /**
     *
     * @type {HiddenReason}
     * @memberof TradeInOverviewEntryCustomerDTO
     */
    hidden: HiddenReason
    /**
     *
     * @type {TradeInOverviewEntryCustomerDTOValue}
     * @memberof TradeInOverviewEntryCustomerDTO
     */
    value?: TradeInOverviewEntryCustomerDTOValue
}

/**
 * Check if a given object implements the TradeInOverviewEntryCustomerDTO interface.
 */
export function instanceOfTradeInOverviewEntryCustomerDTO(value: object): value is TradeInOverviewEntryCustomerDTO {
    if (!('hidden' in value) || value['hidden'] === undefined) return false
    return true
}

export function TradeInOverviewEntryCustomerDTOFromJSON(json: any): TradeInOverviewEntryCustomerDTO {
    return TradeInOverviewEntryCustomerDTOFromJSONTyped(json, false)
}

export function TradeInOverviewEntryCustomerDTOFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): TradeInOverviewEntryCustomerDTO {
    if (json == null) {
        return json
    }
    return {
        hidden: HiddenReasonFromJSON(json['hidden']),
        value: json['value'] == null ? undefined : TradeInOverviewEntryCustomerDTOValueFromJSON(json['value']),
    }
}

export function TradeInOverviewEntryCustomerDTOToJSON(json: any): TradeInOverviewEntryCustomerDTO {
    return TradeInOverviewEntryCustomerDTOToJSONTyped(json, false)
}

export function TradeInOverviewEntryCustomerDTOToJSONTyped(
    value?: TradeInOverviewEntryCustomerDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        hidden: HiddenReasonToJSON(value['hidden']),
        value: TradeInOverviewEntryCustomerDTOValueToJSON(value['value']),
    }
}
