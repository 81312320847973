import { PInlineNotification } from '@porsche-design-system/components-react'
import { PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'
import { FrontendConfiguration } from 'tim-api-client'

import { SkeletonContent, SkeletonHeadline } from '~/app/components'
import { messagesForTradeInManagerContextProvider } from '~/app/context/messages/messagesForTradeInManagercontextProvider'
import { TradeInManagerContext } from '~/app/context/TradeInManagerContext'

import { useTradeInManagerBackend } from '../backend/useTradeInManagerBackendContext'

interface TradeInManagerContextProviderProps extends PropsWithChildren {
    /** Marketplace key to fetch correct marketplace configuration, e.g. "de" or "us" */
    marketplaceKey: string
    /** Allow setting useQuery state for testing **/
    overrides?: {
        data?: FrontendConfiguration
        isLoading?: boolean
        error?: Error
    }
}

export const TradeInManagerContextProvider = ({
    marketplaceKey,
    children,
    overrides,
}: TradeInManagerContextProviderProps) => {
    const { useFrontendConfigurationQuery } = useTradeInManagerBackend()
    const useQueryData = useFrontendConfigurationQuery({ marketplaceKey, overrides })

    const { data, isLoading, error } = overrides ?? useQueryData

    const { formatMessage } = useIntl()

    if (isLoading) {
        return (
            <>
                <SkeletonHeadline />
                <SkeletonContent />
            </>
        )
    }

    if (!data) {
        console.error(error)
        return (
            <PInlineNotification
                inert={undefined}
                dismissButton={false}
                state={'error'}
                heading={formatMessage(messagesForTradeInManagerContextProvider.errorHeader)}
                description={formatMessage(messagesForTradeInManagerContextProvider.errorDescription)}
            />
        )
    }
    return <TradeInManagerContext.Provider value={data}>{children}</TradeInManagerContext.Provider>
}
