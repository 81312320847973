/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EngineTypeDTO = {
    GASOLINE: 'GASOLINE',
    DIESEL: 'DIESEL',
    GAS: 'GAS',
    ELECTRIC: 'ELECTRIC',
    PLUG_IN_HYBRID: 'PLUG_IN_HYBRID',
} as const
export type EngineTypeDTO = (typeof EngineTypeDTO)[keyof typeof EngineTypeDTO]

export function instanceOfEngineTypeDTO(value: any): boolean {
    for (const key in EngineTypeDTO) {
        if (Object.prototype.hasOwnProperty.call(EngineTypeDTO, key)) {
            if (EngineTypeDTO[key as keyof typeof EngineTypeDTO] === value) {
                return true
            }
        }
    }
    return false
}

export function EngineTypeDTOFromJSON(json: any): EngineTypeDTO {
    return EngineTypeDTOFromJSONTyped(json, false)
}

export function EngineTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineTypeDTO {
    return json as EngineTypeDTO
}

export function EngineTypeDTOToJSON(value?: EngineTypeDTO | null): any {
    return value as any
}

export function EngineTypeDTOToJSONTyped(value: any, ignoreDiscriminator: boolean): EngineTypeDTO {
    return value as EngineTypeDTO
}
