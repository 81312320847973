import { useContext } from 'react'

import { TradeInManagerBackendContext } from './TradeInManagerBackendProvider'

export const useTradeInManagerBackend = () => {
    const context = useContext(TradeInManagerBackendContext)
    if (context == null) {
        throw new Error('TradeInManagerBackendContextProvider is required for this component')
    }
    return context
}
