import { fontWeightSemiBold, spacingFluidXSmall, textSmallStyle } from '@porsche-design-system/components-react/styles'
import styled from 'styled-components'

const InlineTextSmall = styled.p`
    ${textSmallStyle};
    display: inline;
    margin: 0;
`

export const TotalCount = styled(InlineTextSmall)`
    font-weight: ${fontWeightSemiBold};
`

export const Headline = styled(InlineTextSmall)`
    margin-left: ${spacingFluidXSmall};
`
