import { useEffect, useState } from 'react'
import { AnyObject, ObjectSchema } from 'yup'

export const useFormValidation = <T extends AnyObject>(schema: ObjectSchema<T>, value: T) => {
    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        try {
            schema.validateSync(value)
            setIsValid(true)
        } catch {
            setIsValid(false)
        }
    }, [schema, value])

    return isValid
}
