/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { FrontendConfiguration } from '../models/index'
import { FrontendConfigurationFromJSON, FrontendConfigurationToJSON } from '../models/index'

export interface FetchFrontendConfigurationRequest {
    marketplace: string
}

/**
 *
 */
export class FrontendApi extends runtime.BaseAPI {
    /**
     * Fetch configuration for the given market and resolved environment to configure the frontend that is calling this endpoint.
     */
    async fetchFrontendConfigurationRaw(
        requestParameters: FetchFrontendConfigurationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<FrontendConfiguration>> {
        if (requestParameters['marketplace'] == null) {
            throw new runtime.RequiredError(
                'marketplace',
                'Required parameter "marketplace" was null or undefined when calling fetchFrontendConfiguration().',
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = await token('ppnAuth', [])

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/frontend/configuration/{marketplace}`.replace(
                    `{${'marketplace'}}`,
                    encodeURIComponent(String(requestParameters['marketplace'])),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontendConfigurationFromJSON(jsonValue))
    }

    /**
     * Fetch configuration for the given market and resolved environment to configure the frontend that is calling this endpoint.
     */
    async fetchFrontendConfiguration(
        requestParameters: FetchFrontendConfigurationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<FrontendConfiguration> {
        const response = await this.fetchFrontendConfigurationRaw(requestParameters, initOverrides)
        return await response.value()
    }
}
