import {
    getMediaQueryMin,
    gridBasicColumnEnd,
    gridBasicColumnStart,
    gridNarrowColumnEnd,
    gridNarrowColumnStart,
    gridWideColumnEnd,
    gridWideColumnStart,
} from '@porsche-design-system/components-react/styles'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { TopLevelGrid } from '~/app/components/layout/TopLevelGrid'

/**
 * Top Level Porsche-Grid that implements the Hero (= wide) breakpoints
 * Warning: only use on top level!
 * @see TopLevelGrid
 */
export const WideTopLevelContent = ({ children }: PropsWithChildren) => (
    <TopLevelGrid>
        <WideContentItem>{children}</WideContentItem>
    </TopLevelGrid>
)

export const WideContentItem = styled.div`
    grid-column: ${gridWideColumnStart} / ${gridWideColumnEnd};
    grid-row: 1;
`

/**
 * Top Level Porsche-Grid that implements narrow breakpoints
 * Warning: only use on top level!
 * @see TopLevelGrid
 */
export const NarrowTopLevelContent = ({ children }: PropsWithChildren) => (
    <TopLevelGrid>
        <NarrowContentItem>{children}</NarrowContentItem>
    </TopLevelGrid>
)

export const NarrowContentItem = styled.div`
    grid-column: ${gridBasicColumnStart} / ${gridBasicColumnEnd};
    ${getMediaQueryMin('m')} {
        grid-column: ${gridNarrowColumnStart} / ${gridNarrowColumnEnd};
    }
    grid-row: 1;
`
