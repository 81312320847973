/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const TransmissionTypeDTO = {
    AUTOMATIC: 'AUTOMATIC',
    MANUAL: 'MANUAL',
} as const
export type TransmissionTypeDTO = (typeof TransmissionTypeDTO)[keyof typeof TransmissionTypeDTO]

export function instanceOfTransmissionTypeDTO(value: any): boolean {
    for (const key in TransmissionTypeDTO) {
        if (Object.prototype.hasOwnProperty.call(TransmissionTypeDTO, key)) {
            if (TransmissionTypeDTO[key as keyof typeof TransmissionTypeDTO] === value) {
                return true
            }
        }
    }
    return false
}

export function TransmissionTypeDTOFromJSON(json: any): TransmissionTypeDTO {
    return TransmissionTypeDTOFromJSONTyped(json, false)
}

export function TransmissionTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransmissionTypeDTO {
    return json as TransmissionTypeDTO
}

export function TransmissionTypeDTOToJSON(value?: TransmissionTypeDTO | null): any {
    return value as any
}

export function TransmissionTypeDTOToJSONTyped(value: any, ignoreDiscriminator: boolean): TransmissionTypeDTO {
    return value as TransmissionTypeDTO
}
