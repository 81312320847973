import { useCallback } from 'react'
import { tradeInControllerApi } from 'tim-api-client'

import { UseFilterReturnValue } from '~/app/pages/request-table/hooks/useFilter'

export interface TablePageQueryProps {
    filter: UseFilterReturnValue['filter']
    text: string
    dealerEntryDn: string
    country: string
}

export const useTablePageQuery = ({ filter, text, dealerEntryDn, country }: TablePageQueryProps) => {
    return useCallback(
        async ({ pageParam = 0 }) =>
            tradeInControllerApi.fetchTradeIns({
                searchQuery: text,
                dealerEntryDn: dealerEntryDn,
                country: country,
                page: pageParam,
                size: 30,
                from: formatFilterDate(filter.fromDate, { hours: 0, minutes: 0, seconds: 0 }),
                to: formatFilterDate(filter.toDate, { hours: 23, minutes: 59, seconds: 59 }),
            }),
        [country, dealerEntryDn, filter.fromDate, filter.toDate, text],
    )
}

const formatFilterDate = (
    date: string,
    { hours, minutes, seconds }: { hours: number; minutes: number; seconds: number },
): number | undefined => {
    if (!date || date.length === 0) return undefined

    const parsedDate = new Date(date)
    parsedDate.setHours(hours)
    parsedDate.setMinutes(minutes)
    parsedDate.setSeconds(seconds)
    return +parsedDate
}
