import { MessageDescriptor } from 'react-intl'
import { FeedbackReasonDTO, LikertFeedbackDTO } from 'tim-api-client'
import { object, string } from 'yup'

import { feedbackReasonMessages } from '~/app/pages/request-detail/components/FeedbackForm/messages'

export const feedbackReasonOptionsMapping: Record<FeedbackReasonDTO, MessageDescriptor> = {
    TooLittleInformation: feedbackReasonMessages.TooLittleInformation,
    UselessCustomerVehicle: feedbackReasonMessages.UselessCustomerVehicle,
    MissingStatusFeature: feedbackReasonMessages.MissingStatusFeature,
    MissingEditFeature: feedbackReasonMessages.MissingEditFeature,
    MissingPicturesFeature: feedbackReasonMessages.MissingPicturesFeature,
    OtherReason: feedbackReasonMessages.OtherReason,
}

/**
 * Schema for the dealers Feedback-Form Validation
 * Validation-Rules:
 * - if the selected likert is 'VeryDissatisfied', 'SomewhatDissatisfied' or 'NeitherNor', an additional Dropdown shall be presented
 * - if the dropdown value is 'Other' or 'Too little information' a free text is required
 */
export const TradeInFeedbackFormSchema = object().shape({
    likertValue: string().oneOf(Object.keys(LikertFeedbackDTO)),
    feedbackReason: string().when('likertValue', ([value], schema) =>
        value === LikertFeedbackDTO.VerySatisfied || value === LikertFeedbackDTO.SomewhatSatisfied
            ? schema.optional()
            : schema.oneOf(Object.keys(feedbackReasonOptionsMapping)).required(),
    ),
    freeText: string().when('feedbackReason', ([value], schema) =>
        value === FeedbackReasonDTO.OtherReason || value === FeedbackReasonDTO.TooLittleInformation
            ? schema.required()
            : schema.optional(),
    ),
    locale: string().required(),
})
