/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface EquipmentOptionDTO
 */
export interface EquipmentOptionDTO {
    /**
     * Translated name of the category
     * @type {string}
     * @memberof EquipmentOptionDTO
     */
    name?: string
    /**
     * The internal alphanumeric option code from the factory
     * @type {string}
     * @memberof EquipmentOptionDTO
     */
    optionCode?: string
}

/**
 * Check if a given object implements the EquipmentOptionDTO interface.
 */
export function instanceOfEquipmentOptionDTO(value: object): value is EquipmentOptionDTO {
    return true
}

export function EquipmentOptionDTOFromJSON(json: any): EquipmentOptionDTO {
    return EquipmentOptionDTOFromJSONTyped(json, false)
}

export function EquipmentOptionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentOptionDTO {
    if (json == null) {
        return json
    }
    return {
        name: json['name'] == null ? undefined : json['name'],
        optionCode: json['optionCode'] == null ? undefined : json['optionCode'],
    }
}

export function EquipmentOptionDTOToJSON(json: any): EquipmentOptionDTO {
    return EquipmentOptionDTOToJSONTyped(json, false)
}

export function EquipmentOptionDTOToJSONTyped(
    value?: EquipmentOptionDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        name: value['name'],
        optionCode: value['optionCode'],
    }
}
