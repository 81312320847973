/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { CurrencyDTO } from './CurrencyDTO'
import { CurrencyDTOFromJSON, CurrencyDTOFromJSONTyped, CurrencyDTOToJSON, CurrencyDTOToJSONTyped } from './CurrencyDTO'

/**
 *
 * @export
 * @interface AmountWithCurrencyDTO
 */
export interface AmountWithCurrencyDTO {
    /**
     * Amount of money in given currency (without cents)
     * @type {number}
     * @memberof AmountWithCurrencyDTO
     */
    amount: number
    /**
     *
     * @type {CurrencyDTO}
     * @memberof AmountWithCurrencyDTO
     */
    currency: CurrencyDTO
}

/**
 * Check if a given object implements the AmountWithCurrencyDTO interface.
 */
export function instanceOfAmountWithCurrencyDTO(value: object): value is AmountWithCurrencyDTO {
    if (!('amount' in value) || value['amount'] === undefined) return false
    if (!('currency' in value) || value['currency'] === undefined) return false
    return true
}

export function AmountWithCurrencyDTOFromJSON(json: any): AmountWithCurrencyDTO {
    return AmountWithCurrencyDTOFromJSONTyped(json, false)
}

export function AmountWithCurrencyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AmountWithCurrencyDTO {
    if (json == null) {
        return json
    }
    return {
        amount: json['amount'],
        currency: CurrencyDTOFromJSON(json['currency']),
    }
}

export function AmountWithCurrencyDTOToJSON(json: any): AmountWithCurrencyDTO {
    return AmountWithCurrencyDTOToJSONTyped(json, false)
}

export function AmountWithCurrencyDTOToJSONTyped(
    value?: AmountWithCurrencyDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        amount: value['amount'],
        currency: CurrencyDTOToJSON(value['currency']),
    }
}
