import { spacingFluidMedium } from '@porsche-design-system/components-react/styles'
import { useDealerContext } from '@slfinrtl/context'
import { DealerContextProps } from '@slfinrtl/context-types'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { TradeInVehicleSearchParameters } from 'tim-api-client'

import { useTradeInManagerContext } from '~/app/context'
import MarketplaceLinkButton from '~/app/pages/request-detail/components/PriceEstimation/MarketplaceLinks/MarketplaceLinkButton'
import { marketplaceLinksMessages as msg } from '~/app/pages/request-detail/components/PriceEstimation/MarketplaceLinks/messages'
import { finderURL, mobileURL } from '~/app/pages/request-detail/util/util'

export interface SearchProps {
    mileageInKmOrMiles: number
    modelSeries?: string
    modelGeneration?: string
    derivative?: string
    // The finder internally resolves the proper unit to use here, likely based
    // on the marketplace/locale, so we just pass what we get here
    firstRegistrationDate?: Date
}

export interface MarketplaceLinksProps {
    searchFilterData: TradeInVehicleSearchParameters
}

export enum MOBILE_DE_MAGIC_NUMBER {
    P_356 = '2',
    P_911ER_REIHE = '40',
    P_911_URMODELL = '3',
    P_930 = '24',
    P_964 = '5',
    P_991 = '21',
    P_992 = '28',
    P_993 = '6',
    P_996 = '7',
    P_997 = '17',
    P_912 = '8',
    P_914 = '9',
    P_918 = '23',
    P_924 = '10',
    P_928 = '11',
    P_944 = '12',
    P_959 = '13',
    P_962 = '14',
    P_968 = '15',
    P_BOXSTER = '16',
    P_CARRERA_GT = '20',
    P_CAYENNE = '18',
    P_CAYMAN = '19',
    P_MACAN = '22',
    P_PANAMERA = '4',
    P_TAYCAN = '30',
}

// Some selectable models on mobile.de are model generations
const mobileDe911ModelGenerationMapping: Record<string, MOBILE_DE_MAGIC_NUMBER> = {
    '992': MOBILE_DE_MAGIC_NUMBER.P_992,
    '991-1': MOBILE_DE_MAGIC_NUMBER.P_991,
    '991-2': MOBILE_DE_MAGIC_NUMBER.P_991,
    '997-1': MOBILE_DE_MAGIC_NUMBER.P_997,
    '997-2': MOBILE_DE_MAGIC_NUMBER.P_997,
    '996-1': MOBILE_DE_MAGIC_NUMBER.P_996,
    '996-2': MOBILE_DE_MAGIC_NUMBER.P_996,
    '993-1': MOBILE_DE_MAGIC_NUMBER.P_993,
    '993-2': MOBILE_DE_MAGIC_NUMBER.P_993,
    '964': MOBILE_DE_MAGIC_NUMBER.P_964,
    // f-model and g-model used to be just slang words, but we use them now as model series for early 911s
    'g-model-2': MOBILE_DE_MAGIC_NUMBER.P_911_URMODELL,
    'g-model-1': MOBILE_DE_MAGIC_NUMBER.P_911_URMODELL,
    'f-model': MOBILE_DE_MAGIC_NUMBER.P_911_URMODELL,
}

// Some selectable models on mobile.de are model series
const mobileDeModelSeriesMapping: Record<string, MOBILE_DE_MAGIC_NUMBER> = {
    '356': MOBILE_DE_MAGIC_NUMBER.P_356,
    '911': MOBILE_DE_MAGIC_NUMBER.P_911ER_REIHE,
    '914': MOBILE_DE_MAGIC_NUMBER.P_914,
    '918': MOBILE_DE_MAGIC_NUMBER.P_918,
    '924': MOBILE_DE_MAGIC_NUMBER.P_924,
    '928': MOBILE_DE_MAGIC_NUMBER.P_928,
    '944': MOBILE_DE_MAGIC_NUMBER.P_944,
    '959': MOBILE_DE_MAGIC_NUMBER.P_959,
    '962': MOBILE_DE_MAGIC_NUMBER.P_962,
    '968': MOBILE_DE_MAGIC_NUMBER.P_968,
    'carrera-gt': MOBILE_DE_MAGIC_NUMBER.P_CARRERA_GT,
    cayenne: MOBILE_DE_MAGIC_NUMBER.P_CAYENNE,
    macan: MOBILE_DE_MAGIC_NUMBER.P_MACAN,
    panamera: MOBILE_DE_MAGIC_NUMBER.P_PANAMERA,
    taycan: MOBILE_DE_MAGIC_NUMBER.P_TAYCAN,
}

export const getMobileDeModelMagicNumber = (
    modelSeriesKey?: string,
    modelGenerationKey?: string,
    derivativeKey?: string,
): MOBILE_DE_MAGIC_NUMBER | undefined => {
    if (modelSeriesKey === '911') {
        if (derivativeKey?.includes('930')) {
            return MOBILE_DE_MAGIC_NUMBER.P_930
        }

        if (derivativeKey?.includes('912')) {
            return MOBILE_DE_MAGIC_NUMBER.P_912
        }

        const modelGenerationMagicNumber = modelGenerationKey && mobileDe911ModelGenerationMapping[modelGenerationKey]
        if (modelGenerationMagicNumber) {
            return modelGenerationMagicNumber
        }
    } else if (modelSeriesKey === '718') {
        if (derivativeKey?.includes('boxster')) {
            return MOBILE_DE_MAGIC_NUMBER.P_BOXSTER
        }
        if (derivativeKey?.includes('cayman')) {
            return MOBILE_DE_MAGIC_NUMBER.P_CAYMAN
        }
    }

    return modelSeriesKey ? mobileDeModelSeriesMapping[modelSeriesKey] : undefined
}

export const getMinMaxMileage = (mileageInKmOrMiles: number, mileageOffset = 20000) => {
    const minimumMileage = mileageInKmOrMiles - mileageOffset < 0 ? 0 : mileageInKmOrMiles - mileageOffset
    const maximumMileage = mileageInKmOrMiles + mileageOffset
    return { minimumMileage, maximumMileage }
}

/**
 * Generates a link to mobile.de with the given search Parameters (see SOC-454)
 * @param modelSeries
 * @param modelGeneration
 * @param derivative
 * @param mileageInKmOrMiles
 * @param firstRegistrationDate
 */
export const buildMobileDeLink = ({
    modelSeries,
    modelGeneration,
    derivative,
    mileageInKmOrMiles,
    firstRegistrationDate,
}: SearchProps): string => {
    const porscheMobileManufacturerId = '20100'

    const { minimumMileage, maximumMileage } = getMinMaxMileage(mileageInKmOrMiles)

    const url = mobileURL()

    const modelMagicNumber = getMobileDeModelMagicNumber(modelSeries, modelGeneration, derivative)
    const firstRegistrationYear = firstRegistrationDate?.getFullYear()

    if (firstRegistrationYear) {
        url.searchParams.set('fr', `${firstRegistrationYear - 2}:${firstRegistrationYear + 2}`)
    }
    if (0 <= minimumMileage && 0 <= maximumMileage) {
        url.searchParams.set('ml', `${minimumMileage}:${maximumMileage}`)
    }
    if (modelMagicNumber) {
        url.searchParams.set('ms', `${porscheMobileManufacturerId};${modelMagicNumber};;`)
    } else {
        url.searchParams.set('ms', `${porscheMobileManufacturerId};;;`)
    }

    return url.toString()
}

/**
 * SOC-453#AC5: following filters should be set:
 * - model (= model series)
 * - model-generation (= model generation)
 * - mileage from (-20.000 km of trade-in)
 * - mileage to (+20.000 km of trade-in)
 * (SOC-454: +firstRegistrationDate)
 * @param environment e.g. preview
 * @param locale e.g. de-DE
 * @param marketplaceKey e.g. de
 * @param modelSeries e.g. 911 or undefined
 * @param modelGeneration e.g. 922 or undefined
 * @param derivative e.g. 911-carrera-cabriolet or undefined
 * @param mileageInKmOrMiles e.g. 60000 - we don't need to know if its miles or km here
 * @returns e.g. // https://finder.porsche.com/de/de-DE/search?model=911&model-generation=992&minimum-mileage=40000&maximum-mileage=80000
 */
export const buildFinderLink = ({
    environment,
    locale,
    marketplaceKey,
    modelSeries,
    modelGeneration,
    derivative,
    mileageInKmOrMiles,
}: {
    environment: DealerContextProps['environment']
    locale: DealerContextProps['locale']
    marketplaceKey: DealerContextProps['marketplaceKey']
} & SearchProps): string => {
    const infix = `${marketplaceKey}/${locale}/search`
    // On local host (=no env) we fall back to creating prod-finder links
    const url = finderURL(environment ?? 'prod', infix)

    const { minimumMileage, maximumMileage } = getMinMaxMileage(mileageInKmOrMiles)

    // ?minimum-mileage=40000
    url.searchParams.set('minimum-mileage', `${minimumMileage}`)
    // &maximum-mileage=80000
    url.searchParams.set('maximum-mileage', `${maximumMileage}`)
    if (modelSeries) {
        // &model=911
        url.searchParams.set('model', modelSeries)
    }
    // Note: With modelSeries missing and modelGeneration set the results are
    //       filtered correctly, but the rest of the UI doesn't reflect this
    //       state
    if (modelGeneration) {
        // &model-generation=992
        url.searchParams.set('model-generation', modelGeneration)
    }
    if (derivative) {
        // &category=911-carrera-4s-convertible
        url.searchParams.set('category', derivative)
    }
    return url.toString()
}

export const MarketplaceLinks: FC<MarketplaceLinksProps> = ({ searchFilterData }) => {
    const { formatMessage } = useIntl()
    const { locale, environment, marketplaceKey } = useDealerContext()
    const tradeInManagerContext = useTradeInManagerContext()

    const finderLinkText = formatMessage(msg.finderButton) // SOC-453
    const mobileDeLinkText = formatMessage(msg.mobileDeButton) // SOC-454

    const { modelSeriesKey, modelGenerationKey, derivativeKey, firstRegistrationDate, mileage } = searchFilterData
    const firstRegistrationDateToDate = new Date(firstRegistrationDate)

    const finderLink = buildFinderLink({
        environment,
        locale,
        marketplaceKey,
        modelSeries: modelSeriesKey, // VDS data
        modelGeneration: modelGenerationKey, // VDS data
        derivative: derivativeKey, // VDS data
        mileageInKmOrMiles: mileage.value, // Customer data
    })

    const renderMobileDeLinkButton = () => {
        const mobileDeLink = buildMobileDeLink({
            modelSeries: modelSeriesKey,
            modelGeneration: modelGenerationKey,
            derivative: derivativeKey,
            mileageInKmOrMiles: mileage.value,
            firstRegistrationDate: firstRegistrationDateToDate,
        })
        return <MarketplaceLinkButton title={mobileDeLinkText} link={mobileDeLink} />
    }
    // Note: Mobile.de currently only for German marketplaces. Might change in the future
    return (
        <Flex>
            <div>
                <MarketplaceLinkButton title={finderLinkText} link={finderLink} />
            </div>
            {tradeInManagerContext.showThirdPartyProviderLink && (
                <FlexItemWithMarginTop>{renderMobileDeLinkButton()}</FlexItemWithMarginTop>
            )}
        </Flex>
    )
}

const Flex = styled.div`
    display: flex;
    flex-direction: column;
`

const FlexItemWithMarginTop = styled.div`
    margin-top: ${spacingFluidMedium};
`
