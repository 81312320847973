/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Source of this trade-in request
 * @export
 */
export const TradeInSourceDTO = {
    FINDER_CONTACT: 'FINDER_CONTACT',
    ICC_CONTACT: 'ICC_CONTACT',
    FINDER_DEALBUILDER: 'FINDER_DEALBUILDER',
    ICC_DEALBUILDER: 'ICC_DEALBUILDER',
} as const
export type TradeInSourceDTO = (typeof TradeInSourceDTO)[keyof typeof TradeInSourceDTO]

export function instanceOfTradeInSourceDTO(value: any): boolean {
    for (const key in TradeInSourceDTO) {
        if (Object.prototype.hasOwnProperty.call(TradeInSourceDTO, key)) {
            if (TradeInSourceDTO[key as keyof typeof TradeInSourceDTO] === value) {
                return true
            }
        }
    }
    return false
}

export function TradeInSourceDTOFromJSON(json: any): TradeInSourceDTO {
    return TradeInSourceDTOFromJSONTyped(json, false)
}

export function TradeInSourceDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeInSourceDTO {
    return json as TradeInSourceDTO
}

export function TradeInSourceDTOToJSON(value?: TradeInSourceDTO | null): any {
    return value as any
}

export function TradeInSourceDTOToJSONTyped(value: any, ignoreDiscriminator: boolean): TradeInSourceDTO {
    return value as TradeInSourceDTO
}
