import { Configuration, FrontendApi, type Middleware, type RequestContext, TradeInControllerApi } from './lib/generated'

export * from './lib/generated/apis'
export * from './lib/generated/models'

export type Stage = 'local' | 'dev' | 'preview' | 'prod'

export const backendUrls: Record<Stage, URL> = {
    prod: new URL('https://trade-in-service.cstradein.aws.porsche.cloud'),
    preview: new URL('https://preview.trade-in-service.cstradein.aws.porsche-preview.cloud'),
    dev: new URL('https://dev.trade-in-service.cstradein.aws.porsche-preview.cloud'),
    local: new URL('http://localhost:8080'),
}

interface AuthorizationHeader {
    Authorization: string
}

class TokenMiddleware implements Middleware {
    private tokenFunction?: () => Promise<AuthorizationHeader | undefined>

    public setTokenFunction(tokenFunction: () => Promise<AuthorizationHeader | undefined>) {
        this.tokenFunction = tokenFunction
    }

    pre = async ({ url, init }: RequestContext) => {
        if (this.tokenFunction) {
            const existingHeaders = init.headers || {}
            const headersWithAuth = {
                ...existingHeaders,
                ...(await this.tokenFunction()),
            }
            const initWithAuth = { ...init, headers: headersWithAuth }
            return { init: initWithAuth, url }
        }
        return
    }
}

class BasePathMiddleware implements Middleware {
    private env: Stage = 'local'
    public setEnv = (env: Stage) => (this.env = env)
    pre = async ({ url, init }: RequestContext) => {
        const backendUrl = backendUrls[this.env]
        const requestUrl = new URL(url)
        requestUrl.hostname = backendUrl.hostname
        requestUrl.protocol = backendUrl.protocol
        requestUrl.port = backendUrl.port
        return { init, url: requestUrl.toString() }
    }
}

export const tokenMiddleware = new TokenMiddleware()
export const basePathMiddleware = new BasePathMiddleware()

const config = new Configuration({
    middleware: [basePathMiddleware, tokenMiddleware],
})

export const tradeInControllerApi = new TradeInControllerApi(config)
export const frontendApi = new FrontendApi(config)
