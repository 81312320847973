import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router'
import { tradeInControllerApi } from 'tim-api-client'

import { TradeInDetailPageContainerProvider } from '~/app/pages/request-detail/context/TradeInDetailPageContext'
import { TradeInRequestDetailPage } from '~/app/pages/request-detail/TradeInRequestDetailPage'

import { TradeInRequestDetailPageProps } from './TradeInRequestDetailPageProps'

export const TradeInRequestDetailPageContainer = ({ getVehicleDetailsUrl }: TradeInRequestDetailPageProps) => {
    const { id } = useParams<{ id: string }>()

    const { data } = useQuery({
        queryKey: ['trade-in-request', id],
        queryFn: () => tradeInControllerApi.fetchTradeInMetaInfo({ id: id ?? '' }),
    })

    return (
        <TradeInDetailPageContainerProvider>
            <TradeInRequestDetailPage getVehicleDetailsUrl={getVehicleDetailsUrl} data={data} id={id} />
        </TradeInDetailPageContainerProvider>
    )
}
