import React from 'react'

import { useTradeInManagerBackend } from '~/app/backend/useTradeInManagerBackendContext'
import { PurchasePrice } from '~/app/pages/request-detail/components/PriceEstimation/PurchasePrice/PurchasePrice'

export interface PurchasePriceContainerProps {
    tradeInId: string
    priceEstimationDate: Date
}

export const PurchasePriceContainer = ({ tradeInId, priceEstimationDate }: PurchasePriceContainerProps) => {
    const { useDealPriceDataQuery } = useTradeInManagerBackend()

    const { data: dealPriceData } = useDealPriceDataQuery({ tradeInId })

    return <PurchasePrice dealPriceData={dealPriceData} priceEstimationDate={priceEstimationDate} />
}
