import { useDealerContext } from '@slfinrtl/context'

import { useTradeInManagerBackend } from '~/app/backend/useTradeInManagerBackendContext'
import {
    ACPriceEstimation,
    MarketPriceError,
    MarketPriceLoading,
} from '~/app/pages/request-detail/components/PriceEstimation/MarketPrice/MarketPrice'

/**
 * This component offers an estimated market-price of the current trade-in-vehicle for a dealer
 * @param tradeInId id of the current trade in vehicle
 */
export const MarketPriceContainer = ({ tradeInId }: { tradeInId: string }) => {
    const { locale } = useDealerContext()

    const { useMarketPriceQuery } = useTradeInManagerBackend()

    const { data: marketPriceData, isLoading, error } = useMarketPriceQuery({ tradeInId, locale })

    if (isLoading) {
        return <MarketPriceLoading />
    }

    if (error || !marketPriceData?.trend30days) {
        return <MarketPriceError />
    }

    return (
        <ACPriceEstimation
            marketPriceInCent={marketPriceData.trend30days}
            marketPriceWarnings={marketPriceData.warnings}
            source={'Analytic Company'}
            tradeInId={tradeInId}
        />
    )
}
