/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 * Vehicle which customer wants to potentially buy
 * @export
 * @interface WantedVehicleDTO
 */
export interface WantedVehicleDTO {
    /**
     * If type INVENTORY, this is a Porsche Finder inventory id, which can be resolved via InventoryService
     * @type {string}
     * @memberof WantedVehicleDTO
     */
    id: string
    /**
     * INVENTORY = trade-in created by finder.porsche.com). CSV = trade-in created by configurator.porsche.com
     * @type {string}
     * @memberof WantedVehicleDTO
     */
    type: WantedVehicleDTOTypeEnum
}

/**
 * @export
 */
export const WantedVehicleDTOTypeEnum = {
    INVENTORY: 'INVENTORY',
    CSV: 'CSV',
} as const
export type WantedVehicleDTOTypeEnum = (typeof WantedVehicleDTOTypeEnum)[keyof typeof WantedVehicleDTOTypeEnum]

/**
 * Check if a given object implements the WantedVehicleDTO interface.
 */
export function instanceOfWantedVehicleDTO(value: object): value is WantedVehicleDTO {
    if (!('id' in value) || value['id'] === undefined) return false
    if (!('type' in value) || value['type'] === undefined) return false
    return true
}

export function WantedVehicleDTOFromJSON(json: any): WantedVehicleDTO {
    return WantedVehicleDTOFromJSONTyped(json, false)
}

export function WantedVehicleDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WantedVehicleDTO {
    if (json == null) {
        return json
    }
    return {
        id: json['id'],
        type: json['type'],
    }
}

export function WantedVehicleDTOToJSON(json: any): WantedVehicleDTO {
    return WantedVehicleDTOToJSONTyped(json, false)
}

export function WantedVehicleDTOToJSONTyped(
    value?: WantedVehicleDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        id: value['id'],
        type: value['type'],
    }
}
