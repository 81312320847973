/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface TradeInOverviewEntryCustomerDTOValue
 */
export interface TradeInOverviewEntryCustomerDTOValue {
    /**
     * Full name of the customer
     * @type {string}
     * @memberof TradeInOverviewEntryCustomerDTOValue
     */
    name?: string
}

/**
 * Check if a given object implements the TradeInOverviewEntryCustomerDTOValue interface.
 */
export function instanceOfTradeInOverviewEntryCustomerDTOValue(
    value: object,
): value is TradeInOverviewEntryCustomerDTOValue {
    return true
}

export function TradeInOverviewEntryCustomerDTOValueFromJSON(json: any): TradeInOverviewEntryCustomerDTOValue {
    return TradeInOverviewEntryCustomerDTOValueFromJSONTyped(json, false)
}

export function TradeInOverviewEntryCustomerDTOValueFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): TradeInOverviewEntryCustomerDTOValue {
    if (json == null) {
        return json
    }
    return {
        name: json['name'] == null ? undefined : json['name'],
    }
}

export function TradeInOverviewEntryCustomerDTOValueToJSON(json: any): TradeInOverviewEntryCustomerDTOValue {
    return TradeInOverviewEntryCustomerDTOValueToJSONTyped(json, false)
}

export function TradeInOverviewEntryCustomerDTOValueToJSONTyped(
    value?: TradeInOverviewEntryCustomerDTOValue | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        name: value['name'],
    }
}
