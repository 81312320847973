import { defineMessages } from 'react-intl'
import { OwnershipDTOOwnershipTypeEnum } from 'tim-api-client'

export const messagesForTradeInOwnershipType = defineMessages({
    fullyOwned: {
        id: 'tradeIn.requests.ownershipType.fullOwned',
        defaultMessage: 'Fully-owned',
    },
    finance: {
        id: 'tradeIn.requests.ownershipType.finance',
        defaultMessage: 'Financed',
    },
    lease: {
        id: 'tradeIn.requests.ownershipType.lease',
        defaultMessage: 'Leased',
    },
})

export const formatTradeInOwnershipType = (tradeInOwnershipType: OwnershipDTOOwnershipTypeEnum) => {
    switch (tradeInOwnershipType) {
        case 'FULLY_OWNED':
            return messagesForTradeInOwnershipType.fullyOwned
        case 'FINANCE':
            return messagesForTradeInOwnershipType.finance
        case 'LEASE':
            return messagesForTradeInOwnershipType.lease
    }
}
