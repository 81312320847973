type Environment = 'dev' | 'preview' | 'prod' | 'local' | null

/**
 * Returns a base URL of the Porsche-Car-Configurator for the specified environment
 * @param environment application environment (e.g. 'prod' or 'staging')
 */
export const getConfiguratorBaseUrl = (environment: Environment) => {
    switch (environment) {
        case 'prod':
            return 'https://configurator.porsche.com'

        case 'preview':
            return 'https://configurator-staging.porsche.com'

        case 'dev':
        case 'local':
        default:
            return 'https://configurator-dev.porsche.com'
    }
}

/**
 * Returns a URL for the Porsche Car Configurator with a pre-configured porsche code.
 * @param porscheCode code of the configured vehicle
 * @param environment application environment (e.g. 'prod' or 'staging')
 */
export const getPorscheCodeUrl = ({ porscheCode, environment }: { porscheCode: string; environment: Environment }) => {
    return `${getConfiguratorBaseUrl(environment)}/porsche-code/${porscheCode}`
}
