import { useDealerContext } from '@slfinrtl/context'
import { useIntl } from 'react-intl'
import { CustomerDTO, VehicleOfInterestDTO } from 'tim-api-client'

import {
    CardWithMarginTop,
    FullWidth,
    VehicleDetailGrid,
    VehicleDetailsHeadline,
    VehicleDetailsItem,
} from '~/app/components'
import type { CustomerInformationContainerProps } from '~/app/pages/request-detail/components/CustomerInformation/CustomerInformationContainer'
import { customerInformationMessages } from '~/app/pages/request-detail/components/CustomerInformation/messages'
import { VehicleDetailsLink } from '~/app/pages/request-detail/components/CustomerInformation/VehicleDetailsLink'
import { tradeInRequestDetailMessages } from '~/app/pages/request-detail/messages/tradeInRequestDetailPageMessages'
import { getPorscheCodeUrl } from '~/app/ui-utils'

export interface CustomerInformationProps {
    customerData: CustomerDTO
    vehicleData: VehicleOfInterestDTO
    getVehicleDetailsUrl: CustomerInformationContainerProps['getVehicleDetailsUrl']
}

export const CustomerInformation = ({ customerData, vehicleData, getVehicleDetailsUrl }: CustomerInformationProps) => {
    const { formatMessage } = useIntl()
    const { environment } = useDealerContext()

    return (
        <CardWithMarginTop bottomSpacing={false}>
            <FullWidth>
                <VehicleDetailsHeadline>
                    {formatMessage(tradeInRequestDetailMessages.customerInformationInfoBoxTitle)}
                </VehicleDetailsHeadline>
                <VehicleDetailGrid>
                    <VehicleDetailsItem
                        label={formatMessage(customerInformationMessages.name)}
                        value={
                            customerData ? `${customerData?.value?.firstName} ${customerData?.value?.lastName}` : '-'
                        }
                        hiddenStatus={customerData.hidden}
                        copyToClipboardButton={!!customerData?.value?.firstName}
                    />
                    <VehicleDetailsItem
                        label={formatMessage(customerInformationMessages.mail)}
                        value={customerData?.value?.email ? `${customerData?.value?.email}` : '-'}
                        hiddenStatus={customerData.hidden}
                        copyToClipboardButton={!!customerData?.value?.email}
                    />
                    <VehicleDetailsItem
                        label={formatMessage(customerInformationMessages.phone)}
                        value={customerData.value?.phoneNumber ? `${customerData.value?.phoneNumber}` : '-'}
                        hiddenStatus={customerData.hidden}
                        copyToClipboardButton={!!customerData.value?.phoneNumber}
                    />
                    <VehicleDetailsItem
                        label={formatMessage(customerInformationMessages.interestedIn)}
                        value={
                            vehicleData?.description && vehicleData?.id && environment !== null ? (
                                <VehicleDetailsLink
                                    vehicleData={vehicleData}
                                    buildInventoryVehicleUrl={getVehicleDetailsUrl}
                                    buildConfiguredVehicleUrl={getPorscheCodeUrl}
                                    environment={environment}
                                />
                            ) : (
                                (vehicleData?.description ?? '-')
                            )
                        }
                    />
                </VehicleDetailGrid>
            </FullWidth>
        </CardWithMarginTop>
    )
}
