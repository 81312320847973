import React from 'react'
import { useIntl } from 'react-intl'
import { TradeInVehicleDealPriceDataDTO } from 'tim-api-client'

import { PriceTile } from '~/app/components'
import { Markdown } from '~/app/components/markdown/Markdown'
import { purchasePriceMessages } from '~/app/pages/request-detail/components/PriceEstimation/PurchasePrice/messages'
import {
    HeadingLarge,
    PopoverWithWrapText,
    PriceEntries,
    PriceEntry,
    PurchasePriceSubtitle,
    PurchasePriceSubtitleStatusDate,
    SpacingFluidSmall,
    TextXSmall,
    TextXSmallSemiBold,
} from '~/app/pages/request-detail/components/PriceEstimation/PurchasePrice/PurchasePrice.styled'

interface PurchasePriceProps {
    dealPriceData?: TradeInVehicleDealPriceDataDTO
    priceEstimationDate: Date
}

export const PurchasePrice = (props: PurchasePriceProps) => {
    const { formatMessage, formatDate } = useIntl()
    const { dealPriceData, priceEstimationDate } = props

    return (
        <PriceTile
            heading={formatMessage(purchasePriceMessages.tileHeading)}
            content={
                <>
                    <PurchasePriceSubtitle>
                        <div>{formatMessage(purchasePriceMessages.labelCalculatedPurchasePrice)}</div>
                        <PurchasePriceSubtitleStatusDate>
                            {formatMessage(purchasePriceMessages.calculatedPurchasePriceStatusDate, {
                                date: formatDate(priceEstimationDate),
                            })}
                        </PurchasePriceSubtitleStatusDate>
                        <PopoverWithWrapText
                            inert={undefined}
                            direction={'top'}
                            description={formatMessage(purchasePriceMessages.infoPopup)}
                        />
                    </PurchasePriceSubtitle>
                    <SpacingFluidSmall />
                    {dealPriceData ? (
                        <PurchasePriceCalculation dealPriceData={dealPriceData} />
                    ) : (
                        <Markdown source={formatMessage(purchasePriceMessages.noPurchasePriceAvailable)} />
                    )}
                </>
            }
        />
    )
}

const PurchasePriceCalculation = (props: { dealPriceData: TradeInVehicleDealPriceDataDTO }) => {
    const { formatMessage, formatNumber } = useIntl()
    const { dealPriceData } = props

    const rangedPriceEstimation = dealPriceData.rangedPriceEstimation
    const formattedLowEstimate = formatNumber(rangedPriceEstimation.low, {
        style: 'currency',
        currency: rangedPriceEstimation.currency,
    })
    const formattedHighEstimate = formatNumber(rangedPriceEstimation.high, {
        style: 'currency',
        currency: rangedPriceEstimation.currency,
    })
    const formattedMedianEstimate = formatNumber(rangedPriceEstimation.estimate, {
        style: 'currency',
        currency: rangedPriceEstimation.currency,
    })

    const finalEstimate = dealPriceData.ownership.outstandingFinanceOrLeaseAmount
        ? rangedPriceEstimation.estimate - dealPriceData.ownership.outstandingFinanceOrLeaseAmount.amount
        : rangedPriceEstimation.estimate
    const formattedFinalEstimate = formatNumber(finalEstimate, {
        style: 'currency',
        currency: rangedPriceEstimation.currency,
    })

    return (
        <PriceEntries>
            <PriceEntry>
                <TextXSmall>{formatMessage(purchasePriceMessages.estimatedPriceRange)}</TextXSmall>
                <TextXSmallSemiBold>
                    {formattedLowEstimate} - {formattedHighEstimate}
                </TextXSmallSemiBold>
            </PriceEntry>
            {dealPriceData.ownership.outstandingFinanceOrLeaseAmount && (
                <>
                    <PriceEntry>
                        <TextXSmall>{formatMessage(purchasePriceMessages.medianPriceEstimate)}</TextXSmall>
                        <TextXSmallSemiBold>{formattedMedianEstimate}</TextXSmallSemiBold>
                    </PriceEntry>
                    <PriceEntry>
                        <TextXSmall>{formatMessage(purchasePriceMessages.expectedTransferFee)}</TextXSmall>
                        <TextXSmallSemiBold>
                            -&nbsp;
                            {formatNumber(dealPriceData.ownership.outstandingFinanceOrLeaseAmount.amount, {
                                style: 'currency',
                                currency: dealPriceData.ownership.outstandingFinanceOrLeaseAmount.currency,
                            })}
                        </TextXSmallSemiBold>
                    </PriceEntry>
                </>
            )}
            <PriceEntry>
                <TextXSmall>{formatMessage(purchasePriceMessages.finalEstimate)}</TextXSmall>
                <HeadingLarge>{formattedFinalEstimate}</HeadingLarge>
            </PriceEntry>
        </PriceEntries>
    )
}
