import { DealerContextProps } from '@slfinrtl/context-types'
import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query'
import React, { PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'
import { TradeInOverviewPage } from 'tim-api-client'

import { WideTopLevelContent as WideTopLevelContentContainer } from '~/app/components'
import TradeInRequestTable from '~/app/pages/request-table/components/TradeInRequestTable/TradeInRequestTable'
import {
    TradeInRequestTableSearchAndFilters,
    TradeInRequestTableSearchAndFiltersProps,
} from '~/app/pages/request-table/components/TradeInRequestTableSearchAndFilters/TradeInRequestTableSearchAndFilters'
import TradeInRequestTableTotalCount from '~/app/pages/request-table/components/TradeInRequestTableTotalCount/TradeInRequestTableTotalCount'
import { useInfiniteScroll } from '~/app/pages/request-table/hooks/useInfiniteScroll'
import { messagesForTradeInRequestTable as messages } from '~/app/pages/request-table/messages/messagesForTradeInRequestTable'
import { HeadingXXLarge, TopMarginMedium } from '~/app/pages/request-table/TradeInRequestTablePage.styled'

export interface TradeInRequestTablePageProps {
    dealerEntryDn: DealerContextProps['dealershipId']
    country: DealerContextProps['countryCode']
    getVehicleDetailsUrl: (vehicleId: string) => string
    tableData: Pick<
        UseInfiniteQueryResult<InfiniteData<TradeInOverviewPage, unknown>, Error>,
        'data' | 'isError' | 'isFetchingNextPage'
    >
    onScrollToPageEndLoadMore: () => void
    filterProps: TradeInRequestTableSearchAndFiltersProps
}

/**
 * Wraps the TradeInRequestTable with infinite loading logic
 * @constructor
 */
export function TradeInRequestTablePage({
    getVehicleDetailsUrl,
    tableData,
    onScrollToPageEndLoadMore,
    filterProps,
}: TradeInRequestTablePageProps) {
    const intl = useIntl()

    const { data } = tableData

    useInfiniteScroll(onScrollToPageEndLoadMore)

    const totalCount = data?.pages[0]?.totalElements ?? 0

    return (
        <>
            <WideTopLevelContent>
                <HeadingXXLarge>{intl.formatMessage(messages.tradeInRequestTableTitle)}</HeadingXXLarge>
            </WideTopLevelContent>
            <WideTopLevelContent>
                <TradeInRequestTableSearchAndFilters {...filterProps} />
            </WideTopLevelContent>
            <WideTopLevelContent>
                <TradeInRequestTableTotalCount totalCount={totalCount} />
            </WideTopLevelContent>
            <WideTopLevelContent>
                <TradeInRequestTable {...tableData} getVehicleDetailsUrl={getVehicleDetailsUrl} />
            </WideTopLevelContent>
        </>
    )
}

const WideTopLevelContent = ({ children }: PropsWithChildren) => (
    <WideTopLevelContentContainer>
        <TopMarginMedium>{children}</TopMarginMedium>
    </WideTopLevelContentContainer>
)

export default TradeInRequestTablePage
