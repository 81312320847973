import { PText, PTextList, PTextListItem } from '@porsche-design-system/components-react'
import { compiler, MarkdownToJSX } from 'markdown-to-jsx'

export interface MarkdownProps {
    source: string
    overrides?: MarkdownToJSX.Overrides
}

export const Markdown = ({ source, overrides }: MarkdownProps) => {
    const html = compiler(source, {
        forceBlock: true,
        namedCodesToUnicode: {
            copy: '©',
        },
        overrides: {
            li: {
                component: PTextListItem,
            },
            ol: {
                component: PTextList,
                props: {
                    type: 'numbered',
                },
            },
            ul: {
                component: PTextList,
                props: {
                    type: 'unordered',
                },
            },
            p: {
                component: PText,
            },
            ...overrides,
        },
    })

    return html
}
