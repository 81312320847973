import { defineMessages } from 'react-intl'
import { BodyTypeDTO, EngineTypeDTO, TradeInPowerUnitDTO, TransmissionTypeDTO } from 'tim-api-client'

export const vehicleInformationMessages = defineMessages({
    vin: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.vin',
        defaultMessage: 'VIN',
    },
    manufacturer: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.manufacturer',
        defaultMessage: 'Manufacturer',
    },
    model: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.model',
        defaultMessage: 'Model',
    },
    modelVariant: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.modelVariant',
        defaultMessage: 'Model Variant',
    },
    modelGeneration: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.modelGeneration',
        defaultMessage: 'Model Generation',
    },
    bodyType: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.bodyType',
        defaultMessage: 'Body Type',
    },
    transmission: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.transmission',
        defaultMessage: 'Transmission',
    },
    power: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.power',
        defaultMessage: 'Power',
    },
    exterior: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.exterior',
        defaultMessage: 'Exterior',
    },
    interior: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.interior',
        defaultMessage: 'Interior',
    },
    engineType: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.engineType',
        defaultMessage: 'Engine Type',
    },
    modelYear: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.modelYear',
        defaultMessage: 'Model Year',
    },
    [EngineTypeDTO.GASOLINE]: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.engineType.GASOLINE',
        defaultMessage: 'Combustion Engine',
    },
    [EngineTypeDTO.GAS]: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.engineType.GAS',
        defaultMessage: 'Gas',
    },
    [EngineTypeDTO.DIESEL]: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.engineType.DIESEL',
        defaultMessage: 'Diesel',
    },
    [EngineTypeDTO.PLUG_IN_HYBRID]: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.engineType.PLUG_IN_HYBRID',
        defaultMessage: 'Plugin-In Hybrid',
    },
    [EngineTypeDTO.ELECTRIC]: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.engineType.ELECTRIC',
        defaultMessage: 'Electric',
    },
    [TransmissionTypeDTO.MANUAL]: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.transmission.MANUAL',
        defaultMessage: 'Manual',
    },
    [TransmissionTypeDTO.AUTOMATIC]: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.transmission.AUTOMATIC',
        defaultMessage: 'Automatic',
    },
    [BodyTypeDTO.SUV]: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.bodyType.SUV',
        defaultMessage: 'SUV',
    },
    [BodyTypeDTO.COMBI]: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.bodyType.COMBI',
        defaultMessage: 'Combi',
    },
    [BodyTypeDTO.COUPE]: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.bodyType.COUPE',
        defaultMessage: 'Coupé',
    },
    [BodyTypeDTO.CABRIOLET]: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.bodyType.CABRIOLET',
        defaultMessage: 'Cabriolet',
    },
    [BodyTypeDTO.LIMOUSINE]: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.bodyType.LIMOUSINE',
        defaultMessage: 'Limousine',
    },
    // Note: We intentionally use ".OTHERS" here to avoid using the reserved ".other" suffix
    [BodyTypeDTO.OTHER]: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.bodyType.OTHERS',
        defaultMessage: 'Other',
    },
    [TradeInPowerUnitDTO.KILOWATT]: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.powerUnit.KILOWATT',
        defaultMessage: 'kW',
    },
    [TradeInPowerUnitDTO.PS_OR_HORSEPOWER]: {
        id: 'tradeIn.requests.detail.infobox.vehicleInformation.powerUnit.PS_OR_HORSEPOWER',
        defaultMessage: 'PS',
    },
})
