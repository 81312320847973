import { border, spacingFluidMedium, themeLightBackgroundBase } from '@porsche-design-system/components-react/styles'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { SpacingFluidMedium } from '~/app/components/layout/Spacing'

export type CardProps = { bottomSpacing?: boolean } & PropsWithChildren
export const Card = ({ children, bottomSpacing }: CardProps) => {
    return (
        <CardWrapper $bottomSpacing={bottomSpacing ?? true}>
            <CardLayout>{children}</CardLayout>
        </CardWrapper>
    )
}

export const CardWithMarginTop = ({ children, bottomSpacing }: CardProps) => (
    <>
        <SpacingFluidMedium />
        <Card bottomSpacing={bottomSpacing}>{children}</Card>
    </>
)

const CardWrapper = styled.div<{ $bottomSpacing: boolean }>`
    border-radius: ${border.radius.medium};
    padding: ${spacingFluidMedium};
    padding-bottom: ${({ $bottomSpacing }) => ($bottomSpacing ? spacingFluidMedium : 0)};
    background: ${themeLightBackgroundBase};
`

const CardLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`
