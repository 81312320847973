import { defineMessages } from 'react-intl'

export const feedbackFormMessages = defineMessages({
    titleLikertAnswers: {
        id: 'tradeIn.requests.detail.infobox.feedBackForm.titleLikertAnswers',
        defaultMessage: 'Overall, how satisfied are you with the information available on this page?',
    },
    labelVeryDissatisfied: {
        id: 'tradeIn.requests.detail.infobox.feedBackForm.label.VeryDissatisfied',
        defaultMessage: 'Very dissatisfied',
    },
    labelVerySatisfied: {
        id: 'tradeIn.requests.detail.infobox.feedBackForm.label.VerySatisfied',
        defaultMessage: 'Very satisfied',
    },
    titleFreeText: {
        id: 'tradeIn.requests.detail.infobox.feedBackForm.titleFreeText',
        defaultMessage: 'How satisfied are you with the information presented on this page?',
    },
    badFeedbackReason: {
        id: 'tradeIn.requests.detail.infobox.feedBackForm.badFeedbackReason',
        defaultMessage: 'Why are you dissatisfied?',
    },
    submitButton: {
        id: 'tradeIn.requests.detail.infobox.feedBackForm.submitButton',
        defaultMessage: 'Send Feedback',
    },
})

export const feedbackReasonMessages = defineMessages({
    TooLittleInformation: {
        id: 'tradeIn.requests.detail.infobox.feedBackForm.reasonDropdown.littleInformation',
        defaultMessage: 'Too little information about the trade-in car',
    },
    UselessCustomerVehicle: {
        id: 'tradeIn.requests.detail.infobox.feedBackForm.reasonDropdown.uselessCustomerVehicle',
        defaultMessage: 'Useless trade-in vehicle of the customer',
    },
    MissingEditFeature: {
        id: 'tradeIn.requests.detail.infobox.feedBackForm.reasonDropdown.missingEditFeature',
        defaultMessage: 'Editing of trade-in request information is missing',
    },
    MissingStatusFeature: {
        id: 'tradeIn.requests.detail.infobox.feedBackForm.reasonDropdown.missingStatusFeature',
        defaultMessage: 'Status of trade-in request is missing',
    },
    MissingPicturesFeature: {
        id: 'tradeIn.requests.detail.infobox.feedBackForm.reasonDropdown.missingPicturesFeature',
        defaultMessage: 'Pictures of the car are missing',
    },
    OtherReason: {
        id: 'tradeIn.requests.detail.infobox.feedBackForm.reasonDropdown.otherReason',
        defaultMessage: 'Other (e.g. missing important feature)',
    },
})

export const feedbackFormNotifications = defineMessages({
    transmissionSuccess: {
        id: 'tradeIn.requests.detail.infobox.feedBackForm.transmission.success',
        defaultMessage: 'Thanks for your Feedback and helping us to improve!',
    },
    transmissionErrorTitle: {
        id: 'tradeIn.requests.detail.infobox.feedBackForm.transmission.title.error',
        defaultMessage: 'Error sending Feedback',
    },
    transmissionErrorMessage: {
        id: 'tradeIn.requests.detail.infobox.feedBackForm.transmission.message.error',
        defaultMessage: 'An error occurred while sending feedback. Please try again later.',
    },
})
