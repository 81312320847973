/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CustomerDTOValue
 */
export interface CustomerDTOValue {
    /**
     * Customer first name
     * @type {string}
     * @memberof CustomerDTOValue
     */
    firstName: string
    /**
     * Customer last name
     * @type {string}
     * @memberof CustomerDTOValue
     */
    lastName: string
    /**
     * Customer email
     * @type {string}
     * @memberof CustomerDTOValue
     */
    email?: string
    /**
     * Customer phone number
     * @type {string}
     * @memberof CustomerDTOValue
     */
    phoneNumber?: string
    /**
     * Customer salutation
     * @type {string}
     * @memberof CustomerDTOValue
     */
    salutation?: string
    /**
     * Locale used by customer when he made an inquiry
     * @type {string}
     * @memberof CustomerDTOValue
     */
    locale: string
}

/**
 * Check if a given object implements the CustomerDTOValue interface.
 */
export function instanceOfCustomerDTOValue(value: object): value is CustomerDTOValue {
    if (!('firstName' in value) || value['firstName'] === undefined) return false
    if (!('lastName' in value) || value['lastName'] === undefined) return false
    if (!('locale' in value) || value['locale'] === undefined) return false
    return true
}

export function CustomerDTOValueFromJSON(json: any): CustomerDTOValue {
    return CustomerDTOValueFromJSONTyped(json, false)
}

export function CustomerDTOValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerDTOValue {
    if (json == null) {
        return json
    }
    return {
        firstName: json['firstName'],
        lastName: json['lastName'],
        email: json['email'] == null ? undefined : json['email'],
        phoneNumber: json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        salutation: json['salutation'] == null ? undefined : json['salutation'],
        locale: json['locale'],
    }
}

export function CustomerDTOValueToJSON(json: any): CustomerDTOValue {
    return CustomerDTOValueToJSONTyped(json, false)
}

export function CustomerDTOValueToJSONTyped(
    value?: CustomerDTOValue | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        firstName: value['firstName'],
        lastName: value['lastName'],
        email: value['email'],
        phoneNumber: value['phoneNumber'],
        salutation: value['salutation'],
        locale: value['locale'],
    }
}
