import React, { ChangeEventHandler, useCallback, useEffect, useRef, useState } from 'react'

interface TextFilterHook {
    text: string
    onTextChange: ChangeEventHandler<HTMLInputElement>
}

// Adapted from slsoscf-opportunity-frontend
export const useTextFilter = (): TextFilterHook => {
    const [text, setText] = useState<string>('')
    const [delayedText, setDelayedText] = useState<string>('')
    const timer = useRef<NodeJS.Timeout | null>(null)
    const onTextChange = useCallback(
        (e: { target: { value: React.SetStateAction<string> } }) => {
            setText(e.target.value)
        },
        [setText],
    )

    useEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current)
        }
        timer.current = setTimeout(() => setDelayedText(text), 1000)
    }, [text])

    return {
        text: delayedText,
        onTextChange,
    }
}
