import { DealerContextProps } from '@slfinrtl/context-types'

type Stage = Exclude<DealerContextProps['environment'], null>
export const stageBaseUrl: Record<Stage, string> = {
    local: 'finder-k.porsche.com',
    dev: 'finder-k.porsche.com',
    preview: 'finder-preview.porsche.com',
    prod: 'finder.porsche.com',
}

/**
 * Provides a URL object for finder with the given stage and pathname suffix.
 * @param stage 'local' | 'dev' | 'preview' | 'prod'
 * @param pathname suffix at end of url
 * @return (preview,"foo/bar") -> `https://finder-preview.porsche.com/foo/bar`
 *
 */
export const finderURL = (stage: Stage, pathname: string): URL => {
    const baseUrl = (stage && stageBaseUrl[stage]) ?? 'finder-k.porsche.com'
    return new URL(`https://${baseUrl}/${pathname}`)
}

/**
 * Provides a base URL object for mobile.de with the given locale
 * @param locale e.g. de-DE
 */
export const mobileURL = (locale = 'de-DE') => {
    // Note: If we ever need other languages than German, be aware, that France has an own website mobile.fr while other languages do not
    const language = locale.split('-')[0]
    return new URL(`https://suchen.mobile.de/fahrzeuge/search.html?isSearchRequest=true&lang=${language}`)
}
