import { useIntl } from 'react-intl'
import {
    BodyTypeDTO,
    EngineTypeDTO,
    TradeInPowerUnitDTO,
    TradeInVehicleDetail,
    TransmissionTypeDTO,
} from 'tim-api-client'

import {
    CardWithMarginTop,
    FullWidth,
    VehicleDetailGrid,
    VehicleDetailsHeadline,
    VehicleDetailsItem,
} from '~/app/components'
import { vehicleInformationMessages } from '~/app/pages/request-detail/components/VehicleInformation/messages'
import { tradeInRequestDetailMessages } from '~/app/pages/request-detail/messages/tradeInRequestDetailPageMessages'

export const VehicleInformation = ({ data }: { data?: TradeInVehicleDetail }) => {
    const { formatMessage } = useIntl()

    const translateEngineType = (engineType?: string) => {
        if (engineType === undefined) return '-'
        if (engineType === EngineTypeDTO.GAS.toString()) {
            return formatMessage(vehicleInformationMessages[EngineTypeDTO.GAS])
        } else if (engineType === EngineTypeDTO.DIESEL.toString()) {
            return formatMessage(vehicleInformationMessages[EngineTypeDTO.DIESEL])
        } else if (engineType === EngineTypeDTO.ELECTRIC.toString()) {
            return formatMessage(vehicleInformationMessages[EngineTypeDTO.ELECTRIC])
        } else if (engineType === EngineTypeDTO.GASOLINE.toString()) {
            return formatMessage(vehicleInformationMessages[EngineTypeDTO.GASOLINE])
        } else if (engineType === EngineTypeDTO.PLUG_IN_HYBRID.toString()) {
            return formatMessage(vehicleInformationMessages[EngineTypeDTO.PLUG_IN_HYBRID])
        }
        return engineType
    }

    const translateTransmission = (transmission?: string) => {
        if (transmission === undefined) return '-'
        if (transmission === TransmissionTypeDTO.MANUAL.toString()) {
            return formatMessage(vehicleInformationMessages[TransmissionTypeDTO.MANUAL])
        } else if (transmission === TransmissionTypeDTO.AUTOMATIC.toString()) {
            return formatMessage(vehicleInformationMessages[TransmissionTypeDTO.AUTOMATIC])
        }
        return transmission
    }

    const translateBodyType = (bodyType?: string) => {
        if (bodyType === undefined) return '-'
        if (bodyType === BodyTypeDTO.SUV.toString()) {
            return formatMessage(vehicleInformationMessages[BodyTypeDTO.SUV])
        } else if (bodyType === BodyTypeDTO.COMBI.toString()) {
            return formatMessage(vehicleInformationMessages[BodyTypeDTO.COMBI])
        } else if (bodyType === BodyTypeDTO.COUPE.toString()) {
            return formatMessage(vehicleInformationMessages[BodyTypeDTO.COUPE])
        } else if (bodyType === BodyTypeDTO.CABRIOLET.toString()) {
            return formatMessage(vehicleInformationMessages[BodyTypeDTO.CABRIOLET])
        } else if (bodyType === BodyTypeDTO.LIMOUSINE.toString()) {
            return formatMessage(vehicleInformationMessages[BodyTypeDTO.LIMOUSINE])
        } else if (bodyType === BodyTypeDTO.OTHER.toString()) {
            return formatMessage(vehicleInformationMessages[BodyTypeDTO.OTHER])
        }
        return bodyType
    }

    const translatePower = (power?: string) => {
        if (!power) return '-'
        return power
            ?.replace(
                TradeInPowerUnitDTO.PS_OR_HORSEPOWER,
                formatMessage(vehicleInformationMessages[TradeInPowerUnitDTO.PS_OR_HORSEPOWER]),
            )
            ?.replace(
                TradeInPowerUnitDTO.KILOWATT,
                formatMessage(vehicleInformationMessages[TradeInPowerUnitDTO.KILOWATT]),
            )
    }

    return (
        <CardWithMarginTop bottomSpacing={false}>
            <FullWidth>
                <VehicleDetailsHeadline>
                    {formatMessage(tradeInRequestDetailMessages.vehicleInformationInfoBoxTitle)}
                </VehicleDetailsHeadline>
                <VehicleDetailGrid>
                    <VehicleDetailsItem
                        label={formatMessage(vehicleInformationMessages.vin)}
                        value={data?.vin?.value}
                        hiddenStatus={data?.vin?.hidden}
                        copyToClipboardButton
                    />
                    <VehicleDetailsItem
                        label={formatMessage(vehicleInformationMessages.manufacturer)}
                        value={data?.manufacturer ?? '-'}
                    />
                    <VehicleDetailsItem
                        label={formatMessage(vehicleInformationMessages.model)}
                        value={data?.model ?? '-'}
                    />
                    <VehicleDetailsItem
                        label={formatMessage(vehicleInformationMessages.modelVariant)}
                        value={data?.modelVariant ?? '-'}
                    />
                    <VehicleDetailsItem
                        label={formatMessage(vehicleInformationMessages.modelGeneration)}
                        value={data?.modelGeneration ?? '-'}
                    />
                    <VehicleDetailsItem
                        label={formatMessage(vehicleInformationMessages.modelYear)}
                        value={data?.modelYear ?? '-'}
                    />
                    <VehicleDetailsItem
                        label={formatMessage(vehicleInformationMessages.engineType)}
                        value={translateEngineType(data?.engineType)}
                    />
                    <VehicleDetailsItem
                        label={formatMessage(vehicleInformationMessages.transmission)}
                        value={translateTransmission(data?.transmission)}
                    />
                    <VehicleDetailsItem
                        label={formatMessage(vehicleInformationMessages.power)}
                        value={translatePower(data?.power)}
                    />
                    <VehicleDetailsItem
                        label={formatMessage(vehicleInformationMessages.bodyType)}
                        value={translateBodyType(data?.bodyType)}
                    />
                    <VehicleDetailsItem
                        label={formatMessage(vehicleInformationMessages.exterior)}
                        value={data?.exterior ?? '-'}
                    />
                    <VehicleDetailsItem
                        label={formatMessage(vehicleInformationMessages.interior)}
                        value={data?.interior ?? '-'}
                    />
                </VehicleDetailGrid>
            </FullWidth>
        </CardWithMarginTop>
    )
}
