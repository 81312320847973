import { useDealerContext } from '@slfinrtl/context'
import { useQuery } from '@tanstack/react-query'
import { tradeInControllerApi } from 'tim-api-client'

import { VehicleInformation } from '~/app/pages/request-detail/components/VehicleInformation/VehicleInformation'

export interface VehicleInformationProps {
    id: string
}

export const VehicleInformationContainer = ({ id }: VehicleInformationProps) => {
    const { locale } = useDealerContext()
    const { data } = useQuery({
        queryKey: ['trade-in-request', id, 'vehicle-information', locale],
        queryFn: () => tradeInControllerApi.fetchTradeInVehicleInformation({ id, locale }),
    })

    return <VehicleInformation data={data} />
}
