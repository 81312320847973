import React from 'react'
import { useIntl } from 'react-intl'

import {
    Headline,
    TotalCount,
} from '~/app/pages/request-table/components/TradeInRequestTableTotalCount/TradeInRequestTableTotalCount.styled'
import { messagesForTradeInRequestTable as messages } from '~/app/pages/request-table/messages/messagesForTradeInRequestTable'

export interface TradeInRequestTableTotalCountProps {
    totalCount: number
}

export const TradeInRequestTableTotalCount = ({ totalCount }: TradeInRequestTableTotalCountProps) => {
    const { formatMessage } = useIntl()
    return (
        <>
            <TotalCount>{totalCount}</TotalCount>
            <Headline>{formatMessage(messages.tableTotalCount)}</Headline>
        </>
    )
}

export default TradeInRequestTableTotalCount
