import { PorscheDesignSystemProvider } from '@porsche-design-system/components-react'
import { DealerContextProvider } from '@slfinrtl/context'
import { DealerContextProps } from '@slfinrtl/context-types'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React, { useEffect, useMemo, useRef } from 'react'
import { IntlProvider } from 'react-intl'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router'
import { basePathMiddleware, tokenMiddleware } from 'tim-api-client'

import { TradeInManagerContextProvider } from '~/app/context'
import { useTranslations } from '~/app/hooks/useTranslations'
import { TradeInRequestDetailPageContainer } from '~/app/pages/request-detail'
import TradeInRequestTablePageContainer from '~/app/pages/request-table/TradeInRequestTablePageContainer'
import { defaultUiQueryClientConfig } from '~/app/ui-utils'

import { TradeInManagerBackendProvider } from './backend/TradeInManagerBackendProvider'

export const TRADEIN_PDS_PREFIX = 'trade-in'

export interface AppProps {
    basePath?: string
    context: DealerContextProps
    /**
     * Provided by dealer host app, so that trade-in microfrontend doesn't have to know URL structure of host app.
     */
    getVehicleDetailsUrl: (vehicleId: string) => string
    // Only reason for this prop is so that tests can pass mocked queryClient
    queryClient?: QueryClient
}

// Keeping query client external, so we can mock it in tests
const defaultQueryClient = new QueryClient(defaultUiQueryClientConfig)

export const App = ({
    context,
    basePath = `/dealer/${context.dealershipId}/${context.locale}/trade-in-requests`,
    getVehicleDetailsUrl,
    queryClient = defaultQueryClient,
}: AppProps) => {
    const { messages } = useTranslations(context.locale)
    const { environment, getAuthorizationHeader } = context

    // Pass current env to middleware that overrides the API-baseURL w/ stage specific baseURL
    useEffect(() => {
        basePathMiddleware.setEnv(environment ?? 'local')
    }, [environment])

    // There should be no way in the application for the dealer to change his dealershipId/countryCode etc.
    // without page reloads in-between, so we can just cache this array here
    const { current: routes } = useRef([
        {
            path: '/',
            element: (
                <TradeInRequestTablePageContainer
                    dealerEntryDn={context.dealershipId}
                    country={context.countryCode}
                    getVehicleDetailsUrl={getVehicleDetailsUrl}
                />
            ),
        },
        {
            path: '/:id',
            element: <TradeInRequestDetailPageContainer getVehicleDetailsUrl={getVehicleDetailsUrl} />,
        },
        { path: '*', element: <Navigate to="/" /> },
    ])

    useEffect(() => {
        tokenMiddleware.setTokenFunction(getAuthorizationHeader)
    }, [getAuthorizationHeader])

    const router = useMemo(() => createBrowserRouter(routes, { basename: basePath }), [basePath, routes])

    return (
        <DealerContextProvider context={context}>
            <QueryClientProvider client={queryClient}>
                <TradeInManagerBackendProvider>
                    <IntlProvider locale={context.locale} messages={messages}>
                        <PorscheDesignSystemProvider prefix={TRADEIN_PDS_PREFIX}>
                            <TradeInManagerContextProvider marketplaceKey={context.marketplaceKey}>
                                <RouterProvider router={router}></RouterProvider>
                            </TradeInManagerContextProvider>
                        </PorscheDesignSystemProvider>
                    </IntlProvider>
                </TradeInManagerBackendProvider>
            </QueryClientProvider>
        </DealerContextProvider>
    )
}
