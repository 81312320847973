import { defineMessages } from 'react-intl'

export const messagesForUseFilter = defineMessages({
    filterDateFromLabel: {
        id: 'tradeIn.requests.table.filter.dateFrom.label',
        defaultMessage: 'Date (from)',
        // Datum (von)
    },
    filterDateToLabel: {
        id: 'tradeIn.requests.table.filter.dateTo.label',
        defaultMessage: 'Date (to)',
        // Datum (bis)
    },
})
