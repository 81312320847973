/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { AmountWithCurrencyDTO } from './AmountWithCurrencyDTO'
import {
    AmountWithCurrencyDTOFromJSON,
    AmountWithCurrencyDTOFromJSONTyped,
    AmountWithCurrencyDTOToJSON,
    AmountWithCurrencyDTOToJSONTyped,
} from './AmountWithCurrencyDTO'

/**
 * Information about the type of ownership of the trade-in vehicle
 * @export
 * @interface OwnershipDTO
 */
export interface OwnershipDTO {
    /**
     *
     * @type {string}
     * @memberof OwnershipDTO
     */
    ownershipType: OwnershipDTOOwnershipTypeEnum
    /**
     *
     * @type {AmountWithCurrencyDTO}
     * @memberof OwnershipDTO
     */
    outstandingFinanceOrLeaseAmount?: AmountWithCurrencyDTO
}

/**
 * @export
 */
export const OwnershipDTOOwnershipTypeEnum = {
    FULLY_OWNED: 'FULLY_OWNED',
    FINANCE: 'FINANCE',
    LEASE: 'LEASE',
} as const
export type OwnershipDTOOwnershipTypeEnum =
    (typeof OwnershipDTOOwnershipTypeEnum)[keyof typeof OwnershipDTOOwnershipTypeEnum]

/**
 * Check if a given object implements the OwnershipDTO interface.
 */
export function instanceOfOwnershipDTO(value: object): value is OwnershipDTO {
    if (!('ownershipType' in value) || value['ownershipType'] === undefined) return false
    return true
}

export function OwnershipDTOFromJSON(json: any): OwnershipDTO {
    return OwnershipDTOFromJSONTyped(json, false)
}

export function OwnershipDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnershipDTO {
    if (json == null) {
        return json
    }
    return {
        ownershipType: json['ownershipType'],
        outstandingFinanceOrLeaseAmount:
            json['outstandingFinanceOrLeaseAmount'] == null
                ? undefined
                : AmountWithCurrencyDTOFromJSON(json['outstandingFinanceOrLeaseAmount']),
    }
}

export function OwnershipDTOToJSON(json: any): OwnershipDTO {
    return OwnershipDTOToJSONTyped(json, false)
}

export function OwnershipDTOToJSONTyped(value?: OwnershipDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value
    }

    return {
        ownershipType: value['ownershipType'],
        outstandingFinanceOrLeaseAmount: AmountWithCurrencyDTOToJSON(value['outstandingFinanceOrLeaseAmount']),
    }
}
