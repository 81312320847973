/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface TradeInVehicleEstimationDTO
 */
export interface TradeInVehicleEstimationDTO {
    /**
     * The VIN of the trade-in vehicle
     * @type {string}
     * @memberof TradeInVehicleEstimationDTO
     */
    vin?: string
    /**
     * price trend of the last 30 days in cents
     * @type {number}
     * @memberof TradeInVehicleEstimationDTO
     */
    trend30days?: number
    /**
     * Warnings about the price estimation from our external service provider, e.g. 'bad car age, should be between 8 and 44'
     * @type {Array<string>}
     * @memberof TradeInVehicleEstimationDTO
     */
    warnings?: Array<string>
}

/**
 * Check if a given object implements the TradeInVehicleEstimationDTO interface.
 */
export function instanceOfTradeInVehicleEstimationDTO(value: object): value is TradeInVehicleEstimationDTO {
    return true
}

export function TradeInVehicleEstimationDTOFromJSON(json: any): TradeInVehicleEstimationDTO {
    return TradeInVehicleEstimationDTOFromJSONTyped(json, false)
}

export function TradeInVehicleEstimationDTOFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): TradeInVehicleEstimationDTO {
    if (json == null) {
        return json
    }
    return {
        vin: json['vin'] == null ? undefined : json['vin'],
        trend30days: json['trend30days'] == null ? undefined : json['trend30days'],
        warnings: json['warnings'] == null ? undefined : json['warnings'],
    }
}

export function TradeInVehicleEstimationDTOToJSON(json: any): TradeInVehicleEstimationDTO {
    return TradeInVehicleEstimationDTOToJSONTyped(json, false)
}

export function TradeInVehicleEstimationDTOToJSONTyped(
    value?: TradeInVehicleEstimationDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        vin: value['vin'],
        trend30days: value['trend30days'],
        warnings: value['warnings'],
    }
}
