import { PModal, PSpinner, PTextList, PTextListItem } from '@porsche-design-system/components-react'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { PriceTile, PriceTileContent } from '~/app/components'
import {
    DataProviderExplanation,
    HeadingLarge,
    PopoverWithWrapText,
    PriceEstimationWarningIcon,
    PriceWithWarnings,
    SpacingFluidSmall,
    TextSmall,
    TextWithoutWrap,
    TextXSmall,
} from '~/app/pages/request-detail/components/PriceEstimation/MarketPrice/MarketPrice.styled'
import { MarketPriceFeedback } from '~/app/pages/request-detail/components/PriceEstimation/MarketPrice/MarketPriceFeedback'
import { marketPriceMessages } from '~/app/pages/request-detail/components/PriceEstimation/MarketPrice/messages'

type ACPriceEstimationProps = {
    marketPriceInCent: number
    marketPriceWarnings?: string[]
    source: string
    tradeInId: string
}
export const ACPriceEstimation = ({
    marketPriceInCent,
    marketPriceWarnings,
    source,
    tradeInId,
}: ACPriceEstimationProps) => {
    const { formatMessage } = useIntl()
    const [priceEstimationWarningsOpen, setPriceEstimationWarningsOpen] = useState<boolean>(false)

    // 9447000 -> 94470.00
    const marketPriceInEur = marketPriceInCent / 100
    // 94470.00 -> "94.470,00 €"
    // Note: We'll need to extend this component once we expand outside germany into non de-DE locales
    const marketPriceFormatted = new Intl.NumberFormat(`de-DE`, {
        currency: 'EUR',
        style: 'currency',
    }).format(marketPriceInEur)

    return (
        <PriceTile
            heading={formatMessage(marketPriceMessages.tileHeading)}
            content={
                <PriceTileContent>
                    <div>
                        <PriceWithWarnings>
                            <HeadingLarge>{marketPriceFormatted}</HeadingLarge>
                            <SpacingFluidSmall />
                            {marketPriceWarnings && (
                                <PriceEstimationWarningIcon
                                    inert={undefined}
                                    name="exclamation"
                                    color="notification-error"
                                    onClick={() => setPriceEstimationWarningsOpen(true)}
                                />
                            )}
                            <PModal
                                inert={undefined}
                                open={priceEstimationWarningsOpen}
                                onDismiss={() => setPriceEstimationWarningsOpen(false)}
                            >
                                {formatMessage(marketPriceMessages.labelPriceEstimationWarningsPopup)}
                                <PTextList inert={undefined}>
                                    {marketPriceWarnings?.map((warning, index: number) => (
                                        <PTextListItem inert={undefined} key={`ac-warning-${index}`}>
                                            {warning}
                                        </PTextListItem>
                                    ))}
                                </PTextList>
                            </PModal>
                        </PriceWithWarnings>
                        <TextXSmall>{formatMessage(marketPriceMessages.labelMarketPriceEstimation)}</TextXSmall>
                    </div>
                    <div>
                        <DataProviderExplanation>
                            <TextWithoutWrap>{source}</TextWithoutWrap>
                            <SpacingFluidSmall />
                            <PopoverWithWrapText
                                inert={undefined}
                                direction={'top'}
                                description={formatMessage(marketPriceMessages.infoPopupAboutAnalyticCompany)}
                            />
                        </DataProviderExplanation>
                        <TextXSmall>{formatMessage(marketPriceMessages.labelSource)}</TextXSmall>
                    </div>
                </PriceTileContent>
            }
            footer={<MarketPriceFeedback tradeInId={tradeInId} />}
        />
    )
}

export const MarketPriceError = () => {
    const { formatMessage } = useIntl()

    return (
        <PriceTile
            heading={formatMessage(marketPriceMessages.tileHeading)}
            content={<TextSmall>{formatMessage(marketPriceMessages.labelPriceEstimationNotPossible)}</TextSmall>}
        />
    )
}

export const MarketPriceLoading = () => {
    const { formatMessage } = useIntl()

    return (
        <PriceTile
            heading={formatMessage(marketPriceMessages.tileHeading)}
            content={
                <>
                    <TextSmall>{formatMessage(marketPriceMessages.labelPriceEstimationIsLoading)}</TextSmall>
                    <PSpinner inert={undefined} size="medium" aria={{ 'aria-label': 'Loading page content' }} />
                </>
            }
        />
    )
}
