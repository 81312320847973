import { defineMessages } from 'react-intl'
import { MileageUnitDTO } from 'tim-api-client'

export const conditionAndHistoryMessages = defineMessages({
    firstRegistration: {
        id: 'tradeIn.requests.detail.infobox.conditionAndHistory.firstRegistration',
        defaultMessage: 'First Registration',
    },
    mileage: {
        id: 'tradeIn.requests.detail.infobox.conditionAndHistory.mileage',
        defaultMessage: 'Mileage',
    },
    numberOfPreviousOwners: {
        id: 'tradeIn.requests.detail.infobox.conditionAndHistory.numberOfPreviousOwners',
        defaultMessage: 'Number of previous owners',
    },
    accidentFree: {
        id: 'tradeIn.requests.detail.infobox.conditionAndHistory.accidentFree',
        defaultMessage: 'Accident free',
    },
    fullServiceHistory: {
        id: 'tradeIn.requests.detail.infobox.conditionAndHistory.fullServiceHistory',
        defaultMessage: 'Full-service history',
    },
    vatDeductible: {
        id: 'tradeIn.requests.detail.infobox.conditionAndHistory.vatDeductible',
        defaultMessage: 'VAT deductible',
    },
    ownership: {
        id: 'tradeIn.requests.detail.infobox.conditionAndHistory.ownership',
        defaultMessage: 'Ownership',
    },
    ownershipOutstandingFinanceOrLeaseAmount: {
        id: 'tradeIn.requests.detail.infobox.conditionAndHistory.ownership.outstandingFinanceOrLeaseAmount',
        defaultMessage: '({outstandingFinanceOrLeaseAmount} exp. transfer fee)',
    },
    pcssLinkButtonText: {
        id: 'tradeIn.requests.detail.infobox.conditionAndHistory.button.showVehicleHistoryOnPCSS',
        defaultMessage: 'Show vehicle history on PCSS',
    },
    yes: {
        id: 'tradeIn.requests.detail.infobox.conditionAndHistory.yes',
        defaultMessage: 'Yes',
    },
    no: {
        id: 'tradeIn.requests.detail.infobox.conditionAndHistory.no',
        defaultMessage: 'No',
    },
    [MileageUnitDTO.KM]: {
        id: 'tradeIn.requests.detail.infobox.conditionAndHistory.mileage.unit.km',
        defaultMessage: 'km',
    },
    [MileageUnitDTO.SMI]: {
        id: 'tradeIn.requests.detail.infobox.conditionAndHistory.mileage.unit.smi',
        defaultMessage: 'mi',
    },
})
