import { PLinkPure, PTag } from '@porsche-design-system/components-react'
import {
    headingXXLargeStyle,
    spacingFluidLarge,
    spacingFluidMedium,
    spacingFluidSmall,
    spacingFluidXSmall,
    themeLightBackgroundSurface,
} from '@porsche-design-system/components-react/styles'
import styled from 'styled-components'

export const BackToOverviewLink = styled(PLinkPure)`
    margin: ${spacingFluidMedium} 0;
`

export const HeadingXXLarge = styled.h2`
    ${headingXXLargeStyle};
    margin: 0;
`

export const HeadingTags = styled.div`
    display: flex;
    gap: ${spacingFluidXSmall};
    flex-wrap: wrap;
`

export const TradeInRequestTag = styled(PTag)`
    margin-top: ${spacingFluidSmall};
    margin-bottom: ${spacingFluidMedium};
`

export const DetailPageBackground = styled.div`
    background-color: ${themeLightBackgroundSurface};
`

export const SpacingFluidLargeTopAndBottom = styled.div`
    margin: ${spacingFluidLarge} 0;
`
