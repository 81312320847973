/**
 * Formats the Registration Date into a unified international Format (MM-YYYY)
 * @param registrationDate date to be formatted
 */
export const formatRegistrationDate = (registrationDate?: string | Date) => {
    if (!registrationDate) {
        return '-'
    }

    const dateInstance = new Date(registrationDate)
    // locale is irrelevant here, since we override the separator into MM-YYYY anyways
    return `${dateInstance.toLocaleDateString('en-EN', { month: '2-digit' })}-${dateInstance.toLocaleDateString(
        'en-EN',
        { year: 'numeric' },
    )}`
}
