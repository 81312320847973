import { getMediaQueryMin, spacing } from '@porsche-design-system/components-react/styles'
import styled from 'styled-components'

export const MarketPriceLayoutGrid = styled.div`
    display: grid;
    grid-gap: ${spacing.fluid.medium};
    grid-template-columns: 1fr;

    ${getMediaQueryMin('l')} {
        grid-gap: ${spacing.fluid.large};
        grid-template-columns: 1fr 1fr;
    }
`

export const PriceTileColumn = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: ${spacing.fluid.medium};
`

export const MarketplaceLinksColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`
