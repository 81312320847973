/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { RangedPriceEstimationDTO } from './RangedPriceEstimationDTO'
import {
    RangedPriceEstimationDTOFromJSON,
    RangedPriceEstimationDTOFromJSONTyped,
    RangedPriceEstimationDTOToJSON,
    RangedPriceEstimationDTOToJSONTyped,
} from './RangedPriceEstimationDTO'
import type { OwnershipDTO } from './OwnershipDTO'
import {
    OwnershipDTOFromJSON,
    OwnershipDTOFromJSONTyped,
    OwnershipDTOToJSON,
    OwnershipDTOToJSONTyped,
} from './OwnershipDTO'

/**
 *
 * @export
 * @interface TradeInVehicleDealPriceDataDTO
 */
export interface TradeInVehicleDealPriceDataDTO {
    /**
     *
     * @type {RangedPriceEstimationDTO}
     * @memberof TradeInVehicleDealPriceDataDTO
     */
    rangedPriceEstimation: RangedPriceEstimationDTO
    /**
     *
     * @type {OwnershipDTO}
     * @memberof TradeInVehicleDealPriceDataDTO
     */
    ownership: OwnershipDTO
}

/**
 * Check if a given object implements the TradeInVehicleDealPriceDataDTO interface.
 */
export function instanceOfTradeInVehicleDealPriceDataDTO(value: object): value is TradeInVehicleDealPriceDataDTO {
    if (!('rangedPriceEstimation' in value) || value['rangedPriceEstimation'] === undefined) return false
    if (!('ownership' in value) || value['ownership'] === undefined) return false
    return true
}

export function TradeInVehicleDealPriceDataDTOFromJSON(json: any): TradeInVehicleDealPriceDataDTO {
    return TradeInVehicleDealPriceDataDTOFromJSONTyped(json, false)
}

export function TradeInVehicleDealPriceDataDTOFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): TradeInVehicleDealPriceDataDTO {
    if (json == null) {
        return json
    }
    return {
        rangedPriceEstimation: RangedPriceEstimationDTOFromJSON(json['rangedPriceEstimation']),
        ownership: OwnershipDTOFromJSON(json['ownership']),
    }
}

export function TradeInVehicleDealPriceDataDTOToJSON(json: any): TradeInVehicleDealPriceDataDTO {
    return TradeInVehicleDealPriceDataDTOToJSONTyped(json, false)
}

export function TradeInVehicleDealPriceDataDTOToJSONTyped(
    value?: TradeInVehicleDealPriceDataDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        rangedPriceEstimation: RangedPriceEstimationDTOToJSON(value['rangedPriceEstimation']),
        ownership: OwnershipDTOToJSON(value['ownership']),
    }
}
