/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { PowerDTO } from './PowerDTO'
import { PowerDTOFromJSON, PowerDTOFromJSONTyped, PowerDTOToJSON, PowerDTOToJSONTyped } from './PowerDTO'

/**
 *
 * @export
 * @interface TechnicalDataDTO
 */
export interface TechnicalDataDTO {
    /**
     *
     * @type {PowerDTO}
     * @memberof TechnicalDataDTO
     */
    power: PowerDTO
}

/**
 * Check if a given object implements the TechnicalDataDTO interface.
 */
export function instanceOfTechnicalDataDTO(value: object): value is TechnicalDataDTO {
    if (!('power' in value) || value['power'] === undefined) return false
    return true
}

export function TechnicalDataDTOFromJSON(json: any): TechnicalDataDTO {
    return TechnicalDataDTOFromJSONTyped(json, false)
}

export function TechnicalDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalDataDTO {
    if (json == null) {
        return json
    }
    return {
        power: PowerDTOFromJSON(json['power']),
    }
}

export function TechnicalDataDTOToJSON(json: any): TechnicalDataDTO {
    return TechnicalDataDTOToJSONTyped(json, false)
}

export function TechnicalDataDTOToJSONTyped(
    value?: TechnicalDataDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        power: PowerDTOToJSON(value['power']),
    }
}
