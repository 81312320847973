/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { TradeInPowerUnitDTO } from './TradeInPowerUnitDTO'
import {
    TradeInPowerUnitDTOFromJSON,
    TradeInPowerUnitDTOFromJSONTyped,
    TradeInPowerUnitDTOToJSON,
    TradeInPowerUnitDTOToJSONTyped,
} from './TradeInPowerUnitDTO'

/**
 *
 * @export
 * @interface PowerDTO
 */
export interface PowerDTO {
    /**
     *
     * @type {number}
     * @memberof PowerDTO
     */
    value: number
    /**
     *
     * @type {TradeInPowerUnitDTO}
     * @memberof PowerDTO
     */
    unit: TradeInPowerUnitDTO
}

/**
 * Check if a given object implements the PowerDTO interface.
 */
export function instanceOfPowerDTO(value: object): value is PowerDTO {
    if (!('value' in value) || value['value'] === undefined) return false
    if (!('unit' in value) || value['unit'] === undefined) return false
    return true
}

export function PowerDTOFromJSON(json: any): PowerDTO {
    return PowerDTOFromJSONTyped(json, false)
}

export function PowerDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PowerDTO {
    if (json == null) {
        return json
    }
    return {
        value: json['value'],
        unit: TradeInPowerUnitDTOFromJSON(json['unit']),
    }
}

export function PowerDTOToJSON(json: any): PowerDTO {
    return PowerDTOToJSONTyped(json, false)
}

export function PowerDTOToJSONTyped(value?: PowerDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value
    }

    return {
        value: value['value'],
        unit: TradeInPowerUnitDTOToJSON(value['unit']),
    }
}
