/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { TradeInOverviewEntry } from './TradeInOverviewEntry'
import {
    TradeInOverviewEntryFromJSON,
    TradeInOverviewEntryFromJSONTyped,
    TradeInOverviewEntryToJSON,
    TradeInOverviewEntryToJSONTyped,
} from './TradeInOverviewEntry'

/**
 *
 * @export
 * @interface TradeInOverviewPage
 */
export interface TradeInOverviewPage {
    /**
     * Trade-ins for overview which this page contains
     * @type {Array<TradeInOverviewEntry>}
     * @memberof TradeInOverviewPage
     */
    content?: Array<TradeInOverviewEntry>
    /**
     * Total number of elements matching input parameters
     * @type {number}
     * @memberof TradeInOverviewPage
     */
    totalElements?: number
    /**
     * Total number of pages
     * @type {number}
     * @memberof TradeInOverviewPage
     */
    totalPages?: number
    /**
     * Defines if this is first page
     * @type {boolean}
     * @memberof TradeInOverviewPage
     */
    first?: boolean
    /**
     * Defines if this is last page
     * @type {boolean}
     * @memberof TradeInOverviewPage
     */
    last?: boolean
    /**
     * Page size
     * @type {number}
     * @memberof TradeInOverviewPage
     */
    size?: number
    /**
     * Current page number (zero based)
     * @type {number}
     * @memberof TradeInOverviewPage
     */
    number?: number
    /**
     * Number of elements in current page
     * @type {number}
     * @memberof TradeInOverviewPage
     */
    numberOfElements?: number
}

/**
 * Check if a given object implements the TradeInOverviewPage interface.
 */
export function instanceOfTradeInOverviewPage(value: object): value is TradeInOverviewPage {
    return true
}

export function TradeInOverviewPageFromJSON(json: any): TradeInOverviewPage {
    return TradeInOverviewPageFromJSONTyped(json, false)
}

export function TradeInOverviewPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeInOverviewPage {
    if (json == null) {
        return json
    }
    return {
        content:
            json['content'] == null ? undefined : (json['content'] as Array<any>).map(TradeInOverviewEntryFromJSON),
        totalElements: json['totalElements'] == null ? undefined : json['totalElements'],
        totalPages: json['totalPages'] == null ? undefined : json['totalPages'],
        first: json['first'] == null ? undefined : json['first'],
        last: json['last'] == null ? undefined : json['last'],
        size: json['size'] == null ? undefined : json['size'],
        number: json['number'] == null ? undefined : json['number'],
        numberOfElements: json['numberOfElements'] == null ? undefined : json['numberOfElements'],
    }
}

export function TradeInOverviewPageToJSON(json: any): TradeInOverviewPage {
    return TradeInOverviewPageToJSONTyped(json, false)
}

export function TradeInOverviewPageToJSONTyped(
    value?: TradeInOverviewPage | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        content:
            value['content'] == null ? undefined : (value['content'] as Array<any>).map(TradeInOverviewEntryToJSON),
        totalElements: value['totalElements'],
        totalPages: value['totalPages'],
        first: value['first'],
        last: value['last'],
        size: value['size'],
        number: value['number'],
        numberOfElements: value['numberOfElements'],
    }
}
