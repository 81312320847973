/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface VehicleOfInterestDTO
 */
export interface VehicleOfInterestDTO {
    /**
     * VehicleId of the Porsche vehicle the customer is interested in
     * @type {string}
     * @memberof VehicleOfInterestDTO
     */
    id: string
    /**
     * Describes the Type of a VehicleOfInterest (i.e. Configured Car via PorscheCode or Stock Vehicle via Finder)
     * @type {string}
     * @memberof VehicleOfInterestDTO
     */
    type: VehicleOfInterestDTOTypeEnum
    /**
     * Description with vehicle model (= derivative) and stockNumber or PorscheCode
     * @type {string}
     * @memberof VehicleOfInterestDTO
     */
    description: string
}

/**
 * @export
 */
export const VehicleOfInterestDTOTypeEnum = {
    INVENTORY: 'INVENTORY',
    CSV: 'CSV',
} as const
export type VehicleOfInterestDTOTypeEnum =
    (typeof VehicleOfInterestDTOTypeEnum)[keyof typeof VehicleOfInterestDTOTypeEnum]

/**
 * Check if a given object implements the VehicleOfInterestDTO interface.
 */
export function instanceOfVehicleOfInterestDTO(value: object): value is VehicleOfInterestDTO {
    if (!('id' in value) || value['id'] === undefined) return false
    if (!('type' in value) || value['type'] === undefined) return false
    if (!('description' in value) || value['description'] === undefined) return false
    return true
}

export function VehicleOfInterestDTOFromJSON(json: any): VehicleOfInterestDTO {
    return VehicleOfInterestDTOFromJSONTyped(json, false)
}

export function VehicleOfInterestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleOfInterestDTO {
    if (json == null) {
        return json
    }
    return {
        id: json['id'],
        type: json['type'],
        description: json['description'],
    }
}

export function VehicleOfInterestDTOToJSON(json: any): VehicleOfInterestDTO {
    return VehicleOfInterestDTOToJSONTyped(json, false)
}

export function VehicleOfInterestDTOToJSONTyped(
    value?: VehicleOfInterestDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        id: value['id'],
        type: value['type'],
        description: value['description'],
    }
}
