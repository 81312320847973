import { DealerContextProps } from '@slfinrtl/context-types'
import React from 'react'

import { useTradeInManagerBackend } from '~/app/backend/useTradeInManagerBackendContext'
import { useFilter } from '~/app/pages/request-table/hooks/useFilter'
import { useTextFilter } from '~/app/pages/request-table/hooks/useTextFilter'
import TradeInRequestTablePage from '~/app/pages/request-table/TradeInRequestTablePage'

export interface TradeInRequestTablePageContainerProps {
    dealerEntryDn: DealerContextProps['dealershipId']
    country: DealerContextProps['countryCode']
    getVehicleDetailsUrl: (vehicleId: string) => string
}

/**
 * Wraps the TradeInRequestTable with infinite loading logic
 * @constructor
 */
export function TradeInRequestTablePageContainer({
    dealerEntryDn,
    country,
    getVehicleDetailsUrl,
}: TradeInRequestTablePageContainerProps) {
    const { text, onTextChange } = useTextFilter()
    const { filter, fromDateChange, toDateChange } = useFilter()

    const { useInfiniteTablePageQuery } = useTradeInManagerBackend()

    const iqResult = useInfiniteTablePageQuery({ text, filter, country, dealerEntryDn })

    const onScrollToPageEndLoadMore = () => {
        if (iqResult.hasNextPage && !(iqResult.isFetchingNextPage || iqResult.isLoading)) {
            iqResult.fetchNextPage().then(() => {
                console.log('fetchNextPage success')
            })
        }
    }

    return (
        <TradeInRequestTablePage
            dealerEntryDn={dealerEntryDn}
            country={country}
            getVehicleDetailsUrl={getVehicleDetailsUrl}
            tableData={iqResult}
            onScrollToPageEndLoadMore={onScrollToPageEndLoadMore}
            filterProps={{
                fromDateChange,
                toDateChange,
                onTextChange,
            }}
        />
    )
}

export default TradeInRequestTablePageContainer
