/* tslint:disable */
/* eslint-disable */
/**
 * Trade-in service API
 * API used for management of trade-ins
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { ConditionAndHistoryDTO } from './ConditionAndHistoryDTO'
import {
    ConditionAndHistoryDTOFromJSON,
    ConditionAndHistoryDTOFromJSONTyped,
    ConditionAndHistoryDTOToJSON,
    ConditionAndHistoryDTOToJSONTyped,
} from './ConditionAndHistoryDTO'
import type { TradeInSourceDTO } from './TradeInSourceDTO'
import {
    TradeInSourceDTOFromJSON,
    TradeInSourceDTOFromJSONTyped,
    TradeInSourceDTOToJSON,
    TradeInSourceDTOToJSONTyped,
} from './TradeInSourceDTO'
import type { WantedVehicleDTO } from './WantedVehicleDTO'
import {
    WantedVehicleDTOFromJSON,
    WantedVehicleDTOFromJSONTyped,
    WantedVehicleDTOToJSON,
    WantedVehicleDTOToJSONTyped,
} from './WantedVehicleDTO'
import type { RangedPriceEstimationDTO } from './RangedPriceEstimationDTO'
import {
    RangedPriceEstimationDTOFromJSON,
    RangedPriceEstimationDTOFromJSONTyped,
    RangedPriceEstimationDTOToJSON,
    RangedPriceEstimationDTOToJSONTyped,
} from './RangedPriceEstimationDTO'
import type { OwnershipDTO } from './OwnershipDTO'
import {
    OwnershipDTOFromJSON,
    OwnershipDTOFromJSONTyped,
    OwnershipDTOToJSON,
    OwnershipDTOToJSONTyped,
} from './OwnershipDTO'
import type { CreateCustomerDTO } from './CreateCustomerDTO'
import {
    CreateCustomerDTOFromJSON,
    CreateCustomerDTOFromJSONTyped,
    CreateCustomerDTOToJSON,
    CreateCustomerDTOToJSONTyped,
} from './CreateCustomerDTO'
import type { CustomerVehicleDataDTO } from './CustomerVehicleDataDTO'
import {
    CustomerVehicleDataDTOFromJSON,
    CustomerVehicleDataDTOFromJSONTyped,
    CustomerVehicleDataDTOToJSON,
    CustomerVehicleDataDTOToJSONTyped,
} from './CustomerVehicleDataDTO'

/**
 *
 * @export
 * @interface TradeInRequestDTO
 */
export interface TradeInRequestDTO {
    /**
     * Identification number of a vehicle which the customer wants to trade-in
     * @type {string}
     * @memberof TradeInRequestDTO
     */
    vin: string
    /**
     *
     * @type {string}
     * @memberof TradeInRequestDTO
     */
    dealerEntryDn: string
    /**
     *
     * @type {WantedVehicleDTO}
     * @memberof TradeInRequestDTO
     */
    wantedVehicle: WantedVehicleDTO
    /**
     *
     * @type {ConditionAndHistoryDTO}
     * @memberof TradeInRequestDTO
     */
    conditionAndHistory: ConditionAndHistoryDTO
    /**
     *
     * @type {CustomerVehicleDataDTO}
     * @memberof TradeInRequestDTO
     */
    vehicleData?: CustomerVehicleDataDTO
    /**
     *
     * @type {CreateCustomerDTO}
     * @memberof TradeInRequestDTO
     */
    customer: CreateCustomerDTO
    /**
     *
     * @type {RangedPriceEstimationDTO}
     * @memberof TradeInRequestDTO
     */
    rangedPriceEstimation?: RangedPriceEstimationDTO
    /**
     *
     * @type {OwnershipDTO}
     * @memberof TradeInRequestDTO
     */
    ownership?: OwnershipDTO
    /**
     *
     * @type {TradeInSourceDTO}
     * @memberof TradeInRequestDTO
     */
    source: TradeInSourceDTO
    /**
     * A globally unique id (e.g. deal-id in dealbuilder). The service uses it to ensure that no two trade-in-requests with the same combination of externalId and source are created.
     * @type {string}
     * @memberof TradeInRequestDTO
     */
    externalId?: string
}

/**
 * Check if a given object implements the TradeInRequestDTO interface.
 */
export function instanceOfTradeInRequestDTO(value: object): value is TradeInRequestDTO {
    if (!('vin' in value) || value['vin'] === undefined) return false
    if (!('dealerEntryDn' in value) || value['dealerEntryDn'] === undefined) return false
    if (!('wantedVehicle' in value) || value['wantedVehicle'] === undefined) return false
    if (!('conditionAndHistory' in value) || value['conditionAndHistory'] === undefined) return false
    if (!('customer' in value) || value['customer'] === undefined) return false
    if (!('source' in value) || value['source'] === undefined) return false
    return true
}

export function TradeInRequestDTOFromJSON(json: any): TradeInRequestDTO {
    return TradeInRequestDTOFromJSONTyped(json, false)
}

export function TradeInRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeInRequestDTO {
    if (json == null) {
        return json
    }
    return {
        vin: json['vin'],
        dealerEntryDn: json['dealerEntryDn'],
        wantedVehicle: WantedVehicleDTOFromJSON(json['wantedVehicle']),
        conditionAndHistory: ConditionAndHistoryDTOFromJSON(json['conditionAndHistory']),
        vehicleData: json['vehicleData'] == null ? undefined : CustomerVehicleDataDTOFromJSON(json['vehicleData']),
        customer: CreateCustomerDTOFromJSON(json['customer']),
        rangedPriceEstimation:
            json['rangedPriceEstimation'] == null
                ? undefined
                : RangedPriceEstimationDTOFromJSON(json['rangedPriceEstimation']),
        ownership: json['ownership'] == null ? undefined : OwnershipDTOFromJSON(json['ownership']),
        source: TradeInSourceDTOFromJSON(json['source']),
        externalId: json['externalId'] == null ? undefined : json['externalId'],
    }
}

export function TradeInRequestDTOToJSON(json: any): TradeInRequestDTO {
    return TradeInRequestDTOToJSONTyped(json, false)
}

export function TradeInRequestDTOToJSONTyped(
    value?: TradeInRequestDTO | null,
    ignoreDiscriminator: boolean = false,
): any {
    if (value == null) {
        return value
    }

    return {
        vin: value['vin'],
        dealerEntryDn: value['dealerEntryDn'],
        wantedVehicle: WantedVehicleDTOToJSON(value['wantedVehicle']),
        conditionAndHistory: ConditionAndHistoryDTOToJSON(value['conditionAndHistory']),
        vehicleData: CustomerVehicleDataDTOToJSON(value['vehicleData']),
        customer: CreateCustomerDTOToJSON(value['customer']),
        rangedPriceEstimation: RangedPriceEstimationDTOToJSON(value['rangedPriceEstimation']),
        ownership: OwnershipDTOToJSON(value['ownership']),
        source: TradeInSourceDTOToJSON(value['source']),
        externalId: value['externalId'],
    }
}
