import { PInlineNotification, PSelectWrapper, PTextareaWrapper } from '@porsche-design-system/components-react'
import {
    borderRadiusSmall,
    headingMediumStyle,
    headingSmallStyle,
    spacingFluid,
    textSmallStyle,
    theme,
    themeLightBackgroundBase,
    themeLightContrastLow,
    themeLightPrimary,
    themeLightStateFocus,
} from '@porsche-design-system/components-react/styles'
import styled, { css } from 'styled-components'

export const HeadingMedium = styled.h3`
    ${headingMediumStyle};
    margin: 0;
    text-align: center;
`

export const HeadingSmall = styled.h4`
    ${headingSmallStyle};
    margin: 0;
    text-align: center;
`

export const FreeTextContainerDiv = styled.div`
    margin-top: ${spacingFluid.small};
`

export const BadReasonFeedbackSelectWrapper = styled.div`
    margin-top: ${spacingFluid.small};
`

export const FeedbackReasonBox = styled.div`
    margin-top: ${spacingFluid.medium};
`

export const SelectWrapper = styled(PSelectWrapper)`
    background-color: ${themeLightBackgroundBase};
`

export const FreeTextPTextareaWrapper = styled(PTextareaWrapper)`
    width: 100%;
    margin-top: ${spacingFluid.small};
    background-color: ${themeLightBackgroundBase};
`

export const SubmitButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: ${spacingFluid.medium};
`

export const ErrorPInlineNotification = styled(PInlineNotification)`
    margin: ${spacingFluid.medium} 0;
`

export const TextSmall = styled.p`
    ${textSmallStyle};
    margin: 0;
`

// all following styles are taken from Finder Feedback Form
// e.g. https://github.com/myporsche/slfinpub-finder/blob/4043126286b8b4cfbfc04e7ac7009bb60c24641f/web/packages/finder/src/shared/components/feedback/Feedback.tsx#L290
export const ScoreBox = styled.div`
    display: grid;
    gap: ${spacingFluid.xSmall} ${spacingFluid.medium};
    margin-top: ${spacingFluid.medium};
    justify-content: space-between;
    align-items: center;

    grid-template-columns: auto 1fr auto;
`

const buttonReset = css`
    ${textSmallStyle};
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    overflow: visible;
    border-radius: 0;
    background: transparent;
    text-align: center;
    text-decoration: none;
    color: ${theme.light.primary};
    cursor: pointer;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;
`

export const Scores = styled.div`
    display: flex;
    gap: ${spacingFluid.small};
`
// Uses https://styled-components.com/docs/api#transient-props to avoid error from passing isActive to html-button
export const Score = styled.button<{ $isActive: boolean }>`
    ${buttonReset};
    padding: 15px;
    border-radius: ${borderRadiusSmall};
    border: 2px solid ${(props) => (props.$isActive ? themeLightPrimary : themeLightContrastLow)};
    flex: 1;
    position: relative;
    transition: border-color 0.26s;

    &:hover {
        border-color: ${themeLightPrimary};
    }

    &:focus-visible::before {
        content: '';
        position: absolute;
        inset: -5px;
        border: 2px solid ${themeLightStateFocus};
        border-radius: 7px;
    }
`
