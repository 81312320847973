import { defineMessages } from 'react-intl'

export const purchasePriceMessages = defineMessages({
    tileHeading: {
        id: 'tradeIn.requests.detail.infobox.purchasePrice.tileHeading',
        defaultMessage: 'Purchase price',
    },
    labelCalculatedPurchasePrice: {
        id: 'tradeIn.requests.detail.infobox.purchasePrice.labelCalculatedPurchasePrice',
        defaultMessage: 'Calculated purchase price in front of customer',
    },
    calculatedPurchasePriceStatusDate: {
        id: 'tradeIn.requests.detail.infobox.purchasePrice.calculatedPurchasePriceStatusDate',
        defaultMessage: '(Status: {date})',
    },
    estimatedPriceRange: {
        id: 'tradeIn.requests.detail.infobox.purchasePrice.estimatedPriceRange',
        defaultMessage: 'Estimated price range by Analytic Company',
    },
    medianPriceEstimate: {
        id: 'tradeIn.requests.detail.infobox.purchasePrice.mediumPriceEstimate',
        defaultMessage: 'Median estimate',
    },
    finalEstimate: {
        id: 'tradeIn.requests.detail.infobox.purchasePrice.finalEstimate',
        defaultMessage: 'Derived purchase price in front of customer',
    },
    expectedTransferFee: {
        id: 'tradeIn.requests.detail.infobox.purchasePrice.expectedTransferFee',
        defaultMessage: 'Expected transfer fee',
    },
    infoPopup: {
        id: 'tradeIn.requests.detail.infobox.purchasePrice.infoPopup',
        defaultMessage:
            'On the given date, an estimated purchase price for the trade-in vehicle was calculated based on the information provided by the customer.',
    },
    noPurchasePriceAvailable: {
        id: 'tradeIn.requests.detail.infobox.purchasePrice.noPriceEstimationAvailable',
        defaultMessage: `
We were unable to estimate a purchase value for this vehicle, therefore no price was shown to the customer.
<br/>This can happen due to:
* Trade-in vehicle is an exclusive or extravagant model for which Analytic Company cannot provide an estimate
* Trade-in vehicle is leased
* Trade-in vehicle is not a Porsche
* Customer mentioned damages or accidents
`,
    },
})
